import * as NotificationSystem from 'react-notification-system'

const queue: NotificationSystem.Notification[] = []
let notificationSystem: NotificationSystem.System | null = null

function showNotification (notification: NotificationSystem.Notification) {
  if (!notificationSystem) {
    queue.push(notification)
    return
  }
  notificationSystem.addNotification(notification)
}

function setSystem (system: NotificationSystem.System) {
  notificationSystem = system
  queue.forEach(notification => system.addNotification(notification))
  queue.splice(0)
}

export default {
  showNotification,
  setSystem,
}
