import * as React from "react";

import css from './ToggleButtonSmall.module.scss'
import classNames from 'classnames'

export interface IToggleButtonProps {
  selected: boolean
  onChange: (index: number) => void
}

class ToggleButtonSmall extends React.Component<IToggleButtonProps> {
  onChange = (index: number) => (evt: any) => {
    this.props.onChange(index)
  }

  render () {
    const {
      selected,
    } = this.props
    return (
      <div className={classNames(css.container, { [css.selected]: selected })}>
        <div
          className={classNames(css.button, { [css.active]: !selected })}
          onClick={this.onChange(0)}
        />
        <div
          className={classNames(css.button, { [css.active]: selected })}
          onClick={this.onChange(1)}
        />
      </div>
    )

  }
}

export default ToggleButtonSmall
