const en = {
  common: {
    save: 'Save',
    cancel: 'Cancel',
    back: 'Back',
    confirmDelete: 'Are you sure you want to delete?',
    delete: 'Delete',
    confirmClose: 'Discard changes?',
    discard: 'Discard',
    to: 'To',

    units: {
      km: 'km',
      m: 'm',
      s: 's',
      km_per_hour: 'km/h',
      m_per_sec: 'm/s'
    }
  },

  login: {
    username: 'Username',
    password: 'Password',
    submit: 'LOGIN',
    invalidCredentials: 'Invalid credentials',
    welcome: 'Welcome Back',
    text1: 'Get your sports insights',
    text2: 'Without breaking a sweat',
    noAccount: 'No account yet?',
    getInTouch: "Let's get in touch",
    poweredBy: 'Powered By'
  },

  sections: {
    games: 'Games',
    fixtures: 'Games',
    practices: 'Practices',
    analytics: 'Analytics',
    video: 'Video',
    team: 'Team Settings',
    reports: 'Reports',
    admin: 'Admin'
  },

  recording: {
    tooltips: {
      report: 'Download PDF report',
      json: 'Download JSON report',
      members: 'Add players',
      edit: 'Edit',
      delete: 'Delete',

      fullscreen: 'Show fullscreen',
      loop: 'Loop selected event',
      previous: 'Previous',
      next: 'Next',
      play: 'Play',
      pause: 'Pause',
      tacticalView: 'Tactical View',
      speed: 'Speed',
      backward10s: '10s Backward',
      forward10s: '10s Forward'
    },
    viewmode: {
      gameVideo: 'Game Video',
      fieldMap: 'Field Map',
      stats: 'Stats'
    },
    modal: {
      time: 'Time occured',
      duration: 'Duration',
      distance: 'Distance',
      speed: 'Average speed'
    },
    seeReport: 'See full game report',
    report: 'PDF Report',
    json: 'JSON report',
    gridType: 'Grid type:',
    timePadding: 'Time padding:',
    sections: {
      all: 'All Games',
      recent: 'Recent',
      previous: 'Previous'
    },
    state: {
      running: 'Processing is in progress',
      disabled: 'Recording is not available'
    },
    playback_rate: {
      speed_1: 'Normal',
      speed_0_5: '0.5x',
      speed_2: '2.0x'
    }
  },

  team: {
    title: 'Team Settings',
    squad: 'Squad',
    actions: {
      addNewPlayer: 'Add New Player'
    }
  },

  player: {
    all: 'All Players',
    players: 'players',
    title: 'Player',
    details: 'Player details',
    edit: 'Edit Player',
    add: 'Add Player',
    first_name: 'First name',
    last_name: 'Last name',
    num: 'Jersey number',
    position: 'Position',
    removeFromTeam: 'Remove from team',
    reports: 'Available reports',
    reportsEmpty: 'None',

    tooltips: {
      report: 'Download PDF report',
      edit: 'Edit Player',
      delete: 'Remove from team'
    }
  },

  imageUploader: {
    errors: {
      size: 'File is too big. Max size is 5Mb.',
      type: 'Wrong file type. Only .jpg,.bmp,.gif and .png files are allowed.'
    },
    selectImage: 'Select an image'
  },

  footer: {
    copyright: '© Signality',
    motto: 'real sports insights',
    madeWithLove: 'Made with ♡ in Sweden'
  },

  event: {
    pass: 'successful pass',
    ball_win: 'possession win',
    ball_lost: 'possession lost',
    failed_pass: 'failed pass',
    interception: 'failed pass',
    home_team: 'Home team',
    away_team: 'Away team',
    interceptionBy: 'Interception by',
    passFrom: 'Pass from',
    possessionWonBy: 'Possession won by',
    goal: 'Goal',
  },

  reports: {
    title: 'Reports',
    pdf_title: 'PDF Report'
  },

  half: {
    1: '1st half',
    2: '2nd half'
  },

  filter: {
    select_all: 'Select all',
    time_span: 'Time span',
    teams: 'Teams',
    events: 'Events',
    eventsShowAll: 'Showing all event types',
    passes: 'Passes',
    successful: 'Successful',
    failed: 'Failed',
    possession: 'Possession',
    wins: 'Wins',
    losses: 'Losses',
    selectedEvents: 'Selected Events',
    noEvents: 'No events found',
    goals: 'Goals'
  },

  stats: {
    last_update: 'Last update',
    match_time: 'Match time',
    number: 'No.',
    distance: 'Distance',
    speed: 'Speed',
    top_distance: 'Top distance',
    possession: 'Possession',
    passes: 'Passes',
    failed_passes: 'Failed passes',
    packing: 'Packing',
    sprints: 'Sprints',
    high_intensity_runs: 'High intensity runs',
    high_intensity_runs_short: 'HI runs',
    accelerations: 'Accelerations',
    decelerations: 'Decelerations',
    accelerations_short: 'Accel.',
    decelerations_short: 'Decel.',
    balls_won: 'Balls won',
    balls_lost: 'Balls lost'
  },

  usermenu: {
    log_out: 'Log out'
  }
}

export default en
