import * as THREE from 'three'

import jsfeat from 'jsfeat'

import {
  matrix_add,
  matrix_sub,
  homogeneous_to_cartesian,
  map_image_point,
  clamp,
  get_cr
} from './lib/helpers'
import { Matrix } from './lib/Vectorious/matrix'
import { Vector } from './lib/Vectorious/vector'
import { undistortPoint } from './lib/undistortPoint';

import { Video3dControls } from './modules/Video3dControls';
import TacticalCamera from './modules/TacticalCamera';
import tacticalCameraState from '../../stores/TacticalCameraStore';
import { TracksInfo } from '../../Types/Types';

const SHOW_OVERLAY = false

type Vec2 = [number, number]
type Mat3x3 = [[number, number, number], [number, number, number], [number, number, number]]
type Mat3x1 = [[number], [number], [number]]
type DistCoeffs = [number, number, number, number, number]
type LandmarkList = (key: string) => Vec2

interface ICameraCalibration {
  landmarks: LandmarkList
  camera_matrix: Mat3x3
  distortion: [DistCoeffs]
  new_camera_matrix: Mat3x3
  translation: Mat3x1
  rotation: Mat3x1
}

interface ICalibration {
  left: ICameraCalibration
  right: ICameraCalibration
  reference_landmarks: LandmarkList
  pitch_size: Vec2
}

export class Video3dRenderer {

  camera: THREE.PerspectiveCamera
  scene: THREE.Scene
  plane_left: THREE.PlaneGeometry
  plane_right: THREE.PlaneGeometry
  refPlane: THREE.PlaneGeometry
  renderer: THREE.WebGLRenderer;

  overlayElement?: HTMLCanvasElement
  overlayTexture?: THREE.CanvasTexture
  overlayMaterial?: THREE.MeshBasicMaterial
  centerCircle?: THREE.Mesh

  rotationX: number = 0
  rotationY: number = 0

  fullscreen: boolean = false

  leftKeys: string[]
  rightKeys: string[]
  viewKeys: [string[], string[]]

  undist_plane_points_left: Matrix[]
  undist_plane_points_right: Matrix[]
  undist_left_img_points: Vec2[]
  undist_right_img_points: Vec2[]
  undist_left_img_points_homo: Matrix
  undist_right_img_points_homo: Matrix
  ref_points: [Matrix, Matrix]
  img_points: [Vec2[], Vec2[]]
  camera_center: Matrix

  rectification_alignment_rotation!: Matrix
  forward_alignmemt_rotation!: Matrix

  rot: [Matrix, Matrix]
  tvecs: [Matrix, Matrix]
  newcameramtx: [Mat3x3, Mat3x3]
  mtx: [Mat3x3, Mat3x3]

  newc_rotation: Matrix
  newc_translation: Vector

  horizontal_rotation_limits: Vec2
  vertical_rotation_limits: Vec2
  h_right_to_left: Matrix;
  h_left_to_right: Matrix;
  camera_center_right: Matrix;
  camera_center_transpose: Matrix;
  controls: Video3dControls;
  basicFocalLength: number;
  maxFocalLength: number;

  tacticalCamera: TacticalCamera
  autoFollow: boolean;
  tracks: TracksInfo | null;
  currentTrackIndex: number;
  videoElement: HTMLVideoElement;

  isPlaying: boolean;

  constructor (
    public container: HTMLElement,
    videoElement: HTMLVideoElement,
    public calibration: ICalibration,
    alphaMapUrl?: string
  ) {

    this.renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
    this.renderer.setClearColor(0x101010, 1)
    this.renderer.setPixelRatio(window.devicePixelRatio)
    this.handleWindowResize()

    this.autoFollow = false;
    this.tracks = null;

    const dist_params = [calibration.left.distortion[0], calibration.right.distortion[0]]

    this.rot = [new Matrix(calibration.left.rotation), new Matrix(calibration.right.rotation)]
    this.tvecs = [new Matrix(calibration.left.translation), new Matrix(calibration.right.translation)]
    this.newcameramtx = [calibration.left.new_camera_matrix, calibration.right.new_camera_matrix]
    this.mtx = [calibration.left.camera_matrix, calibration.right.camera_matrix]

    this.leftKeys = Object.keys(calibration.left.landmarks)
    const leftRefPoints = this.leftKeys.map(key => {
      const p = calibration.reference_landmarks[key]
      return [p[0], p[1], 0]
    })
    const leftImgPoints = this.leftKeys.map(key => calibration.left.landmarks[key])

    this.rightKeys = Object.keys(calibration.right.landmarks)
    const rightRefPoints = this.rightKeys.map(key => {
      const p = calibration.reference_landmarks[key]
      return [p[0], p[1], 0]
    })
    const rightImgPoints = this.rightKeys.map(key => calibration.right.landmarks[key])
    this.ref_points = [new Matrix(leftRefPoints), new Matrix(rightRefPoints)]
    this.img_points = [leftImgPoints, rightImgPoints]
    this.viewKeys = [this.leftKeys, this.rightKeys]

    this.newc_rotation = new Matrix(this.newcameramtx[0].slice(0, 2).map(x => x.slice(0, 2)));
    this.newc_translation = new Vector(this.newcameramtx[0].slice(0, 2).map(x => x[2]));
    this.undist_left_img_points = this.img_points[0].map(p => undistortPoint(p, this.mtx[0], dist_params[0], this.newcameramtx[0]))
    this.undist_right_img_points = this.img_points[1].map(p => undistortPoint(p, this.mtx[1], dist_params[1], this.newcameramtx[1]))
    this.undist_left_img_points_homo = new Matrix(this.undist_left_img_points.map(p => [p[0], p[1], 1]))
    this.undist_right_img_points_homo = new Matrix(this.undist_right_img_points.map(p => [p[0], p[1], 1]))

    this.undist_plane_points_left = []
    this.undist_plane_points_right = []
    this.camera_center = Matrix.multiply(this.rot[0].T, this.tvecs[0])
    this.camera_center_right = Matrix.multiply(this.rot[1].T, this.tvecs[1])
    this.camera_center_transpose = new Matrix(
      [
        [
          (this.camera_center.get(0, 0) - this.camera_center_right.get(0, 0)) / 2,
          (this.camera_center.get(1, 0) - this.camera_center_right.get(1, 0)) / 2,
          (this.camera_center.get(2, 0) - this.camera_center_right.get(2, 0)) / 2,
        ],
      ]
    ).T
    // var rectification_alignment_rotation = NaN
    // var forward_alignmemt_rotation = NaN
    this.horizontal_rotation_limits = [0, 0]
    this.vertical_rotation_limits = [-0.5, 0.5]//[0, 0]
    const view_to_view_mappings = this.view_to_view_mapping()
    this.h_right_to_left = view_to_view_mappings[0]
    this.h_left_to_right = view_to_view_mappings[1]

    this.currentTrackIndex = 0
    this.isPlaying = true

    // init
    //WHYYYY?
    const width = 1920
    const height = 1080

    const fx = this.mtx[0][0][0];
    const fy = this.mtx[0][1][1];
    const fov_x = 2 * Math.atan(width / (2 * fx))
    const fov_y = 2 * Math.atan(height / (2 * fy))
    this.camera = new THREE.PerspectiveCamera(fov_y, fov_x / fov_y, -this.camera_center.get(1, 0) - 20, -this.camera_center.get(1, 0) + 20);

    this.camera.position.set(0, 0, 0);
    this.camera.up = new THREE.Vector3(0, 1, 0);
    this.camera.setFocalLength(1 - fov_y);
    this.basicFocalLength = 1 - fov_y;
    this.maxFocalLength = 1 + fov_y;
    // this.camera.projectionMatrix
    this.camera.lookAt(new THREE.Vector3(0, 0, -1));

    this.scene = new THREE.Scene();

    const videoMaterial = this.createVideoMaterial(videoElement, alphaMapUrl)
    this.videoElement = videoElement
    const vx = 80
    const vy = Math.round(vx * 9 / 16)

    this.plane_left = new THREE.PlaneGeometry(160, 90, vx, vy);
    this.plane_right = new THREE.PlaneGeometry(160, 90, vx, vy);
    this.refPlane = new THREE.PlaneGeometry(1920 * 2, 1080 * 2, vx, vy);

    for (let i = 0; i < this.plane_left.faceVertexUvs[0].length; i++) {
      this.plane_left.faceVertexUvs[0][i][0].x *= 0.5
      this.plane_left.faceVertexUvs[0][i][1].x *= 0.5
      this.plane_left.faceVertexUvs[0][i][2].x *= 0.5
    }
    this.plane_left.uvsNeedUpdate = true
    for (let i = 0; i < this.plane_right.faceVertexUvs[0].length; i++) {
      const v = this.plane_right.faceVertexUvs[0][i]

      this.plane_right.faceVertexUvs[0][i][0].x = 0.5 + v[0].x * 0.5
      this.plane_right.faceVertexUvs[0][i][1].x = 0.5 + v[1].x * 0.5
      this.plane_right.faceVertexUvs[0][i][2].x = 0.5 + v[2].x * 0.5
    }
    this.plane_right.uvsNeedUpdate = true

    for (let i = 0; i < this.refPlane.vertices.length; i++) {
      const lp = undistortPoint(
        [this.refPlane.vertices[i].x + 1920, 1080 * 2 - (this.refPlane.vertices[i].y + 1080)],
        this.mtx[0],
        dist_params[0],
        this.newcameramtx[0]
      )
      this.undist_plane_points_left.push(new Matrix([[lp[0]], [lp[1]], [1]]))
      const rp = undistortPoint(
        [this.refPlane.vertices[i].x + 1920, 1080 * 2 - (this.refPlane.vertices[i].y + 1080)],
        this.mtx[1],
        dist_params[1],
        this.newcameramtx[1]
      )
      this.undist_plane_points_right.push(new Matrix([[rp[0]], [rp[1]], [1]]))
    }

    const mesh_left = new THREE.Mesh(this.plane_left, videoMaterial);
    const mesh_right = new THREE.Mesh(this.plane_right, videoMaterial);

    if (SHOW_OVERLAY) {
      this.overlayElement = document.createElement('canvas')
      this.overlayElement.style.width = '100%'
      document.body.appendChild(this.overlayElement)

      this.overlayTexture = this.createOverlayTexture(this.overlayElement);
      this.overlayMaterial = this.createOverlayMaterial(this.overlayTexture)
      const mesh_left_overlay = new THREE.Mesh(this.plane_left, this.overlayMaterial);
      const mesh_right_overlay = new THREE.Mesh(this.plane_right, this.overlayMaterial);

      mesh_left_overlay.position.set(0, -this.camera_center.get(2, 0), this.camera_center.get(1, 0))
      mesh_right_overlay.position.set(0, -this.camera_center.get(2, 0), this.camera_center.get(1, 0))
      this.scene.add(mesh_right_overlay);
      this.scene.add(mesh_left_overlay);
    }

    const geometry = new THREE.CircleGeometry(15, 32);
    const material = new THREE.MeshBasicMaterial({ color: 0xffff00 });
    const centerCircle = new THREE.Mesh(geometry, material);
    centerCircle.position.set(0, 0, 0)
    this.scene.add(centerCircle);

    mesh_left.position.set(0, -this.camera_center.get(2, 0), this.camera_center.get(1, 0))
    mesh_right.position.set(0, -this.camera_center.get(2, 0), this.camera_center.get(1, 0))

    this.scene.add(mesh_right);
    this.scene.add(mesh_left);

    this.find_camera_alignment()
    this.recalculate(null)

    // container.appendChild(this.renderer.domElement);
    container.insertBefore(this.renderer.domElement, container.firstChild)

    this.tacticalCamera = new TacticalCamera(
      calibration.pitch_size,
      this.camera,
      this.camera_center,
      this.horizontal_rotation_limits,
      this.vertical_rotation_limits,
      this.basicFocalLength,
      // this.maxFocalLength,
      1,
      this.getRendererSize.bind(this),
      this.topToNewUndist.bind(this),
      this.updateRotation.bind(this),
      this.updateOverlay.bind(this)
    )

    // document.addEventListener('mousewheel', onDocumentMouseWheel, false);
    // document.addEventListener('mousemove', onDocumentMouseMove, false);
    // document.addEventListener('keydown', onKeyDown, false);
    // this.renderer.domElement.addEventListener('wheel', this.handleMouseWheel)
    // this.renderer.domElement.addEventListener('mousedown', this.handleMouseDown)
    // this.renderer.domElement.addEventListener('mouseup', this.handleMouseUp)
    // this.renderer.domElement.addEventListener('mousemove', this.handleMouseMove)

    this.controls = new Video3dControls(
      container,
      this.handleRotate,
      this.handleZoom,
      this.basicFocalLength,
      this.maxFocalLength
    )
    this.controls.zoom = this.camera.getFocalLength()
    this.controls.rotation.x = this.rotationX
    this.controls.rotation.y = this.rotationY
    window.addEventListener('resize', this.handleWindowResize, false);
    this.animate()
  }

  destroy () {
    window.removeEventListener('resize', this.handleWindowResize)
    // this.renderer.domElement.removeEventListener('mousewheel', this.handleMouseWheel)
    if (this.rafId !== undefined)
      cancelAnimationFrame(this.rafId)
  }

  rafId?: number
  animate = () => {
    if (this.isPlaying && this.autoFollow) {
      const tracks = this.findTracksWithNext() as any[]
      if (tracks) {
        this.recalculate(tracks[0], tracks[1]);
        tacticalCameraState.setTracks(tracks[0], tracks[1])
      } else {
        this.recalculate(tracks);
      }
    }
    this.rafId = requestAnimationFrame(this.animate)
    this.renderer.render(this.scene, this.camera)

  }

  createAlphaTexture () {
    const canvas = document.createElement('canvas')
    canvas.width = 1920 * 2
    canvas.height = 1080
    const ctx = canvas.getContext('2d')
    if (!ctx) throw new Error('got no context')

    ctx.fillStyle = '#fff'
    ctx.fillRect(0, 0, canvas.width, canvas.height)

    const points = ["B", "J", "Z", "AH"]
      .map(n => this.img_points[1][this.viewKeys[1].indexOf(n)])
      .map(p => [1920 + p[0] / 2, p[1] / 2]) as Vec2[]

    ctx.beginPath()
    ctx.moveTo(1920, 0)
    ctx.lineTo(points[0][0], 0)
    for (let i = 0; i < points.length; i++) {
      const p = points[i]
      ctx.lineTo(p[0], p[1])
    }
    ctx.lineTo(points[points.length - 1][0], 1080)
    ctx.lineTo(1920, 1080)
    ctx.closePath()

    ctx.fillStyle = '#000'
    ctx.fill()

    // canvas.style.width = '100%'
    // document.body.appendChild(canvas)

    const alphaTexture = new THREE.Texture(canvas)
    alphaTexture.needsUpdate = true
    return alphaTexture
  }

  createVideoMaterial (videoElement: HTMLVideoElement, alphaMapUrl?: string) {
    const texture = new THREE.VideoTexture(videoElement);

    texture.minFilter = THREE.LinearFilter;
    texture.magFilter = THREE.NearestFilter;
    texture.format = THREE.RGBFormat;
    texture.wrapS = THREE.ClampToEdgeWrapping;
    texture.wrapT = THREE.ClampToEdgeWrapping;

    const alphaTexture = alphaMapUrl ? new THREE.TextureLoader().load(alphaMapUrl) : this.createAlphaTexture()
    alphaTexture.minFilter = THREE.NearestFilter;
    alphaTexture.magFilter = THREE.NearestFilter;
    alphaTexture.format = THREE.RGBFormat;
    alphaTexture.wrapS = THREE.ClampToEdgeWrapping;
    alphaTexture.wrapT = THREE.ClampToEdgeWrapping;

    const material = new THREE.MeshBasicMaterial({
      map: texture,
      alphaMap: alphaTexture,
      // wireframe: true,
    });
    material.transparent = true
    return material
  }

  createOverlayMaterial (texture: THREE.CanvasTexture) {
    texture.minFilter = THREE.LinearFilter;
    texture.magFilter = THREE.NearestFilter;
    texture.format = THREE.RGBFormat;
    texture.wrapS = THREE.ClampToEdgeWrapping;
    texture.wrapT = THREE.ClampToEdgeWrapping;


    const material = new THREE.MeshBasicMaterial({
      map: texture,
      transparent: true,
      opacity: .8,
      side: THREE.FrontSide,
      blending: THREE.AdditiveBlending,
      // wireframe: true,
    });
    return material
  }

  createOverlayTexture (canvasElement: HTMLCanvasElement) {
    const canvas = canvasElement

    const texture = new THREE.CanvasTexture(canvas)
    texture.needsUpdate = true
    return texture
  }

  updateOverlay = (top: number[], right: number[], bottom: number[], left: number[]) => {
    const canvas = this.overlayElement;
    if (!(SHOW_OVERLAY && canvas && this.overlayTexture)) return;

    // Render centerpoint
    const centerPoint = new Matrix([[(left[0] + right[0]) / 2, (top[1] + bottom[1]) / 2, 0]]).T
    this.updateCenterCircle([centerPoint.get(0, 0), centerPoint.get(1, 0)])

    // Render translated edge players and rectangle
    canvas.width = 1920 * 2
    canvas.height = 1080
    const ctx = canvas.getContext('2d')
    if (!ctx) throw new Error('got no context')
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.fillStyle = 'transparent'
    const left_ref_points = this.leftKeys.map(key => {
      const p = this.calibration.reference_landmarks[key] as Vec2
      return [p[0], p[1]]
    }) as Vec2[]
    const right_ref_points = this.rightKeys.map(key => {
      const p = this.calibration.reference_landmarks[key] as Vec2
      return [p[0], p[1]]
    }) as Vec2[]

    const topToLeft = this.homography(left_ref_points, this.undist_left_img_points)
    const topToRight = this.homography(right_ref_points, this.undist_right_img_points)

    const halfWidth = this.calibration.pitch_size[0] / 2

    const leftIsLeft = (left[0] < halfWidth)
    const rightIsLeft = (right[0] < halfWidth)
    const bottomIsLeft = (bottom[0] < halfWidth)
    const topIsLeft = (top[0] < halfWidth)

    const lpt = map_image_point([left[0], left[1]], (leftIsLeft ? topToLeft : topToRight))
    const tpt = map_image_point([top[0], top[1]], (topIsLeft ? topToLeft : topToRight))
    const rpt = map_image_point([right[0], right[1]], (rightIsLeft ? topToLeft : topToRight))
    const bpt = map_image_point([bottom[0], bottom[1]], (bottomIsLeft ? topToLeft : topToRight))

    ctx.beginPath()
    ctx.arc((leftIsLeft ? 0 : 1920) + lpt[0] / 2, lpt[1] / 2, 10, 0, 2 * Math.PI)
    ctx.arc((topIsLeft ? 0 : 1920) + tpt[0] / 2, tpt[1] / 2, 10, 0, 2 * Math.PI)
    ctx.arc((rightIsLeft ? 0 : 1920) + rpt[0] / 2, rpt[1] / 2, 10, 0, 2 * Math.PI)
    ctx.arc((bottomIsLeft ? 0 : 1920) + bpt[0] / 2, bpt[1] / 2, 10, 0, 2 * Math.PI)
    ctx.closePath()

    ctx.fillStyle = 'red'
    ctx.fill()

    this.overlayTexture.needsUpdate = true

    return null
  }

  updateCenterCircle (position: number[]) {
    if (this.centerCircle)
      this.centerCircle.position.set(position[0], position[1], -80)
    else {
      const geometry = new THREE.CircleGeometry(15, 32);
      const material = new THREE.MeshBasicMaterial({ color: 0xffff00 });
      const centerCircle = new THREE.Mesh(geometry, material);
      centerCircle.position.set(position[0], position[1], -80)
      this.centerCircle = centerCircle
      this.scene.add(centerCircle);
    }
  }

  setTracks = (tracks: any) => {
    this.tracks = tracks
  }

  findTracksWithNext () {
    if (this.tracks === null)
      return null

    const currentTime = this.videoElement.currentTime * 1000

    const prevTrackIndex = this.currentTrackIndex
    let index = this.currentTrackIndex === -1 ? 0 : this.currentTrackIndex

    while (this.tracks[index] && currentTime > this.tracks[index][0])
      index++
    while (this.tracks[index] && currentTime < this.tracks[index][0])
      index--

    this.currentTrackIndex = index
    if (this.currentTrackIndex === -1 || Math.abs(currentTime - this.tracks[index][0]) > 2000) {
      return
    } else if (this.currentTrackIndex === prevTrackIndex) {
      return null
    }

    return [this.tracks[this.currentTrackIndex], this.tracks[this.currentTrackIndex + 1]]
  }

  findTracks () {
    if (this.tracks === null)
      return null

    const currentTime = this.videoElement.currentTime * 1000

    const prevTrackIndex = this.currentTrackIndex
    let index = this.currentTrackIndex === -1 ? 0 : this.currentTrackIndex

    while (this.tracks[index] && currentTime > this.tracks[index][0])
      index++
    while (this.tracks[index] && currentTime < this.tracks[index][0])
      index--

    this.currentTrackIndex = index
    if (this.currentTrackIndex === -1 || Math.abs(currentTime - this.tracks[index][0]) > 2000) {
      return
    } else if (this.currentTrackIndex === prevTrackIndex) {
      return null
    }

    return this.tracks[this.currentTrackIndex]
  }

  recalculate (tracks: any, nextTracks?: any) {
    let horizontal_rotation
    let vertical_rotation

    if (this.isPlaying && this.autoFollow) {
      const rotations = this.tacticalCamera.alignToPoint(tracks, nextTracks)
      horizontal_rotation = rotations[0]
      vertical_rotation = rotations[1]
    } else {
      const horizontalRotation = this.rotationX
      const verticalRotation = this.rotationY
      const h_rot_theta = clamp(horizontalRotation, this.horizontal_rotation_limits[0] - 0.1, this.horizontal_rotation_limits[1] + 0.1)
      horizontal_rotation = get_cr(h_rot_theta, 0, 0)
      const v_rot_theta = clamp(verticalRotation, this.vertical_rotation_limits[0], this.vertical_rotation_limits[1])
      vertical_rotation = get_cr(0, v_rot_theta, 0)
    }


    const left_h_est = this.estimate_view_homography(this.ref_points[0].T, this.undist_left_img_points, horizontal_rotation, vertical_rotation)
    const right_h_est = this.estimate_view_homography(this.ref_points[1].T, this.undist_right_img_points, horizontal_rotation, vertical_rotation)
    const left_right_h = Matrix.multiply(right_h_est, this.h_left_to_right)
    const right_left_h = Matrix.multiply(left_h_est, this.h_right_to_left)
    const weight = clamp(8 / Math.PI * this.rotationY + 0.5, 0, 1)
    const left_h = this.homography_average(1 - weight, left_right_h, left_h_est, this.undist_left_img_points_homo.T, this.undist_left_img_points)
    const right_h = this.homography_average(weight, right_left_h, right_h_est, this.undist_right_img_points_homo.T, this.undist_right_img_points)

    this.recalculate_plane(this.plane_left, left_h, this.undist_plane_points_left)
    this.recalculate_plane(this.plane_right, right_h, this.undist_plane_points_right)
  }

  recalculate_plane (plane: THREE.PlaneGeometry, h: Matrix, undist_plane_points: Matrix[]) {
    for (let i = 0; i < plane.vertices.length; i++) {
      const half_height = 1080
      const pm = undist_plane_points[i]
      const p = Matrix.multiply(h, pm)
      const pc = homogeneous_to_cartesian(p)[0]
      if (pc[0] > -1920 * 2 && pc[0] < 1920 * 4 && pc[1] > -half_height * 2 && pc[1] < half_height * 4) {
        plane.vertices[i].x = pc[0] - 1920
        plane.vertices[i].y = half_height - pc[1]
        plane.vertices[i].z = 0
      }
      else {
        //hide vertex
        plane.vertices[i].z = this.camera_center.get(1, 0) - 99999
      }
    }
    plane.verticesNeedUpdate = true;
  }

  homography_average (weight: number, h1: Matrix, h2: Matrix, points: Matrix, undist_img_points: Vec2[]) {
    const h1_p = Matrix.multiply(h1, points)
    const h1_pc = homogeneous_to_cartesian(h1_p)
    const h2_p = Matrix.multiply(h2, points)
    const h2_pc = homogeneous_to_cartesian(h2_p)
    const interp_p: Vec2[] = []
    for (let i = 0; i < h1_pc.length; ++i) {
      const p1 = h1_pc[i]
      const p2 = h2_pc[i]
      const diff_x = p1[0] - p2[0]
      const diff_y = p1[1] - p2[1]
      interp_p.push([p2[0] + weight * diff_x, p2[1] + weight * diff_y])
    }

    const avg_h = this.homography(undist_img_points, interp_p)
    return avg_h
  }

  getRendererSize () {
    const size = this.renderer.getSize()
    const pixelRatio = this.renderer.getPixelRatio()
    return {
      height: size.height / pixelRatio,
      width: size.width / pixelRatio,
    }
  }

  find_camera_alignment () {
    const rectification_alignment = get_cr(0, 0, 0)
    const forward_alignmemt = this.find_forward_alignment(rectification_alignment)
    this.rectification_alignment_rotation = this.find_rectification_alignment(forward_alignmemt)
    // Second pass to refine forward alignment using rectification (improves start position but can be skipped)
    this.forward_alignmemt_rotation = this.find_forward_alignment(rectification_alignment)
    this.set_rotation_limits()
  }

  find_rectification_alignment (forward_alignment: Matrix) {
    let theta_rectified = NaN
    let prev_diff_left = Infinity
    let prev_diff_right = Infinity
    let prev_theta = -0.3
    const top_left_key = "A"
    const center_key = "B"
    const top_right_key = "C"
    const top_left_index = this.viewKeys[0].indexOf(top_left_key)
    const center_left_index = this.viewKeys[0].indexOf(center_key)
    const top_right_index = this.viewKeys[1].indexOf(top_right_key)
    const center_right_index = this.viewKeys[1].indexOf(center_key)
    for (let theta = prev_theta; theta < 0.3; theta = theta + 0.01) {
      const h_left = this.homography_from_rotation(theta, this.undist_left_img_points, this.ref_points[0].T, forward_alignment)
      const h_right = this.homography_from_rotation(theta, this.undist_right_img_points, this.ref_points[1].T, forward_alignment)

      const left_corner_point = map_image_point(this.undist_left_img_points[top_left_index], h_left)
      const left_center_point = map_image_point(this.undist_left_img_points[center_left_index], h_left)
      const right_corner_point = map_image_point(this.undist_right_img_points[top_right_index], h_right)
      const right_center_point = map_image_point(this.undist_right_img_points[center_right_index], h_right)

      const diff_left = Math.abs(left_corner_point[1] - left_center_point[1])
      const diff_right = Math.abs(right_corner_point[1] - right_center_point[1])
      if (diff_left > prev_diff_left && diff_right > prev_diff_right) {
        theta_rectified = prev_theta
        break
      }
      prev_diff_left = diff_left
      prev_diff_right = diff_right
      prev_theta = theta
    }
    return get_cr(theta_rectified, 0, 0)
  }

  homography_from_rotation (theta: number, undist_img_points: Vec2[], points: Matrix, forward_alignment: Matrix) {
    let ref_p = matrix_add(points, this.camera_center)
    ref_p = matrix_sub(ref_p, this.camera_center_transpose)
    ref_p = Matrix.multiply(forward_alignment, ref_p)
    ref_p = matrix_sub(ref_p, this.camera_center)
    ref_p = matrix_add(Matrix.multiply(this.rot[0], ref_p), this.tvecs[0])

    const rotation = get_cr(theta, 0, 0)

    let c_ref_points = Matrix.multiply(rotation, ref_p)
    c_ref_points = new Matrix(homogeneous_to_cartesian(c_ref_points))
    const c_ref_points2 = matrix_add(Matrix.multiply(this.newc_rotation, c_ref_points.T), this.newc_translation).T.toArray() as Vec2[]
    return this.homography(undist_img_points, c_ref_points2)
  }

  handleRotate = (x: number, y: number) => {
    if (this.autoFollow) return

    this.rotationX = x
    this.rotationY = y
    this.recalculate(null)
  }

  handleZoom = (zoom: number) => {
    if (this.autoFollow) return

    if (zoom >= this.basicFocalLength && zoom <= this.maxFocalLength) {
      this.camera.setFocalLength(zoom);
      this.camera.updateProjectionMatrix()
    }
  }

  handleWindowResize = () => {
    setTimeout(() => {
      const w = this.container.clientWidth
      const h = w * 0.5625
      this.renderer.setSize(w, h, false)
    }, 1)
  }

  handleWindowResizeFromDefinedSize = (size?: THREE.Vector2) => {
    setTimeout(() => {
      const w = size ? size.x : this.container.clientWidth
      const h = size ? size.y : w * 0.5625
      this.renderer.setSize(w, h, false)
    }, 1)
  }

  view_to_view_mapping () {
    const left_ref_points = this.leftKeys.map(key => {
      const p = this.calibration.reference_landmarks[key] as Vec2
      return [p[0], p[1]]
    }) as Vec2[]
    const right_ref_points = this.rightKeys.map(key => {
      const p = this.calibration.reference_landmarks[key] as Vec2
      return [p[0], p[1]]
    }) as Vec2[]

    const h_right_to_left = this.s2_to_s1_homography(left_ref_points, right_ref_points, this.undist_left_img_points, this.undist_right_img_points)
    const h_left_to_right = this.s2_to_s1_homography(right_ref_points, left_ref_points, this.undist_right_img_points, this.undist_left_img_points)

    return [h_right_to_left, h_left_to_right]
  }

  s2_to_s1_homography (ref_points_s1: Vec2[], ref_points_s2: Vec2[], undist_img_points_s1: Vec2[], undist_img_points_s2: Vec2[]) {
    const h_top_to_s1 = this.homography(ref_points_s1, undist_img_points_s1)
    const h_s2_to_top = this.homography(undist_img_points_s2, ref_points_s2)
    return Matrix.multiply(h_top_to_s1, h_s2_to_top)
  }

  homography (from_arr: Vec2[], to_arr: Vec2[]) {
    const from: any[] = []
    const to: any[] = []
    if (from_arr.length !== to_arr.length) {
      throw new Error("lists of points have different length");
    }
    for (let i = 0; i < from_arr.length; ++i) {
      if (this.is_point_valid(to_arr[i])) {
        from.push({ x: from_arr[i][0], y: from_arr[i][1] });
        to.push({ x: to_arr[i][0], y: to_arr[i][1] });
      }
    }
    const kernel = new jsfeat.motion_model.homography2d();
    const transform = new jsfeat.matrix_t(3, 3, jsfeat.F32_t | jsfeat.C1_t);
    const ok = kernel.run(from, to, transform, from.length);
    if (ok !== 0) {
      const m = transform.data;
      return new Matrix([
        [m[0], m[1], m[2]],
        [m[3], m[4], m[5]],
        [m[6], m[7], m[8]],
      ]);
    }
    return Matrix.identity(3, 3);
  }

  is_point_valid (point: Vec2) {
    const width = 1920 * 2
    const height = 1080 * 2
    return point[0] > -width && point[1] > -height && point[0] < 2 * width && point[1] < 2 * height
  }

  find_forward_alignment (rectification_alignmemt: Matrix) {
    const top_center_key = "AH"
    const bottom_center_key = "B"
    const top_center_left_point = this.undist_left_img_points[this.viewKeys[0].indexOf(top_center_key)]
    const top_center_right_point = this.undist_right_img_points[this.viewKeys[1].indexOf(top_center_key)]
    const bottom_center_left_point = this.undist_left_img_points[this.viewKeys[0].indexOf(bottom_center_key)]
    const bottom_center_right_point = this.undist_right_img_points[this.viewKeys[1].indexOf(bottom_center_key)]

    let theta_left_top = NaN
    let theta_left_bottom = NaN
    let theta_right_top = NaN
    let theta_right_bottom = NaN
    for (let theta = 0; theta > -1; theta = theta - 0.01) {
      const h_left = this.homography_from_forward_rotation(theta, this.ref_points[0].T, this.undist_left_img_points, rectification_alignmemt)
      const h_right = this.homography_from_forward_rotation(theta, this.ref_points[1].T, this.undist_right_img_points, rectification_alignmemt)

      const left_top_point = map_image_point(top_center_left_point, h_left)
      const left_bottom_point = map_image_point(bottom_center_left_point, h_left)
      const right_top_point = map_image_point(top_center_right_point, h_right)
      const right_bottom_point = map_image_point(bottom_center_right_point, h_right)

      if (left_top_point[0] <= 1920 && isNaN(theta_left_top)) {
        theta_left_top = theta
      }
      if (left_bottom_point[0] <= 1920 && isNaN(theta_left_bottom)) {
        theta_left_bottom = theta
      }
      if (right_top_point[0] <= 1920 && isNaN(theta_right_top)) {
        theta_right_top = theta
      }
      if (right_bottom_point[0] <= 1920 && isNaN(theta_right_bottom)) {
        theta_right_bottom = theta
      }
      if (!isNaN(theta_left_top) && !isNaN(theta_left_bottom) && !isNaN(theta_right_top) && !isNaN(theta_right_bottom)) {
        break
      }
    }
    const theta_res = (theta_left_top + theta_left_bottom + theta_right_top + theta_right_bottom) / 4
    return get_cr(theta_res, 0, 0)
  }

  homography_from_forward_rotation (theta: number, view_ref_points: Matrix, undist_img_points: Vec2[], rectification_alignmemt: Matrix) {
    const rotation = get_cr(theta, 0, 0)
    let c_ref_points = matrix_add(view_ref_points, this.camera_center)
    c_ref_points = matrix_sub(c_ref_points, this.camera_center_transpose)
    c_ref_points = Matrix.multiply(rotation, c_ref_points)
    c_ref_points = matrix_sub(c_ref_points, this.camera_center)

    c_ref_points = matrix_add(Matrix.multiply(this.rot[0], c_ref_points), this.tvecs[0])
    c_ref_points = Matrix.multiply(rectification_alignmemt, c_ref_points)

    c_ref_points = new Matrix(homogeneous_to_cartesian(c_ref_points))
    const c_ref_points2 = matrix_add(Matrix.multiply(this.newc_rotation, c_ref_points.T), this.newc_translation).T.toArray() as Vec2[]

    return this.homography(undist_img_points, c_ref_points2)
  }

  set_horizontal_rotation_limits () {
    const frustum = new THREE.Frustum();
    frustum.setFromMatrix(new THREE.Matrix4().multiplyMatrices(this.camera.projectionMatrix, this.camera.matrixWorldInverse));
    const left_corner_key = "AG"
    const right_corner_key = "AI"
    const left_corner_undist = this.undist_left_img_points[this.viewKeys[0].indexOf(left_corner_key)]
    const right_corner_undist = this.undist_right_img_points[this.viewKeys[1].indexOf(right_corner_key)]
    const vertical_rotation = get_cr(0, 0, 0)

    let horizontal_rotation_min = -Math.PI / 3 + 0.02
    let horizontal_rotation_max = Math.PI / 3 - 0.02
    for (let theta = horizontal_rotation_min; theta < horizontal_rotation_max; theta = theta + 0.01) {
      const horizontal_rotation = get_cr(theta, 0, 0)

      const left_h = this.estimate_view_homography(this.ref_points[0].T, this.undist_left_img_points, horizontal_rotation, vertical_rotation)
      const right_h = this.estimate_view_homography(this.ref_points[1].T, this.undist_right_img_points, horizontal_rotation, vertical_rotation)

      const left_corner = map_image_point(left_corner_undist, left_h)
      const right_corner = map_image_point(right_corner_undist, right_h)

      const left_corner_vertex = new THREE.Vector3(left_corner[0] - 1920, 1080 - left_corner[1] - this.camera_center.get(2, 0), this.camera_center.get(1, 0))
      const right_corner_vertex = new THREE.Vector3(right_corner[0] - 1920, 1080 - right_corner[1] - this.camera_center.get(2, 0), this.camera_center.get(1, 0))

      if (frustum.containsPoint(left_corner_vertex) && !frustum.containsPoint(right_corner_vertex) && theta > 0) {
        horizontal_rotation_max = theta
        break
      }
      if (frustum.containsPoint(right_corner_vertex) && theta < 0) {
        horizontal_rotation_min = theta
      }
    }

    // this.horizontal_rotation_limits = [-0.20079632679489534, 0.23920367320510477]//[horizontal_rotation_min, horizontal_rotation_max]
    this.horizontal_rotation_limits = [horizontal_rotation_min, horizontal_rotation_max]
  }

  estimate_view_homography (c_ref_points_input: Matrix, undist_img_points: Vec2[], horizontal_rotation: Matrix, vertical_rotation: Matrix) {
    let c_ref_points = matrix_add(c_ref_points_input, this.camera_center)
    c_ref_points = matrix_sub(c_ref_points, this.camera_center_transpose)
    c_ref_points = Matrix.multiply(this.forward_alignmemt_rotation, c_ref_points)
    c_ref_points = Matrix.multiply(horizontal_rotation, c_ref_points)
    c_ref_points = matrix_sub(c_ref_points, this.camera_center)

    //Move to camera coordinate system
    c_ref_points = matrix_add(Matrix.multiply(this.rot[0], c_ref_points), this.tvecs[0])
    c_ref_points = Matrix.multiply(vertical_rotation, c_ref_points)
    c_ref_points = Matrix.multiply(this.rectification_alignment_rotation, c_ref_points)
    c_ref_points = new Matrix(homogeneous_to_cartesian(c_ref_points))
    const c_ref_points2 = matrix_add(Matrix.multiply(this.newc_rotation, c_ref_points.T), this.newc_translation).T.toArray() as Vec2[]

    return this.homography(undist_img_points, c_ref_points2)
  }

  get_pixel_pos (h: Matrix, key: string) {
    const p = this.undist_left_img_points[this.viewKeys[0].indexOf(key)]
    const ip = map_image_point(p, h)
    const pos = new THREE.Vector3(ip[0] - 1920, 1080 - ip[1] - this.camera_center.get(2, 0), this.camera_center.get(1, 0))
    pos.project(this.camera);
    const size = this.getRendererSize()
    const windowHalfX = size.width / 2
    const windowHalfY = size.height / 2
    pos.x = (pos.x * windowHalfX) + windowHalfX;
    pos.y = - (pos.y * windowHalfY) + windowHalfY;
    return pos
  }

  set_vertical_rotation_limits () {
    const top_center_key = "B"
    const bottom_center_key = "AH"

    let vetrical_rotation_min = NaN
    let vetrical_rotation_max = NaN

    const { height } = this.getRendererSize()

    for (let theta = -0.5; theta < 0.5; theta = theta + 0.01) {
      const vertical_rotation = get_cr(0, theta, 0)
      const horizontal_rotation = get_cr(0, 0, 0)
      const left_h = this.estimate_view_homography(this.ref_points[0].T, this.undist_left_img_points, horizontal_rotation, vertical_rotation)

      const top_pos = this.get_pixel_pos(left_h, top_center_key)
      const bottom_pos = this.get_pixel_pos(left_h, bottom_center_key)

      if (top_pos.y < height / 3 && isNaN(vetrical_rotation_min)) {
        vetrical_rotation_min = theta
      }
      if (bottom_pos.y < height * 3 / 4 && isNaN(vetrical_rotation_max)) {
        vetrical_rotation_max = theta
      }

      if (!isNaN(vetrical_rotation_min) && !isNaN(vetrical_rotation_max)) {
        break
      }
    }

    this.vertical_rotation_limits = [vetrical_rotation_min, vetrical_rotation_max]
    return
  }

  set_rotation_limits () {
    this.set_horizontal_rotation_limits()
    this.set_vertical_rotation_limits()
  }

  //Needed for tactical camera only
  private topToNewUndist (points_input: Matrix, horizontal_rotation: Matrix, vertical_rotation: Matrix) {
    let points = matrix_add(points_input, this.camera_center)
    points = matrix_sub(points, this.camera_center_transpose)

    points = Matrix.multiply(this.forward_alignmemt_rotation, points)
    points = Matrix.multiply(horizontal_rotation, points)
    points = matrix_sub(points, this.camera_center)

    //Move to camera coordinate system
    points = matrix_add(Matrix.multiply(this.rot[0], points), this.tvecs[0])
    points = Matrix.multiply(vertical_rotation, points)
    points = Matrix.multiply(this.rectification_alignment_rotation, points)
    points = new Matrix(homogeneous_to_cartesian(points))
    return matrix_add(Matrix.multiply(this.newc_rotation, points.T), this.newc_translation).T.toArray()
  }

  private updateRotation (x: number, y: number) {
    this.rotationX = x
    this.rotationY = y
  }
}
