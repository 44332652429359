import * as React from 'react'
import { GridType } from '../../Types/Types'
import gridConfig from './gridConfig'

interface IProps {
  type: GridType
  width: number
  length: number
}

export default class Grid extends React.Component<IProps> {
  getVerticalLine = (y: number) => {
    const {
      width,
    } = this.props

    const padding = gridConfig.padding
    const strokeDash = gridConfig.strokeDash

    return (
      <line
        key={'line-vert-' + y}
        strokeDasharray={strokeDash}
        x1={padding}
        y1={y + padding}
        x2={width + padding}
        y2={y + padding}
      />
    )
  }
  getHorizontalLine = (x: number) => {
    const {
      length,
    } = this.props

    const padding = gridConfig.padding
    const strokeDash = gridConfig.strokeDash

    return (
      <line
        key={'line-hor-' + x}
        strokeDasharray={strokeDash}
        x1={x + padding}
        y1={padding}
        x2={x + padding}
        y2={length + padding}
      />
    )
  }

  renderThreeBySix = () => {
    const { width, length } = this.props
    const spacingLength = length / 6;
    const spacingWidth = width / 3;

    return (
      <g stroke={gridConfig.color} strokeWidth={gridConfig.strokeWidth}>
        {[0, 1, 2, 3, 4, 5, 6].map((index: number) => (
          this.getVerticalLine(spacingLength * index)
        ))}
        {[0, 1, 2, 3].map((index: number) => (
          this.getHorizontalLine(spacingWidth * index)
        ))}
      </g>
    );
  }

  renderCorridors = () => {
    const { width, length } = this.props

    const penaltyAreaLength = gridConfig.penalty.length
    const halfGoalAreaWidth = gridConfig.goal.width / 2
    const halfPenaltyAreaWidth = gridConfig.penalty.width / 2

    const halfWidth = width / 2
    const halfLength = length / 2

    const vertLines = [
      0,
      penaltyAreaLength,
      length / 3,
      halfLength,
      length * 2 / 3,
      length - penaltyAreaLength,
      length,
    ]

    const horLines = [
      0,
      halfWidth - halfPenaltyAreaWidth,
      halfWidth - halfGoalAreaWidth,
      halfWidth + halfGoalAreaWidth,
      halfWidth + halfPenaltyAreaWidth,
      width,
    ]

    return (
      <g stroke={gridConfig.color} strokeWidth={gridConfig.strokeWidth}>
        {vertLines.map(y => (this.getVerticalLine(y)))}
        {horLines.map(y => (this.getHorizontalLine(y)))}
      </g>
    );
  }

  render () {
    const { type } = this.props

    switch (type) {
      case GridType.Corridors:
        return this.renderCorridors()
      case GridType.ThreeBySix:
        return this.renderThreeBySix()
      default:
        return null
    }
  }
}
