import * as React from "react";

import { observer } from "mobx-react";
import gameDetailsState, { isPassEvent, isBallWinEvent } from "../../stores/GameDetails";

import { withRouter, RouteComponentProps } from "react-router";
import isEqual from 'react-fast-compare'

import * as ZoneUtils from "./ZoneUtils";
import themeGlobal from '../../Themes/global'

const COLOR_BLUE = themeGlobal.colors.colorBrandMainLight
const COLOR_BRAND_ACCENT = themeGlobal.colors.colorBrandAccent
const COLOR_GRAY = themeGlobal.colors.colorGray
const COLOR_GRAYISH = themeGlobal.colors.colorGrayish
const COLOR_TRANSPARENT = themeGlobal.colors.colorTransparent

import {
  PhaseNumber,
  EventTypeSelection,
  PossessionTypeSelection,
  IEventMovements,
  GridType,
  IGameEvent,
  IPassEvent,
  IBallWinEvent
} from "../../Types/Types";

import css from './FootballField.module.scss'
import EventModal from "./EventModal/EventModal";
import Grid from './Grid'
import FieldLines from "./FieldLines";
import gridConfig from './gridConfig'
import ToggleButton from "../ToggleButton/ToggleButton";
import { t } from "../../localization/i18n";
import tacticalCameraState from "../../stores/TacticalCameraStore";
// import { renderCornerValues, renderZoneData } from './debugHelpers'

const padding = gridConfig.padding;
const RENDER_BORDER_PLAYERS = false;

interface IProps extends RouteComponentProps<any> {
  length: number
  width: number
  events: IGameEvent[]
  phase: PhaseNumber
  filterEventType: EventTypeSelection
  filterPossessionType: PossessionTypeSelection
  currentEventIndex: number
}

interface IState {
  length: number
  width: number
  possessionChangeZoneData: any
  activePossessionChangeZone: number
  showLandscapeIndex: number
  // videoUrl?: string
  events: IGameEvent[]
  eventMovements?: IEventMovements
  gridType: GridType
}

@observer
class FootballField extends React.Component<IProps, IState> {
  state: IState = {
    length: this.props.length,
    possessionChangeZoneData: {
      totalNumber: -1,
    },
    activePossessionChangeZone: -1,
    showLandscapeIndex: 0,
    width: this.props.width,
    events: [],
    gridType: GridType.Corridors,
  };

  fetchPhaseInfo (phase: PhaseNumber) {
    gameDetailsState.getPhaseInfo(this.props.match.params.id, phase)
  }

  componentDidUpdate (prevProps: IProps) {
    const a = prevProps
    const b = this.props
    if (isEqual(a, b))
      return

    this.fetchPhaseInfo(this.props.phase)
    this.setState({
    })
  }

  componentDidMount () {
    gameDetailsState.getCurrentGame(this.props.match.params.id)
    this.fetchPhaseInfo(this.props.phase)
    gameDetailsState.getVideo(this.props.match.params.id)
    gameDetailsState.getTracks(this.props.match.params.id)
    document.addEventListener('click', this.hideOverlay)
  }

  componentWillUnmount () {
    document.removeEventListener('click', this.hideOverlay)
    this.hideOverlay()
  }

  calculateX = (x: number): number => {
    return this.state.width * x + padding;
  }
  calculateY = (y: number): number => {
    return this.state.length * (1 - y) + padding;
  }

  calculateXm (x: number): number {
    return x + padding;
  }
  calculateYm (y: number): number {
    return (this.state.length - y) + padding;
  }

  toggleLandscapeIndex = () => {
    const showLandscapeIndex = this.state.showLandscapeIndex === 0 ? 1 : 0;
    this.setState({ showLandscapeIndex });
  };

  handleChangeGridType = (index: number) => this.setState({ gridType: index })

  hideOverlay = () => {
    gameDetailsState.clearActiveEvent()
  }

  // TODO: Replace this with Link
  clickedPass = (pass: IPassEvent) => (evt: any) => {
    evt.stopPropagation()

    const activePassObject = gameDetailsState.activePassObject
    if (activePassObject) {
      gameDetailsState.clearActiveEvent()
      return
    }

    gameDetailsState.fetchEvent(this.props.match.params.id, pass.id)
    gameDetailsState.fetchEventMovements(this.props.match.params.id, pass.id)
    return
  }

  stopPropagation = (evt: React.SyntheticEvent) => {
    evt.nativeEvent.stopImmediatePropagation()
  }

  renderPlayers () {
    const players = this.state.eventMovements;
    if (!players)
      return null
    return (
      <g>
        {players.opponents.map((player, index) => {
          if (!player.from_position) return;
          const [fromX, fromY] = this.calculateCoords(player)
          return (
            <circle key={index} cx={fromX} cy={fromY} r={1} fill={COLOR_BRAND_ACCENT} />
          );
        })}
        {players.players.map((player, index) => {
          if (!player.from_position) return;
          const [fromX, fromY] = this.calculateCoords(player)
          return (
            <circle
              key={index}
              cx={fromX}
              cy={fromY}
              r={1}
              fill={COLOR_BLUE}
            />
          );
        })}
      </g>
    )
  }

  renderExtremePlayers () {

    const tracks = tacticalCameraState.currentTracks
    if (!tracks)
      return

    const positions = tracks.slice(2)

    // Seeing tracks as [y, x, y, x, ...]
    // Where
    // -----> x
    // |
    // |
    // y
    const playerPositions = [
      [positions[1], positions[0]],
      [positions[3], positions[2]],
      [positions[5], positions[4]],
      [positions[7], positions[6]],
    ]

    return (
      <g>
        {playerPositions.map((element, index) => {
          return (
            <circle
              key={index}
              cx={this.calculateX(element[0])}
              cy={this.calculateY(element[1])}
              r={1}
              fill={COLOR_BLUE}
            />
          );

        })}
      </g>
    )
  }

  calculateCoords (source: any) {
    let fromX
    let fromY
    let toX
    let toY

    //TODO get rid of this hack
    const activeGame = gameDetailsState.currentGame
    const eventVersion = activeGame && activeGame.eventVersion || 0
    if (source.initialVersion !== 0 && (activeGame && eventVersion < 2)) {
      if (source.from_position) {
        fromX = this.calculateXm(source.from_position[1]);
        fromY = this.calculateYm(source.from_position[0]);
      }
      if (source.to_position) {
        toX = this.calculateXm(source.to_position[1]);
        toY = this.calculateYm(source.to_position[0]);
      }
    } else {
      if (source.from_position) {
        fromX = this.calculateX(source.from_position[1]);
        fromY = this.calculateY(source.from_position[0]);
      }
      if (source.to_position) {
        toX = this.calculateX(source.to_position[1]);
        toY = this.calculateY(source.to_position[0]);
      }
    }

    return [fromX, fromY, toX, toY]
  }

  renderPass (pass: IPassEvent, index: number) {
    const activePassObject = gameDetailsState.activePassObject
    const showActivePass = !!activePassObject

    if (activePassObject && activePassObject.id !== pass.id) {
      return null
    }

    const activeInPlaylist = this.props.currentEventIndex === index

    const [fromX, fromY, toX, toY] = this.calculateCoords(pass)

    const fromXLine = fromX;
    const toXLine = toX;
    const fromYLine = fromY;
    const toYLine = toY;

    const playerSize = showActivePass ? 2 : activeInPlaylist ? 1.2 : 0.7;
    const colorFromPlayer = showActivePass || activeInPlaylist ? COLOR_BLUE : COLOR_GRAYISH;
    const colorToPlayer = !activeInPlaylist ? COLOR_TRANSPARENT : (pass.type === 'pass') ? COLOR_BLUE : COLOR_BRAND_ACCENT
    const colorPassArrow = showActivePass || activeInPlaylist ? COLOR_GRAY : COLOR_GRAYISH;
    const markerEnd = showActivePass || activeInPlaylist ? "url(#arrow-active)" : "url(#arrow)";

    const overlay = (
      <rect
        x={padding}
        y={padding}
        width={this.state.width}
        height={this.state.length}
        fillOpacity="0.5"
        onClick={this.clickedPass(pass)}
      />
    )

    return (
      <g className={css.pass} stroke={"transparent"} key={index} onClick={this.clickedPass(pass)}>
        {/* RENDER PLAYERS IF PASS IS SELECTED */}
        {showActivePass ? overlay : null}
        {showActivePass ? this.renderPlayers() : null}
        <circle cx={fromX} cy={fromY} r={playerSize} fill={colorFromPlayer} />
        <circle cx={toX} cy={toY} r={playerSize} fill={colorToPlayer} />
        <line stroke={colorPassArrow} x1={fromXLine} y1={fromYLine} x2={toXLine} y2={toYLine} markerEnd={markerEnd} />
      </g>
    );
  }

  renderPassEvents () {
    return <g>{this.renderInactivePassEvents()} {this.renderCurrentPassEvent()}</g>
  }

  renderInactivePassEvents () {
    return this.props.events.map((event, index) => {

      if (this.props.currentEventIndex === index)
        return null

      if (isPassEvent(event)) {
        return this.renderPass(event, index);
      }

      return null
    });
  }

  renderCurrentPassEvent () {
    const event = this.props.events[this.props.currentEventIndex]
    if (isPassEvent(event))
      return this.renderPass(event, this.props.currentEventIndex);

    return null
  }

  renderPossessionChange = (possessionChange: IBallWinEvent, index: number) => {
    const x = this.calculateX(possessionChange.ball_position[0]);
    const y = this.calculateY(possessionChange.ball_position[1]);
    const playerSize = 1.5;
    const playerColor =
      this.props.filterPossessionType === PossessionTypeSelection.Wins ? COLOR_BLUE : COLOR_BRAND_ACCENT;
    return (
      <g stroke={"transparent"} key={index}>
        <circle cx={x} cy={y} r={playerSize} fill={playerColor} />
      </g>
    );
  };

  handlePossessionChange = (zone: ZoneUtils.IZone) => (evt: any) => {
    this.setState({ activePossessionChangeZone: zone.zoneName })
  }

  renderPossessionChanges () {
    const { gridType, width, length } = this.state

    const zones = ZoneUtils.getZoneDimensions(gridType, width, length) || [];

    // GET TURNOVER DATA
    const possessionChanges = this.props.events.filter(isBallWinEvent)

    // GET POSSESSION CHANGES ZONE DATA
    const zoneOccurences: number[] = [];

    possessionChanges
      .map((possessionChange) => {
        zoneOccurences.push(ZoneUtils.findMyZone(zones, possessionChange.ball_position));
      });
    const possessionChangesZoneData: any = {
      totalNumber: zoneOccurences.length,
    };
    zoneOccurences.forEach(
      number =>
        (possessionChangesZoneData[number] =
          (possessionChangesZoneData[number] || 0) + 1)
    );

    const formatPossessionData = (zone: ZoneUtils.IZone) => {
      return (
        possessionChangesZoneData[zone.zoneName] /
        possessionChangesZoneData.totalNumber
      );

      // const percentage =
      //   (possessionChangesZoneData[zone.zoneName] /
      //     possessionChangesZoneData.totalNumber) *
      //   100;
      // const rounded = Math.round(percentage * 100) / 100;
      // return `${rounded}%`;
    };

    return (
      <g>
        {zones.map((zone, index) => {
          if (this.state.activePossessionChangeZone === zone.zoneName) {
            return possessionChanges.map(
              (possessionChange, i) => {
                if (
                  ZoneUtils.findMyZone(zones, possessionChange.ball_position) ===
                  zone.zoneName
                ) {
                  return this.renderPossessionChange(possessionChange, i);
                } else {
                  return null;
                }
              }
            );
          }
          const x = zone.coordinates.top.left.x;
          const y = zone.coordinates.top.left.y;
          const zoneWidth = zone.coordinates.top.right.x - zone.coordinates.top.left.x;
          const zoneHeight = zone.coordinates.top.left.y - zone.coordinates.bottom.left.y;

          const zoneX = this.calculateX(x);
          const zoneY = this.calculateY(y);

          let opacity = formatPossessionData(zone) || 0;
          opacity *= 3; // 10% * 3
          return (
            <g stroke={"transparent"} key={index}>
              <rect
                x={zoneX}
                y={zoneY}
                onMouseOver={this.handlePossessionChange(zone)}
                width={this.state.width * zoneWidth}
                height={this.state.length * zoneHeight}
                fill={
                  this.props.filterPossessionType === PossessionTypeSelection.Wins ? COLOR_BLUE : COLOR_BRAND_ACCENT
                }
                fillOpacity={opacity}
              />
            </g>
          );
        })}
      </g>
    );
  }

  getLeftAndRightTeamNames () {
    const activeGame = gameDetailsState.currentGame
    if (!activeGame)
      return { left: '', right: '' }

    const awayTeamName = (activeGame.away_team) ? activeGame.away_team.name : ""
    const homeTeamName = (activeGame.home_team) ? activeGame.home_team.name : ""
    const homeAttackDirection = (gameDetailsState.currentPhase && gameDetailsState.currentPhase.home_attack_direction) || 1
    if (homeAttackDirection === 1)
      return { left: homeTeamName, right: awayTeamName }
    else
      return { left: awayTeamName, right: homeTeamName }
  }

  renderAttackDefenceText () {
    const activeGame = gameDetailsState.currentGame
    if (!activeGame)
      return null

    const teams = this.getLeftAndRightTeamNames()

    return (
      <g>
        <text
          x={this.calculateX(0.5)}
          y={this.calculateY(-0.015)}
          fill={COLOR_BRAND_ACCENT}
          alignmentBaseline="middle"
          textAnchor="middle"
          fontSize={"2px"}
          stroke={"none"}
        >
          {teams.left}
        </text>
        <text
          x={this.calculateX(0.5)}
          y={this.calculateY(1.01)}
          fill={COLOR_BRAND_ACCENT}
          alignmentBaseline="middle"
          textAnchor="middle"
          fontSize={"2px"}
          stroke={"none"}
        >
          {teams.right}
        </text>
      </g>
    );
  }

  renderData = () => {
    return this.props.filterEventType === EventTypeSelection.Passes
      ? this.renderPassEvents()
      : this.renderPossessionChanges()
  }

  render () {
    const lineWidth = 0.2;

    const { width, length, gridType } = this.state
    const totalWidth = width + padding * 2;
    const totalHeight = length + padding * 2;

    let transform = "";
    let viewBoxHeight = totalHeight;
    if (!this.state.showLandscapeIndex) {
      const scale = totalWidth / totalHeight;
      viewBoxHeight = totalWidth * scale;
      transform = `scale(${scale}) translate(${totalHeight} 0) rotate(90)`;
    }

    const gridTypeButton = (
      <div className={css.gridButtonWrapper}>
        <span>{t('recording.gridType')}</span>
        <ToggleButton
          selected={gridType}
          options={["Corridors", "3 x 6"]}
          onChange={this.handleChangeGridType}
          style={{ margin: 0 }}
          sub
        />
      </div>
    )

    return (
      <div className={css.container}>
        <EventModal
          isOpen={!!gameDetailsState.activePassObject}
          activePass={gameDetailsState.activePassObject}
          video={gameDetailsState.video}
          tracks={gameDetailsState.tracks}
          closeModal={this.hideOverlay}
        />
        <svg
          viewBox={`0 0 ${totalWidth} ${viewBoxHeight}`}
          stroke="lightgray"
          strokeWidth={lineWidth}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g transform={transform}>
            <Grid
              type={gridType}
              width={width}
              length={length}
            />

            <FieldLines
              width={width}
              length={length}
            />
            <marker
              id="arrow-active"
              viewBox="0 0 10 10"
              refX="10"
              refY="5"
              markerWidth="7"
              markerHeight="7"
              fill={COLOR_GRAY}
              orient="auto-start-reverse"
            >
              <path d="M 0 0 L 10 5 L 0 10 z" />
            </marker>

            <marker
              id="arrow"
              viewBox="0 0 10 10"
              refX="10"
              refY="5"
              markerWidth="7"
              markerHeight="7"
              fill={COLOR_GRAYISH}
              orient="auto-start-reverse"
            >
              <path d="M 0 0 L 10 5 L 0 10 z" />
            </marker>

            {this.renderData()}

            {/* {renderZoneData(
              this.state.possessionChangeZoneData,
              this.calculateX,
              this.calculateY,
              this.props.filterEventType
            )} */}
            {this.renderAttackDefenceText()}
            {RENDER_BORDER_PLAYERS ? this.renderExtremePlayers() : null}
            {/* {renderCornerValues(
              this.calculateX,
              this.calculateY
            )} */}
          </g>
        </svg>
        {gridTypeButton}
      </div>
    );
  }
}

export default withRouter(FootballField)
