import React, { ReactChild } from 'react';

import css from './Label.module.scss'
import classNames from 'classnames'

interface IProps {
  className?: string

  small?: boolean
  fail?: boolean
  success?: boolean
  width?: string
  children: ReactChild
}

const Label = (props: IProps) => {
  const {
    className,
    small,
    fail,
    success,
    width,
    children,
    ...rest
  } = props

  const classes = classNames(
    css.label,
    {
      [css.fail]: fail,
      [css.success]: success,
      [css.small]: small,
    },
    className
  )

  let style = {}
  if (width) {
    style = { width }
  }

  return (
    <div
      style={style}
      className={classes}
      {...rest}
    >
      {children}
    </div>
  );
}

export default Label;
