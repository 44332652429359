import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import recordingStore from '../../stores/RecordingStore'
import { observer } from "mobx-react";
import AdminGameList from "../../Components/GameList/AdminGameList";
import teamListState from "../../stores/TeamListStore";
import appState from "../../stores/AppStateStore";
import css from './LIAdmin.module.scss';

@observer
export default class LIAdmin extends React.Component<RouteComponentProps> {

  componentWillMount () {
    recordingStore.subscribe()
    teamListState.getAllTeams()
  }

  componentWillUnmount () {
    recordingStore.unsubscribe()
    teamListState.unsubscribe()
  }

  render () {
    const isAdmin = appState.user ? appState.user.type === 'admin' : false
    return (
      <div className={css.container}>
        {isAdmin
          ?
          <div>
            <h1>Admin</h1>
            <AdminGameList games={recordingStore.recordings} allTeams={teamListState.allTeams} />
          </div>
          : <h1>Not authorized</h1>
        }
      </div>
    )
  }
}
