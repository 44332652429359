import * as React from "react";

import Button from '../Button/Button';

import css from './ModalSimple.module.scss'
import classNames from 'classnames';
import IconButton from "../IconButton/IconButton";

interface IButton {
  title: string,
  secondary?: boolean,
  disabled?: boolean,
  handler: () => void
}

interface IProps {
  title?: string
  onClose: () => void
  styles?: object
  noScroll?: boolean
  padded?: boolean
  footerButtons?: IButton[]
}

export class Modal extends React.Component<IProps> {
  stopPropagation = (event: React.SyntheticEvent) => {
    event.stopPropagation()
  }

  renderFooter = () => {
    const { footerButtons } = this.props

    if (!(footerButtons && footerButtons.length)) return null;

    const buttonComponents = footerButtons.map((btn) => (
      <Button
        key={btn.title}
        clear={btn.secondary}
        disabled={btn.disabled}
        onClick={btn.handler}
        className={css.button}
      >
        {btn.title}
      </Button>
    ))

    return (
      <div className={css.footer}>
        {buttonComponents}
      </div>
    )
  }

  render () {
    const {
      title,
      noScroll,
      padded,
      children,
    } = this.props

    const styles = this.props.styles ? this.props.styles : {}

    return (
      <div className={css.container} style={styles} onClick={this.stopPropagation}>
        <div className={css.header}>
          <div className={css.title}>
            {title || ''}
          </div>
          <IconButton icon='close' onClick={this.props.onClose} />
        </div>
        <div
          className={classNames(
            css.inner,
            {
              [css.noScroll]: noScroll,
              [css.padded]: padded,
            }
          )}
        >
          {children}
        </div>
        {this.renderFooter()}
      </div>
    )
  }
}

export default Modal
