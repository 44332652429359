import { Matrix } from '../lib/Vectorious/matrix'
import * as THREE from 'three'
import {
  get_cr
} from '../lib/helpers'
import LinearInterpolation from '../lib/interpolation';
import { ITrackItem } from '../../../Types/Types';

type Vec2 = [number, number]

export default class TacticalCamera {
  fHorizontal: LinearInterpolation;
  fVertical: LinearInterpolation;
  fFocal: LinearInterpolation;

  horizontalThetaPrev: number;
  verticalThetaPrev: number;
  focalLengthPrev: number;

  constructor (
    private pitchSize: Vec2,
    private camera: THREE.PerspectiveCamera,
    private cameraCenter: Matrix,
    private horizontalRotationLimits: Vec2,
    private verticalRotationLimits: Vec2,
    private basicFocalLength: number,
    private maxFocalLength: number,
    private getRendererSize: () => { width: number, height: number },
    private topToNewUndist: (p: Matrix, hr: Matrix, vr: Matrix) => any[],
    private updateRotation: (x: number, y: number) => void,
    private updateOverlay: (top: number[], right: number[], bottom: number[], left: number[]) => void
  ) {
    this.fHorizontal = new LinearInterpolation(0.01, 0)
    this.fVertical = new LinearInterpolation(0.01, 0)
    this.fFocal = new LinearInterpolation(0.01, .5)
    this.horizontalThetaPrev = 0
    this.verticalThetaPrev = 0
    this.focalLengthPrev = .5
  }

  alignToPoint (tracks: ITrackItem, nextTracks?: ITrackItem) {
    if (nextTracks) {
      return this._alignToPoint(nextTracks)
    }

    return this._alignToPoint(tracks)
  }

  _alignToPoint (tracks: ITrackItem) {
    let horizontalRotation = get_cr(0, 0, 0)
    let verticalRotation = get_cr(0, 0, 0)

    if (this.camera.getFocalLength() !== this.maxFocalLength) {
      this.camera.setFocalLength(this.maxFocalLength)
    }

    if (typeof tracks === 'undefined') {
      horizontalRotation = get_cr(0, 0, 0)
      verticalRotation = get_cr(0, 0, 0)
      this.camera.setFocalLength(this.basicFocalLength)

      this.horizontalThetaPrev = 0
      this.verticalThetaPrev = 0
      this.focalLengthPrev = .5

      return [horizontalRotation, verticalRotation]
    }

    if (tracks === null) {
      horizontalRotation = get_cr(this.fHorizontal.update(this.horizontalThetaPrev), 0, 0)
      verticalRotation = get_cr(0, this.fVertical.update(this.verticalThetaPrev), 0)
    } else {
      const areaWidth = this.pitchSize[0]
      const areaHeight = this.pitchSize[1]
      const points = tracks.slice(2).map((t, i) => (i % 2 === 0) ? (t * areaWidth) : (t * areaHeight))

      const top = [points[0], points[1], 0]
      const right = [points[2], points[3], 0]
      const bottom = [points[4], points[5], 0]
      const left = [points[6], points[7], 0]
      const ball = tracks.length > 8 ? [points[8], tracks[9], 0] : null

      this.updateOverlay(top, right, bottom, left)

      const allTrackPoints = [left, right, top, bottom]
      const centerPoint = new Matrix([[(left[0] + right[0]) / 2, (top[1] + bottom[1]) / 2, 0]]).T

      let allPoints: Matrix
      if (ball === null) {
        allPoints = new Matrix(allTrackPoints).T
      } else {
        allPoints = new Matrix(allTrackPoints.concat([ball])).T
      }

      const horizontalTheta = this.getHorizontalTheta(allPoints, tracks)
      this.fHorizontal.updateDestination(horizontalTheta)
      horizontalRotation = get_cr(this.fHorizontal.getValue(), 0, 0)

      const verticalTheta = this.getVerticalTheta(allPoints, horizontalRotation)
      this.fVertical.updateDestination(verticalTheta)
      verticalRotation = get_cr(0, this.fVertical.getValue(), 0)

      const alignmentPointImg = this.topToNewUndist(centerPoint, horizontalRotation, verticalRotation)[0]
      const vectorM = new THREE.Vector3(0, 0, -1)
      vectorM.x = alignmentPointImg[0] - 1920;
      vectorM.y = 1080 - alignmentPointImg[1] - this.cameraCenter.get(2, 0);
      vectorM.z = this.cameraCenter.get(1, 0);

      const quaternion = new THREE.Quaternion();
      const eulerAngles = new THREE.Euler(0, 0, 0, 'XYZ');
      const vector = new THREE.Vector3(0, 0, -1);

      quaternion.setFromUnitVectors(vector, vectorM.normalize());
      eulerAngles.setFromQuaternion(quaternion.normalize())
      this.updateRotation(eulerAngles.x, eulerAngles.y)
    }
    this.horizontalThetaPrev = this.fHorizontal.getValue()
    this.verticalThetaPrev = this.fVertical.getValue()

    return [horizontalRotation, verticalRotation]
  }

  private getHorizontalTheta (allPoints: Matrix, tracks: ITrackItem) {
    let distance = Infinity
    let horizontalTheta = this.horizontalRotationLimits[0] - 0.1
    const verticalRotation = get_cr(0, 0, 0)
    let horizontalRotation = get_cr(0, 0, 0)
    let minLimit = this.horizontalRotationLimits[0]
    let maxLimit = this.horizontalRotationLimits[1]
    if (!isNaN(this.horizontalThetaPrev)) {
      minLimit = this.horizontalThetaPrev
      maxLimit = this.horizontalThetaPrev
    }

    const { width } = this.getRendererSize()

    for (let theta = minLimit - 0.1; theta < maxLimit + 0.1; theta = theta + 0.01) {
      horizontalRotation = get_cr(theta, 0, 0)
      const undistImgPoints = this.topToNewUndist(allPoints, horizontalRotation, verticalRotation)
      const distances = []
      for (let i = 0; i < undistImgPoints.length; ++i) {
        const screen_pos = this.getPositionInPixels(undistImgPoints[i])
        distances.push(screen_pos.x)
      }
      const ballDistFromCenter = 0
      // let ballDistFromCenter = 0
      //We should have a ball already in all_points
      // if (tracks.ball !== null) {
      //   ballDistFromCenter = (distances[undistImgPoints.length - 1] - width / 2)
      // }
      const leftDist = Math.min(...distances)
      const rightDist = (width - Math.max(...distances))
      const newDistance = Math.abs((leftDist - rightDist) + ballDistFromCenter)
      if (distance > newDistance) {
        distance = newDistance
        horizontalTheta = theta
      }
      else {
        break
      }
    }
    return horizontalTheta
  }

  private getVerticalTheta (allPoints: Matrix, horizontalRotation: Matrix) {
    let minLimit = this.verticalRotationLimits[0]
    let maxLimit = this.verticalRotationLimits[1]
    if (!isNaN(this.verticalThetaPrev)) {
      minLimit = this.verticalThetaPrev - 0.1
      maxLimit = this.verticalThetaPrev + 0.1
    }
    let distance = Infinity
    let verticalTheta = this.verticalRotationLimits[0]
    let verticalRotation = get_cr(0, 0, 0)
    const { height } = this.getRendererSize()

    for (let theta = minLimit; theta < maxLimit; theta = theta + 0.005) {
      verticalRotation = get_cr(0, theta, 0)
      const undistImgPoints = this.topToNewUndist(allPoints, horizontalRotation, verticalRotation)
      const distances = []
      for (let i = 0; i < undistImgPoints.length; ++i) {
        const screen_pos = this.getPositionInPixels(undistImgPoints[i])
        distances.push(screen_pos.y)
      }

      const topDist = Math.min(...distances)
      const bottomDist = height - Math.max(...distances)
      const newDistance = Math.abs(topDist - bottomDist)
      if (distance > newDistance) {
        distance = newDistance
        verticalTheta = theta
      }
      else {
        break
      }
    }

    return verticalTheta
  }

  private getPositionInPixels (inp: any[]) {
    const p = { x: inp[0] - 1920, y: 1080 - inp[1] - this.cameraCenter.get(2, 0), z: this.cameraCenter.get(1, 0) }
    const pos = new THREE.Vector3(p.x, p.y, p.z)
    pos.project(this.camera);
    const size = this.getRendererSize()
    const windowHalfX = size.width / 2
    const windowHalfY = size.height / 2
    pos.x = (pos.x * windowHalfX) + windowHalfX;
    pos.y = - (pos.y * windowHalfY) + windowHalfY;
    return pos
  }
}
