import { observer } from "mobx-react";
import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import ErrorMessage from "./ErrorMessage";

import { Authentication } from "../../stores/Authentication";
import { t } from "../../localization/i18n";

import css from './Login.module.scss';
import classNames from 'classnames'
import Button from "../../Components/Button/Button";
import Input from "../../Components/Input/Input";

interface IProps {
}
interface IState {
  errorMsg: string | null
  password: string
  username: string
  loading: boolean
}

class Login extends React.Component<RouteComponentProps<IProps>, IState> {
  usernameRef: React.RefObject<HTMLInputElement>
  passwordRef: React.RefObject<HTMLInputElement>

  state: IState = {
    errorMsg: null,
    password: "",
    username: "",
    loading: false,
  };

  constructor (props: RouteComponentProps<IProps>) {
    super(props)
    this.usernameRef = React.createRef()
    this.passwordRef = React.createRef()
  }

  componentWillMount () {
    // TODO: Investigate this.
    this.props.history.push("/");
  }

  login = (evt?: React.SyntheticEvent) => {
    if (evt) {
      evt.preventDefault()
    }

    const { loading } = this.state

    let {
      username,
      password,
    } = this.state

    // Override if saved password is used
    if (!username && this.usernameRef.current) {
      username = this.usernameRef.current.value
    }

    if (!password && this.passwordRef.current) {
      password = this.passwordRef.current.value
    }

    if (loading) return;

    if (username && password) {
      this.setState({ loading: true })
      Authentication
        .logIn(username, password)
        .catch(err => {
          console.error(err)
          this.setState({ loading: false })
          this.setErrorMessage(t('login.invalidCredentials'));
        });
    } else {
      this.setErrorMessage(t('login.invalidCredentials'));
    }
  };

  setErrorMessage = (msg: string) => {
    this.setState({
      errorMsg: msg,
    });
  };

  setEmail = (value: string) => {
    this.setState({
      username: value,
      errorMsg: null,
    });
  };

  setPassword = (value: string) => {
    this.setState({
      password: value,
      errorMsg: null,
    });
  };

  render () {
    const {
      errorMsg,
      loading,
      username,
      password,
    } = this.state
    return (
      <section className={css.wrapper}>
        <div className={css.loginContainer}>
          <div className={css.logo}>
            <img
              className={css.logoImage}
              src={require("../../assets/images/logo-color-dark.svg")}
              alt="logo"
            />
          </div>
          <div className={css.formContainer}>
            <form onSubmit={this.login}>
              <div className={css.title}>
                <div>{t('login.welcome')}</div>
              </div>
              <div className={css.text}>
                <div>{t('login.text1')}</div>
                <div>{t('login.text2')}</div>
              </div>
              <div className={css.formGroup}>
                <Input
                  inputRef={this.usernameRef}
                  type="text"
                  value={username}
                  placeholder={t('login.username')}
                  onChange={this.setEmail}
                  error={!!errorMsg}
                />
              </div>
              <div className={classNames(css.formGroup, css.noMargin)}>
                <Input
                  inputRef={this.passwordRef}
                  type="password"
                  value={password}
                  placeholder={t('login.password')}
                  onChange={this.setPassword}
                  error={!!errorMsg}
                />
              </div>
              <ErrorMessage message={errorMsg} />
              <Button disabled={loading} fullWidth onClick={this.login}>
                <span>{t('login.submit')}</span>
              </Button>
              <div className={css.getInTouch}>
                {t('login.noAccount')}
                <a className={css.email} href='https://www.signality.com/demo/'>{t('login.getInTouch')}</a>
              </div>
            </form>
          </div>
          <div className={css.footer}>
            <span>{t('login.poweredBy')}</span>
            <a href='https://www.signality.com/'>
              <img
                className={css.signalityLogo}
                src={require("../../assets/images/signality-white.svg")}
                alt={"logo"}
              />
            </a>
          </div>
        </div>
      </section>
    );
  }
}

export default withRouter(observer(Login))
