import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { action } from "mobx";

import gameDetailsState from "../../../stores/GameDetails";
import sidebarState from "../../../stores/SidebarStore";
import format from 'date-fns/format'

import {
  ITimeRange,
  EventTypeSelection,
  PassTypeSelection,
  PossessionTypeSelection,
  ViewSelection,
  TeamSelection,
  IFilterParameters,
  IGameEvent
} from "../../../Types/Types";

import LoadingSpinner from "../../../Components/LoadingSpinner/LoadingSpinner";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import MapView from "./MapView/MapView";
import VideoView from "./VideoView/VideoView";
import EventFilter from "./EventFilter/EventFilter";

import { t } from '../../../localization/i18n';

import css from './GameView.module.scss'
import { IconButton } from "@material-ui/core";
import EventPlaylist from "../../../Components/EventPlaylist/EventPlaylist";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import Page from "../../../Components/Page/Page";
import PageHeader from "../../../Components/PageHeader/PageHeader";
import { observer } from "mobx-react";
import displayConfigState from "../../../stores/DisplayConfigStore";
import VerticalMenu from "../../../Components/VerticalMenu/VerticalMenu";
import StatsView from "./StatsView/StatsView";
import ReportUploader from "../../../Components/ReportUploader/ReportUploader";
import appState from "../../../stores/AppStateStore";

interface IProps extends RouteComponentProps<any> {
  clickedBack: () => void
}

interface IState extends IFilterParameters {
  viewSelection: ViewSelection
  events: IGameEvent[]
  currentEventIndex: number,
  currentEventLastSelected: number
  submenuOptions: string[]
}

@observer
class GameView extends React.Component<IProps, IState> {

  state: IState = {
    viewSelection: ViewSelection.Video,
    events: [],
    currentEventIndex: 0,
    currentEventLastSelected: 0,

    jerseyNumber: -1,
    phase: 1,
    timeRangeMin: 0,
    timeRangeMax: 5,
    team: TeamSelection.Home,
    eventType: EventTypeSelection.Passes,
    passType: PassTypeSelection.Successful,
    possessionType: PossessionTypeSelection.Wins,
    submenuOptions: [],
  }

  componentDidMount () {
    gameDetailsState.getJerseyNumbers(this.props.match.params.id)
    gameDetailsState.getCurrentGame(this.props.match.params.id)
      .then(this.setDisplayMode)
  }

  @action setDisplayMode = () => {
    this.updateSubMenu()
    if (displayConfigState.showFilterComponent) {
      sidebarState.showSidebar()
      this.getFilteredEvents(this.state)
    } else if (displayConfigState.movieOnly) {
      this.updateEventsState([])
    }
    this.setState({ viewSelection: displayConfigState.showGameStatsPage ? ViewSelection.Stats : ViewSelection.Video })
  }

  updateSubMenu () {
    const headerOptions = []
    if (displayConfigState.showVideoPage)
      headerOptions.push(t('recording.viewmode.gameVideo'))
    if (!displayConfigState.movieOnly && displayConfigState.showFieldMapPage)
      headerOptions.push(t('recording.viewmode.fieldMap'))
    if (displayConfigState.showGameStatsPage)
      headerOptions.push(t('recording.viewmode.stats'))

    this.setState({ submenuOptions: headerOptions })
  }

  componentWillUnmount () {
    gameDetailsState.detachAll()
    sidebarState.hideSidebar()
  }

  handleRangeChange = (selectedRange: ITimeRange) => {
    const change = { timeRangeMin: selectedRange.min, timeRangeMax: selectedRange.max }
    this.setState(change, () => {
      this.getFilteredEvents(this.state)
    })
  };

  getFilteredEvents (filter: IFilterParameters) {
    let team: (0 | 1) = filter.team
    let type = ''
    if (filter.eventType === EventTypeSelection.Passes)
      type = filter.passType === PassTypeSelection.Successful ? "pass" : "interception"
    else if (filter.eventType === EventTypeSelection.Possession) {
      type = "ball_win"
      if (filter.possessionType === PossessionTypeSelection.Losses)
        team = team === TeamSelection.Home ? TeamSelection.Away : TeamSelection.Home
    } else if (filter.eventType === EventTypeSelection.Goals) {
      type = 'goal'
    }

    const minTime = filter.timeRangeMin * 60000
    const maxTime = filter.timeRangeMax * 60000
    const jerseyNumber = filter.jerseyNumber

    gameDetailsState.getEvents(
      this.props.match.params.id,
      filter.phase,
      type,
      team,
      minTime,
      maxTime,
      jerseyNumber,
      events => {
        this.updateEventsState(events)
      }
    )
  }

  updateEventsState = (newEvents: IGameEvent[]) => {
    const { events, currentEventIndex } = this.state
    const currentEvent = events[currentEventIndex]
    let newIndex = 0

    newEvents.forEach((e, i) => {
      if (e && currentEvent && e.start_match_time === currentEvent.start_match_time) {
        newIndex = i
      }
    })

    this.setState({
      currentEventIndex: newIndex,
      events: newEvents,
    })
  }

  handleFilterChange = (change: any) => {
    this.setState(change, () => {
      this.getFilteredEvents(this.state)
    })
  }

  getViewComponent = () => {
    switch (this.state.viewSelection) {
      case ViewSelection.Video: return VideoView
      case ViewSelection.FieldMap: return MapView
      case ViewSelection.Stats: return StatsView
      default: return null
    }
  }

  handleTogglePlayAll = () => {
    if (this.state.events.length > 0) {
      this.updateEventsState([])
      this.setState({
        viewSelection: ViewSelection.Video,
      })
    }
    else
      this.getFilteredEvents(this.state)
  }

  handleChangeViewSelection = (index: number) => {
    const option = this.state.submenuOptions[index]
    let select = 0
    switch (option) {
      case ViewSelection.Video.toString():
        select = ViewSelection.Video
        break
      case ViewSelection.FieldMap.toString():
        select = ViewSelection.FieldMap
        break
      case ViewSelection.Stats.toString():
        select = ViewSelection.Stats
        break
      default:
        select = ViewSelection.Video
        break
    }

    this.setState({ viewSelection: select })
  }

  renderFilters = () => {
    const activeGame = gameDetailsState.currentGame
    if (!activeGame) {
      return null
    }

    return (
      <div className={css.eventFilter}>
        <EventFilter
          activeGame={activeGame}
          jerseyNumbers={gameDetailsState.jerseyNumbers}
          onChange={this.handleFilterChange}
          togglePlayMode={this.handleTogglePlayAll}
          onRangeChange={this.handleRangeChange}
          {...this.state}
        />
      </div>
    )
  }

  renderPlaylist = () => {
    const events = this.state.events
    return (
      <div className={css.playlist}>
        <EventPlaylist
          events={events}
          currentEventIndex={this.state.currentEventIndex}
          handleGameEventClick={this.updateCurrentEvent}
          activeGame={gameDetailsState.currentGame}
          updateOnHover={this.state.viewSelection === ViewSelection.FieldMap}
        />
      </div>
    )
  }

  updateCurrentEvent = (i: number) => {
    if (i === this.state.currentEventIndex)
      this.setState({ currentEventLastSelected: (new Date()).getTime() })
    else
      this.setState({ currentEventIndex: i })
  }

  handleReportUpload = async (file: File) => {
    if (!gameDetailsState.currentGame || !gameDetailsState.currentGame.id)
      return
    gameDetailsState.saveGameReport(file, gameDetailsState.currentGame.id)
  }

  render () {
    const activeGame = gameDetailsState.currentGame
    if (!activeGame)
      return <LoadingSpinner />

    const View = this.getViewComponent() as any

    const reportButton = activeGame.report_url ? (
      <IconButton
        title={t('recording.tooltips.report')}
      >
        <a
          href={activeGame.report_url}
          target='__blank'
          className={css.reportLink}
        >
          <FontAwesomeIcon icon={['far', 'file-pdf']} />
        </a>
      </IconButton>
    ) : null

    const jsonButton = activeGame.json_url ? (
      <IconButton
        title={t('recording.tooltips.json')}
      >
        <a
          href={activeGame.json_url}
          target='__blank'
          className={css.reportLink}
        >
          <FontAwesomeIcon icon={['far', 'file-code']} />
        </a>
      </IconButton>
    ) : null

    const homeTeamName = (activeGame && activeGame.home_team) ? activeGame.home_team.name : ''
    const awayTeamName = (activeGame && activeGame.away_team) ? activeGame.away_team.name : ''
    const gameDate = (activeGame && activeGame.startTime) ? format(activeGame.startTime, 'MMM D, YYYY') : ''

    const selected = this.state.submenuOptions.indexOf(this.state.viewSelection.toString())

    const modeSelector = this.state.submenuOptions.length
      ? (
        <VerticalMenu
          selected={selected}
          options={this.state.submenuOptions}
          onChange={this.handleChangeViewSelection}
        />
      ) : null

    const { user } = appState
    const uploadReport = (user && user.type === 'admin')
      ? (
        <ReportUploader
          onSubmit={this.handleReportUpload}
        />
      ) : null

    return (
      <Page fillHeight>
        <PageHeader
          onClickBack={this.props.clickedBack}
          title={`${homeTeamName} - ${awayTeamName}`}
          info={gameDate}
          actions={(
            <div className={css.actions}>
              {modeSelector}
              {jsonButton}
              {reportButton}
              {uploadReport}
            </div>

          )}
        />
        <div className={css.analysisContainer}>
          <View
            eventType={this.state.eventType}
            possessionType={this.state.possessionType}
            phase={this.state.phase}
            events={this.state.events}
            currentEventIndex={this.state.currentEventIndex}
            updateCurrentEventIndex={this.updateCurrentEvent}
            currentEventLastSelected={this.state.currentEventLastSelected}
          />
        </div>
        {/* <div className={css.mapOverlay}>
          <MapView
            eventType={this.state.eventType}
            possessionType={this.state.possessionType}
            phase={this.state.phase}
            events={this.state.events}
            currentEventIndex={this.state.currentEventIndex}
          />
        </div> */}
        <Sidebar title="Filters">
          {this.renderFilters()}
          {this.renderPlaylist()}
        </Sidebar>
      </Page>
    );
  }
}

export default withRouter(GameView)
