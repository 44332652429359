import * as React from 'react'
import Video3d from '../../../../Components/Video3d/Video3d'
import css from './PlaylistPlayer.module.scss'
import { IRecording } from '../../../../stores/Recording'
import { arraysEqual } from '../../../../Utils/CommonFunctions'
import cx from 'classnames';
import displayConfigState from '../../../../stores/DisplayConfigStore';
import { IGameEvent, IPassEvent, IVideoInfo, TracksInfo, IBallFrame } from '../../../../Types/Types';

interface IProps {
  events?: IGameEvent[]
  video: IVideoInfo
  tracks?: TracksInfo | null
  ballFrames?: Map<number, IBallFrame>
  activeGame?: IRecording
  currentEventIndex: number
  currentEventLastSelected?: number
  phaseStartTimes?: number[]
  updateCurrentEventIndex: (i: number) => void
}

interface IState {
  prevEventIds: IGameEvent[],
}

export default class PlaylistPlayer extends React.Component<IProps, IState> {

  state: IState = {
    prevEventIds: [],
  }

  static getDerivedStateFromProps (props: IProps, state: IState) {
    if (props.events) {
      const eventIds = props.events.map(e => e.id)
      if (!arraysEqual(eventIds, state.prevEventIds)) {
        return {
          index: 0,
          prevEventIds: eventIds,
        }
      }
    }
    return null
  }

  handleFragmentDone = (currentTime: number) => {
    if (!this.props.events)
      return
    let index = this.props.currentEventIndex + 1
    if (index >= this.props.events.length)
      index = 0
    this.props.updateCurrentEventIndex(index)
  }

  handleNavigate = (value: ('previous' | 'next')) => {
    if (!this.props.events)
      return

    let index = this.props.currentEventIndex
    if (value === 'previous' && this.props.currentEventIndex > 0)
      index = this.props.currentEventIndex - 1

    else if (value === 'next' && this.props.currentEventIndex < this.props.events.length - 1)
      index = this.props.currentEventIndex + 1

    this.props.updateCurrentEventIndex(index)
  }

  render () {
    if (!this.props.events)
      return null

    let videoStart = -1 // Play from current time
    let videoEnd = -1   // Play to end of video

    if (this.props.phaseStartTimes && displayConfigState.movieOnly) {
      videoStart = this.props.phaseStartTimes[0]
    }

    if (this.props.events.length) {
      if (this.props.currentEventIndex >= this.props.events.length)
        return null

      const event = this.props.events[this.props.currentEventIndex] as IPassEvent

      if (event.type === 'ball_win' && event.initialVersion !== 0) {
        videoStart = Math.max(0, event.start_stream_time[0] / 1000)
        videoEnd = videoStart
      } else if (event.initialVersion === 0) {
        videoStart = Math.max(0, event.start_stream_time[0] / 1000)
        videoEnd = videoStart + event.duration / 1000
      } else {
        videoStart = Math.max(0, event.start_stream_time[0] / 1000)
        videoEnd = videoStart + event.duration
      }
    }

    return (
      <div className={cx(css.container, { [css.videoOnly]: (displayConfigState.movieOnly) })}>
        <Video3d
          video={this.props.video}
          start={videoStart}
          end={videoEnd}
          ballFrames={this.props.ballFrames}
          onFragmentDone={this.handleFragmentDone}
          currentEventIndex={this.props.currentEventIndex}
          currentEventLastSelected={this.props.currentEventLastSelected}
          eventCount={this.props.events.length}
          onNavigate={this.handleNavigate}
          tracks={this.props.tracks}
        />
      </div>
    )
  }
}
