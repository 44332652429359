import * as React from 'react'
import * as ReactDOM from 'react-dom'
import App from './App/App'
import { unregister } from './registerServiceWorker';
import './index.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons'
import { faFilePdf, faFileCode, faFileDownload } from '@fortawesome/free-solid-svg-icons'
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

library.add(far, faFileCode, faFilePdf, faFileDownload)

ReactDOM.render(
  <App />,
  document.getElementById('root'))

// Disable progressive web app for all visitors who visited the app while it was active.
// Could be removed after some time.
unregister();
