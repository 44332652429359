import { t } from "../localization/i18n";

export type Position = [number, number];
export interface ITimeRange {
  min: number;
  max: number;
}
export type PhaseNumber = 1 | 2;
export type AttackDirection = -1 | 1;
export interface IPlayerMovement {
  from_position: Position;
  to_position: Position;
}
export interface IEventMovements {
  players: IPlayerMovement[];
  opponents: IPlayerMovement[];
}

export enum TeamSelection {
  Home,
  Away,
}
export enum ViewSelection {
  Video = t('recording.viewmode.gameVideo'),
  FieldMap = t('recording.viewmode.fieldMap'),
  Stats = t('recording.viewmode.stats'),
}
export enum EventTypeSelection {
  Passes,
  Possession,
  Goals,
}
export enum PassTypeSelection {
  Successful,
  Intercepted,
}
export enum PossessionTypeSelection {
  Wins,
  Losses,
}

export enum GridType {
  Corridors,
  ThreeBySix,
}

export enum PlayMode {
  Events,
  All,
}

export interface IPhaseInfo {
  duration: number
  home_attack_direction: AttackDirection
}

export interface IVideoInfo {
  alpha_map_url?: string
  calibration: any
  landmarks: any
  url: string
  utc: string
}

export interface IBallFrame {
  matchTime: number
  found: boolean
  x: number
  y: number
}

export interface IJerseyNumbers {
  home: number[]
  away: number[]
}

export type ITrackItem = number[]
export type TracksInfo = ITrackItem[]


// export type MatchListItem { id: string, homeTeam: string, awayTeam: string }

export interface IFilterParameters {
  phase: PhaseNumber;
  timeRangeMin: number;
  timeRangeMax: number;
  team: TeamSelection;
  eventType: EventTypeSelection;
  passType: PassTypeSelection;
  possessionType: PossessionTypeSelection;
  jerseyNumber: number;
}

export interface IPlayer {
  id?: string;
  name: string;
}

export interface IPlayerReport {
  id: string;
  url: string;
  date?: string;
  phase?: string;
}

export interface IStats {
  phase: number
  match_time: number
  team: string
  possession: number
  possessing_team?: 0 | 1
  failed_passes?: number
  distance: number
  sprints: number,
  highspeedRuns: number
  decelerations: number
  accelerations: number
  successfulPasses: number
  ballWon: number
  ballLost: number
}

export interface IPlayerStats {
  id: string,
  match_time: number
  phase: 1 | 2
  team: 'home_team' | 'away_team'
  speed: number
  distance: number
  sprints: number
  hi_runs: number
  decelerations: number
  accelerations: number
  successful_passes: number
  ball_won: number
  ball_lost: number
  sprint: boolean
  jersey_number: number
}

export interface ITeamPlayer {
  id?: string;
  first_name: string;
  last_name: string;
  num?: string;
  position?: string;
  photo?: string;
  reports?: IPlayerReport[];
  selected?: boolean
}

export interface ITeam {
  id: string;
  name: string;
  players: ITeamPlayer[];
}

export interface ILineChartDataItem {
  y1: number
  y2: number
  x: number
}

export interface IBarChartDataItem {
  category: string
  y: number
}

export interface ITableData {
  header: string[]
  rows: ITableRow[]
}

export interface ITableRow {
  id: string
  content: string[]
}

export interface IGameEvent {
  id: string
  phase: 1 | 2
  type: string //failed_pass -> interception, + start
  start_match_time: number //time
  utc_time: number // not useful anymore (changed from start of event to time of event generation)
  start_stream_time: [number, number] //stream_times
  from_team?: TeamSelection //'home_team' | 'away_team' => 0 | 1
  initialVersion?: number
  live_insight?: ILiveInsightFields
  false_positive?: boolean
}

export interface IBallWinEvent extends IGameEvent {
  team?: 'home_team' | 'away_team'// => 0 | 1
  jersey_number?: number
  ball_position: Position
}

export interface IGameEventSequence {
  events: IGameEvent[]
}

interface ILiveInsightFields {
  from_jersey_number: number
  from_team: 'home_team' | 'away_team'

  jersey_numbers: number[]

  to_jersey_number: number
  to_team: 'home_team' | 'away_team'
}

export interface IPassEvent extends IGameEvent {
  distance: number
  duration: number //ms -> s
  from_position: Position //(0-1) -> meters
  to_position: Position //(0-1) -> meters
  speed: number
  from_team: TeamSelection
  to_team: TeamSelection
}

export interface IGameEventV0 {
  id: string
  type: string
  time: number
  phase?: 1 | 2
  utc_time: number
  stream_times: [number, number]
  team?: 'home_team' | 'away_team'
  initialVersion?: number
}

// interface IPassEventV0 extends IGameEventV0 {
//   distance: number
//   duration: number
//   from_position: Position
//   to_position: Position
//   speed: number
//   time: number
//   team: 'home_team' | 'away_team'
// }

export type IconName = 'back' | 'close' | 'expand' | 'menu' | 'more' | 'settings' | 'edit' | 'download'
