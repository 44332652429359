class LinearInterpolation {
  s: number;
  d: number;
  n: number;

  constructor (normale: number, starting: number = 0, destination: number = 0) {
    this.n = normale
    this.s = starting
    this.d = destination
  }

  updateStarting (starting: number) {
    this.s = starting
  }

  updateDestination (destination: number) {
    this.d = destination
  }

  getValue () {
    return (1 - this.n) * this.s + this.n * this.d;
  }

  update (starting: number) {
    this.updateStarting(starting)
    return this.getValue()
  }
}

export default LinearInterpolation
