import * as React from "react";
import { RouteComponentProps } from "react-router";

import { observer } from "mobx-react";
import teamState from "../../../stores/TeamStore";
import { t } from "../../../localization/i18n";

import LoadingSpinner from "../../../Components/LoadingSpinner/LoadingSpinner";

import Squad from './Squad/Squad';

import Page from "../../../Components/Page/Page";
import PageHeader from "../../../Components/PageHeader/PageHeader";
import PageContent from "../../../Components/PageContent/PageContent";
import PlayerModal from "../PlayerModal/PlayerModal";
import Button from "../../../Components/Button/Button";
import { ITeamPlayer } from "../../../Types/Types";

interface IProps {
  id: string
}

interface IState {
  isPlayerModalOpen: boolean
}

export default observer(class Team extends React.Component<RouteComponentProps<IProps>, IState> {
  constructor (props: RouteComponentProps<IProps>) {
    super(props)

    this.state = {
      isPlayerModalOpen: false,
    }
  }
  componentDidMount () {
    const id = this.props.match.params.id
    teamState.getTeamById(id)
  }

  openModal = () => {
    if (!this.state.isPlayerModalOpen) {
      this.setState({ isPlayerModalOpen: true })
    }
  }

  closeModal = () => {
    if (this.state.isPlayerModalOpen) {
      this.setState({ isPlayerModalOpen: false })
    }
  }


  addPlayer = async (player: ITeamPlayer, photo: File | null) => {
    if (teamState.currentTeam) {
      const teamId = teamState.currentTeam.id
      teamState.addNewPlayer(teamId, player, photo)
      this.closeModal()
    }
  }

  renderContent = () => {
    const team = teamState.currentTeam
    const loading = teamState.loading

    if (loading) {
      return (
        <LoadingSpinner />
      )
    }

    if (!team) return null;
    const id = this.props.match.params.id

    return (
      <Squad teamId={id} players={team.players} />
    )
  }

  render () {
    const team = teamState.currentTeam
    const { isPlayerModalOpen } = this.state
    return (
      <Page>
        <PageHeader
          title={`${t('sections.team')} ${team ? ` - ${team.name}` : ''}`}
          actions={(
            <Button
              small
              onClick={this.openModal}
            >
              {t('team.actions.addNewPlayer')}
            </Button>
          )}
        />

        <PlayerModal
          isOpen={isPlayerModalOpen}
          onClose={this.closeModal}
          playerToEdit={null}
          addPlayer={this.addPlayer}
        />

        <PageContent>
          {this.renderContent()}
        </PageContent>
      </Page>
    );
  }
})
