//Same as Opencv undistortPoints

type Vec2 = [number, number]
type Mat3 = [[number, number, number], [number, number, number], [number, number, number]]

export function undistortPoint (point: Vec2, mtx: Mat3, dist_params: [number, number, number, number, number], newcameramtx: Mat3) {
  const fx = mtx[0][0];
  const fy = mtx[1][1];
  const ifx = 1. / fx;
  const ify = 1. / fy;
  const cx = mtx[0][2];
  const cy = mtx[1][2];

  let x = (point[0] - cx) * ifx
  let y = (point[1] - cy) * ify
  const x0 = x
  const y0 = y

  for (let i = 0; i < 5; ++i) {
    const r2 = x * x + y * y
    const icdist = 1 / (1 + ((dist_params[4] * r2 + dist_params[1]) * r2 + dist_params[0]) * r2);
    const deltaX = 2 * dist_params[2] * x * y + dist_params[3] * (r2 + 2 * x * x);
    const deltaY = dist_params[2] * (r2 + 2 * y * y) + 2 * dist_params[3] * x * y;
    x = (x0 - deltaX) * icdist;
    y = (y0 - deltaY) * icdist;
  }

  const xx = newcameramtx[0][0] * x + newcameramtx[0][1] * y + newcameramtx[0][2];
  const yy = newcameramtx[1][0] * x + newcameramtx[1][1] * y + newcameramtx[1][2];
  const ww = 1. / (newcameramtx[2][0] * x + newcameramtx[2][1] * y + newcameramtx[2][2]);
  x = xx * ww;
  y = yy * ww;

  return [x, y] as Vec2
}
