import React from 'react';
import css from './PageContent.module.scss'

interface IProps {
  children: any
}

const PageContent = ({ children }: IProps) => (
  <div className={css.content}>
    {children}
  </div>
)

export default PageContent
