import { t } from '../localization/i18n';
import gameDetailsState from './GameDetails';
import { EventTypeSelection } from '../Types/Types';

class FilterOptionsStore {

  homeTeamName = ''
  awayTeamName = ''
  jerseyNumber = -1

  getPhaseOptions () {
    return {
      key: 'phase',
      label: t('filter.time_span'),
      values: [
        { key: 1, label: t('half.1') },
        { key: 2, label: t('half.2') },
      ],
      range: true,
    }
  }

  getpossessionOptions () {
    const k = EventTypeSelection.Possession
    return {
      key: k,
      label: t('filter.possession'),
      sub: [{
        key: 'possessionType',
        values: [
          { key: 0, label: t('filter.wins') },
          { key: 1, label: t('filter.losses') },
        ],
      }],
    }
  }

  getPassesOptions () {
    const k = EventTypeSelection.Passes
    return {
      key: k,
      label: t('filter.passes'),
      // sub: [{
      //   key: 'passType',
      //   values: [
      //     { key: 0, label: t('filter.successful') },
      //     { key: 1, label: t('filter.failed') },
      //   ],
      // }],
    }
  }

  getGoalOptions () {
    const k = EventTypeSelection.Goals
    return {
      key: k,
      label: t('filter.goals'),
    }
  }

  getTeamOptions (teamName1: string, teamName2: string) {
    return {
      key: 'team',
      label: t('filter.teams'),
      jerseyNumbers: true,
      values: [
        { key: 0, label: teamName1 },
        { key: 1, label: teamName2 },
      ],
    }

  }

  defaultOptions = [
    this.getTeamOptions('', ''),
    this.getEventOptions(['passes', 'possession', 'goals']),
    this.getPhaseOptions(),
  ]

  getEventOptions (types: string[]) {

    const optionValues: any = []
    types.forEach(type => {
      switch (type) {
        case 'passes':
          optionValues.push(this.getPassesOptions())
          break;
        case 'possession':
          optionValues.push(this.getpossessionOptions())
          break;
        case 'goals':
          optionValues.push(this.getGoalOptions())
          break;
        default:
          break;
      }
    })
    return {
      key: 'eventType',
      label: t('filter.events'),
      disabledLabel: t('filter.eventsShowAll'),
      disabled: () => this.jerseyNumber !== -1,
      values: optionValues,
    }
  }

  getFilterOptions () {
    const game = gameDetailsState.currentGame
    if (game && game.home_team && game.away_team) {
      return [
        this.getTeamOptions(game.home_team.name, game.away_team.name),
        this.getEventOptions(['passes', 'goals']),
        // this.getEventOptions(['passes', 'possession']), //, 'goals']),
        this.getPhaseOptions(),
      ]
    }

    return this.defaultOptions
  }
}

const filterOptionsState = new FilterOptionsStore()
export default filterOptionsState
