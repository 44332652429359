import { decorate, observable } from "mobx";
import { ITrackItem } from "../Types/Types";

class TacticalCameraStore {
  currentExtremePositions: number[]
  currentTracks: ITrackItem
  nextTracks: ITrackItem

  constructor () {
    this.currentExtremePositions = []
    this.currentTracks = []
    this.nextTracks = []
  }

  async setTracks (current: ITrackItem, next: ITrackItem) {
    this.currentTracks = current
    this.nextTracks = next
  }

}

decorate(TacticalCameraStore, {
  currentExtremePositions: observable,
  currentTracks: observable,
  nextTracks: observable,
})

const tacticalCameraState = new TacticalCameraStore()
export default tacticalCameraState
