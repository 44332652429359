
interface IVec2 {
  x: number
  y: number
}

export class Video3dControls {

  zoom: number = 1
  rotation: IVec2 = { x: 0, y: 0 } // [-1..1, -1..1]

  isMouseDown: boolean = false

  // dbg: HTMLDivElement

  constructor (
    public element: HTMLElement,
    public onRotate: (x: number, y: number) => void,
    public onZoom: (zoom: number) => void,
    private minZoom: number,
    private maxZoom: number
  ) {
    this.element.addEventListener('wheel', this.handleMouseWheel)
    this.element.addEventListener('mousedown', this.handleMouseDown)

    // this.dbg = document.createElement('div')
    // this.dbg.style.borderRadius = '50%'
    // this.dbg.style.position = 'absolute'
    // this.dbg.style.width = '20px'
    // this.dbg.style.height = '20px'
    // this.dbg.style.marginLeft = '-10px'
    // this.dbg.style.marginTop = '-10px'
    // this.dbg.style.left = `${(0.5 + this.rotation.x / 2) * 100}%`
    // this.dbg.style.top = `${(0.5 + this.rotation.y / 2) * 100}%`
    // this.dbg.style.background = 'yellow'
    // this.dbg.style.border = '1px solid black'
    // this.element.appendChild(this.dbg)
    // this.element.style.position = 'relative'
    // this.element.style.userSelect = 'none'
  }

  destroy () {
    this.element.removeEventListener('wheel', this.handleMouseWheel)
    document.body.removeEventListener('mousedown', this.handleMouseDown)
    document.body.removeEventListener('mousemove', this.handleMouseMove)
    document.body.removeEventListener('mouseup', this.handleMouseUp)
    document.body.removeEventListener('mouseleave', this.handleMouseLeave)
  }

  handleMouseDown = (e: MouseEvent) => {
    if (e.button !== 0)
      return
    this.isMouseDown = true
    document.body.addEventListener('mousemove', this.handleMouseMove)
    document.body.addEventListener('mouseup', this.handleMouseUp)
    document.body.addEventListener('mouseleave', this.handleMouseLeave)
  }

  handleMouseUp = (e: MouseEvent) => {
    if (e.button !== 0)
      return
    this.isMouseDown = false
    document.body.removeEventListener('mousemove', this.handleMouseMove)
    document.body.removeEventListener('mouseup', this.handleMouseUp)
    document.body.removeEventListener('mouseleave', this.handleMouseLeave)
  }
  handleMouseLeave = () => {
    this.isMouseDown = false
    document.body.removeEventListener('mousemove', this.handleMouseMove)
    document.body.removeEventListener('mouseup', this.handleMouseUp)
    document.body.removeEventListener('mouseleave', this.handleMouseLeave)
  }

  handleMouseMove = (e: MouseEvent) => {
    if (!this.isMouseDown)
      return

    this.rotation.x += (2 * e.movementX / this.element.clientWidth) / this.zoom
    this.rotation.y += (2 * e.movementY / this.element.clientHeight) / this.zoom

    this.rotation.x = clamp(this.rotation.x, -1, 1)
    this.rotation.y = clamp(this.rotation.y, -1, 1)
    this.onRotate(this.rotation.x, -this.rotation.y)

    // this.dbg.style.left = `${(0.5 + this.rotation.x / 2) * 100}%`
    // this.dbg.style.top = `${(0.5 + this.rotation.y / 2) * 100}%`
  }

  handleMouseWheel = (e: MouseWheelEvent) => {
    const delta = 1.1
    let zoom = this.zoom
    if (e.deltaY < 0)
      zoom *= delta
    else
      zoom /= delta

    this.zoom = clamp(zoom, this.minZoom, this.maxZoom)

    e.preventDefault()
    this.onZoom(this.zoom)

    return false
  }
}

function clamp (val: number, min: number, max: number) {
  return Math.max(Math.min(val, max), min)
}
