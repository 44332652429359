import React from 'react'
import Widget from '../Widget/Widget';
import css from './List.module.scss'
import ListItem from './ListItem/ListItem';


interface IProps {
  title: string
  items: React.ReactChild[]
}

export const List = ({
  title,
  items,
}: IProps) => {
  const listItemComponents = items.map((item, i) => {
    return (
      <ListItem key={i} even={i % 2 === 0}>
        {item}
      </ListItem>
    )
  })

  return (
    <Widget title={title}>
      <div className={css.list}>
        {listItemComponents}
      </div>
    </Widget>
  )
}

export default List
