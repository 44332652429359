import React from 'react'
import cx from 'classnames'
import css from './PageHeader.module.scss'
import ReactDOM from 'react-dom';
import IconButton from '../IconButton/IconButton';

interface IProps {
  title: string
  info?: string
  actions?: React.Component | JSX.Element
  relative?: boolean
  onClickBack?: () => void
}


export const PageHeader = ({
  title,
  info,
  actions,
  relative,
  onClickBack,
}: IProps) => {
  const actionComponents = actions
    ? (
      <div>
        {actions}
      </div>
    ) : null

  const backButtonComponent = onClickBack
    ? (
      <div className={css.back}>
        <IconButton onClick={onClickBack} icon='back' />
      </div>
    ) : null

  const content = (
    <div className={cx(css.wrapper, { [css.relative]: relative })}>
      <div className={css.left}>
        {backButtonComponent}
        <div className={css.textWrapper}>
          <span className={css.title}>{title}</span>
          <span className={css.info}>{info}</span>
        </div>
      </div>
      <div className={css.actions}>
        {actionComponents}
      </div>
    </div>
  )

  const headerEl = document.querySelector('#appHeader')

  if (headerEl) {
    return (
      ReactDOM.createPortal(
        content,
        headerEl
      )
    )
  }

  return content
}

export default PageHeader
