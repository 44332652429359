import React from 'react'
import RadioGroup from '../../../../../Components/RadioGroup/RadioGroup';
import cx from 'classnames'
import css from './Filter.module.scss'
import { PlayMode, ITimeRange } from '../../../../../Types/Types';
import RangeSlider from '../../../../../Components/RangeSlider/RangeSlider';
import Autocomplete from '../../../../../Components/Autocomplete/Autocomplete';
import { t } from '../../../../../localization/i18n';
import displayConfigState from '../../../../../stores/DisplayConfigStore';

interface IProps {
  filter: any
  playMode: PlayMode
  isChecked: (filter: string, value: any) => boolean
  handleFilterChange: (key: string) => (value: any) => void
  isSub?: boolean

  jerseyNumber: number
  jerseyNumbers: number[]

  //RangeSlider props
  onRangeChange: (range: ITimeRange) => void
  timeRangeMin: number
  timeRangeMax: number
}

class Filter extends React.Component<IProps> {
  renderRangeSlider = () => {
    if (this.props.playMode === PlayMode.All) {
      return null
    }


    return (
      <RangeSlider
        defaultRange={
          {
            min: this.props.timeRangeMin,
            max: this.props.timeRangeMax,
          }
        }
        setRange={this.props.onRangeChange}
      />
    );
  }

  getNumberOptions = () => {
    return [-1].concat(this.props.jerseyNumbers)
      .sort((a, b) => (a - b))
      .map((num: number) => ({
        value: num.toString(),
        label: num === -1 ? t('player.all') : num.toString(),
      }))
  }

  handleJerseyNumberChange = (value: string) => {
    this.props.handleFilterChange('jerseyNumber')(+value)
  }

  handleFilterChange = (key: string) => (value: any) => {
    this.props.handleFilterChange(key)(value)

    if (key === 'team') {
      this.handleJerseyNumberChange('-1')
    }
  }

  renderJerseyNumbersSelector = () => {
    if (!this.props.jerseyNumbers.length) return null;
    const { jerseyNumber } = this.props
    const options = this.getNumberOptions()

    return (
      <div className={css.autocompleteWrapper}>
        <Autocomplete
          options={options}
          onSelect={this.handleJerseyNumberChange}
          value={jerseyNumber ? jerseyNumber.toString() : ''}
          label={t('player.num')}
          fullWidth
          small
          secondary
        />
      </div>
    )
  }

  renderMainFilters = () => {
    const {
      filter,
      isSub,
      ...rest
    } = this.props

    if (!filter) return null;
    if (filter.disabled && filter.disabled()) return null

    const values = filter.values as any[]
    let subElements: any[] = []

    let selected: string = ''

    const filterOptions = values.map((value: any) => {
      const isChecked = this.props.isChecked(filter.key, value.key)
      if (isChecked) {
        selected = value.key
      }

      if (value.sub && isChecked) {
        const subArr = value.sub.map((sub: any) => {
          return (
            <div
              key={sub.key}
              className={css.subfilter}
            >
              <Filter
                filter={sub}
                isSub
                {...rest}
              />
            </div>
          )
        })
        subElements = subElements.concat(subArr)
      }

      return ({ key: value.key, label: value.label })
    })
    return (
      <>
        <RadioGroup
          onSelect={this.handleFilterChange(filter.key)}
          selected={selected}
          options={filterOptions}
        />
        {filter.jerseyNumbers && displayConfigState.showIndividualStats ? this.renderJerseyNumbersSelector() : null}
        {filter.range ? this.renderRangeSlider() : null}
        {subElements}
      </>
    )
  }

  render () {
    const {
      filter,
      isSub,
    } = this.props

    if (!filter) return null;

    const isDisabled = filter.disabled && filter.disabled()

    let label = filter.label
      ? (<span className={css.title}>{filter.label}</span>)
      : null

    if (isDisabled && filter.disabledLabel) {
      label = (
        <span className={css.title}>
          {filter.disabledLabel}
        </span>
      )
    }

    return (
      <div className={cx(css.groupWrapper, { [css.disabled]: isDisabled })}>
        <div className={cx(css.wrapper, { [css.wrapperSub]: isSub })}>
          {label}
          {this.renderMainFilters()}
        </div>
      </div >

    )

  }
}

export default Filter
