import * as React from "react";

import LineChart from '../../../Components/LineChart/LineChart';

import { t } from '../../../localization/i18n'
import css from './Main.module.scss';

const DATA = [
  { x: 5, y1: 10, y2: 5 },
  { x: 10, y1: 12, y2: 13 },
  { x: 15, y1: 7, y2: 11 },
  { x: 20, y1: 9, y2: 13 },
  { x: 25, y1: 12, y2: 7 },
  { x: 30, y1: 5, y2: 8 },
  { x: 35, y1: 10, y2: 5 },
  { x: 40, y1: 12, y2: 13 },
  { x: 45, y1: 10, y2: 11 },
  { x: 50, y1: 9, y2: 15 },
  { x: 55, y1: 6, y2: 7 },
  { x: 60, y1: 5, y2: 9 },
]

class Main extends React.Component<any, any> {
  render () {
    return (
      <div className={css.container}>
        <h1 className={css.title}>{t('reports.title')}</h1>
        <div className={css.chartContainer}>
          <LineChart
            id='passes'
            data={DATA}
            flexible
          />
        </div>
      </div>
    )
  }
}

export default Main;
