import React from 'react'
import { IStatsListItem } from '../StatsList/StatsListItem/StatsListItem';
import StatsList from '../StatsList/StatsList';
import { roundValueToString } from '../../../Utils/CommonFunctions';
import { IStats } from '../../../Types/Types';
import { t } from '../../../localization/i18n';

interface IProps {
  title: string
  stats: IStats
}

export const GameStatsList = ({
  title,
  stats,
}: IProps) => {

  const items: IStatsListItem[] = [
    {
      id: '0',
      title: t('stats.distance'),
      value: roundValueToString(stats.distance / 1000, 2) + ' km',
    },
    {
      id: '1',
      title: t('stats.passes'),
      value: stats.successfulPasses.toString(),
    },
    {
      id: '2',
      title: t('stats.sprints'),
      value: stats.sprints.toString(),
    },
    {
      id: '3',
      title: t('stats.high_intensity_runs'),
      value: stats.highspeedRuns.toString(),
    },
    {
      id: '4',
      title: t('stats.accelerations'),
      value: stats.accelerations.toString(),
    },
    {
      id: '5',
      title: t('stats.decelerations'),
      value: stats.decelerations.toString(),
    },
    {
      id: '6',
      title: t('stats.balls_won'),
      value: stats.ballWon.toString(),
    },
    {
      id: '7',
      title: t('stats.balls_lost'),
      value: stats.ballLost.toString(),
    },
  ]

  return (
    <StatsList
      title={title}
      items={items}
    />
  )
}

export default GameStatsList
