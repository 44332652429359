import * as React from "react";

import css from './IconButton.module.scss'
import classNames from 'classnames'

import { IconName } from '../../Types/Types'
import Icon from '../Icon/Icon'

interface IProps {
  /** either onClick or href is required */
  onClick?: (evt: any) => void
  /** either onClick or href is required */
  href?: string

  className?: string
  style?: any
  target?: string
  title?: string

  disabled?: boolean
  small?: boolean
  /** icon name */
  icon: IconName
}

export class IconButton extends React.Component<IProps> {
  handleClick = (evt: React.SyntheticEvent) => {
    evt.nativeEvent.stopImmediatePropagation()

    const { onClick, disabled } = this.props
    if (onClick && !disabled) onClick(evt);
  }

  stopPropagation = (evt: React.SyntheticEvent) => {
    evt.nativeEvent.stopImmediatePropagation()
  }


  render () {
    const {
      href,
      className,
      disabled,
      small,
      icon,
      ...rest
    } = this.props

    const iconComponent = <Icon icon={icon} />

    const classes = classNames(
      css.button,
      {
        [css.disabled]: disabled,
        [css.small]: small,
      },
      className
    )

    if (href) {
      return (
        <a
          href={href}
          className={classes}
          {...rest}
        >
          {iconComponent}
        </a>
      );

    }

    return (
      <button
        className={classes}
        onClick={this.handleClick}
        {...rest}
      >
        {iconComponent}
      </button>
    );
  }
}

export default IconButton
