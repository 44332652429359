import * as React from 'react'

import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import { t } from '../../localization/i18n';

import css from './ImageUploader.module.scss'
import classNames from 'classnames'

interface IProps {
  initial?: string
  onChange: (file: File | null) => void
  onRemove: () => void
}

interface IState {
  file: File | null
  isHovered: boolean
  error: string | null
  imagePreviewUrl: string | null
}

const ALLOWED_MIME_TYPES = [
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/bmp',
]

const MAX_SIZE = 5 * 1024 * 1024;

class ImageUploader extends React.Component<IProps, IState> {

  fileInput: any = null;
  constructor (props: IProps) {
    super(props);
    this.state = {
      isHovered: false,
      file: null,
      error: null,
      imagePreviewUrl: props.initial || null,
    };
  }

  errorTimeout?: number = undefined;

  dismissError = () => {
    window.clearTimeout(this.errorTimeout)
    this.errorTimeout = window.setTimeout(() => this.setState({ error: null }), 5000)
  }

  handleImageChange = (e: React.SyntheticEvent) => {
    e.preventDefault();

    const reader = new FileReader();
    const target = e.target as HTMLInputElement

    if (target && target.files && target.files.length) {
      const file = target.files[0];

      reader.onloadend = () => {
        if (file.size > MAX_SIZE) {
          this.setState({
            error: t('imageUploader.errors.size'),
          }, () => this.dismissError());
        } else if (ALLOWED_MIME_TYPES.indexOf(file.type) === -1) {
          this.setState({
            error: t('imageUploader.errors.type'),
          }, () => this.dismissError());
        } else {
          this.setState({
            error: null,
            file,
            imagePreviewUrl: String(reader.result),
          });
          this.props.onChange(file)
        }
      }

      reader.readAsDataURL(file)
    }
  }

  onMouseEnter = () => {
    if (!this.state.isHovered) {
      this.setState({ isHovered: true })
    }
  }

  onMouseLeave = () => {
    if (this.state.isHovered) {
      this.setState({ isHovered: false })
    }
  }

  onClickEdit = () => {
    if (this.fileInput) {
      this.fileInput.click()
    }
  }

  onDelete = () => {
    this.setState({
      isHovered: false,
      file: null,
      error: null,
      imagePreviewUrl: null,
    });
    this.props.onRemove()
  }

  render () {
    const {
      imagePreviewUrl,
      error,
    } = this.state;

    let imagePreview: JSX.Element | null = null;

    if (imagePreviewUrl) {
      imagePreview = (
        <img src={imagePreviewUrl} className={css.image} />
      );
    } else {
      imagePreview = (
        <div className={css.placeholder}>
          <CloudUploadIcon />
          <span className={css.placeholderText}>{t('imageUploader.selectImage')}</span>
        </div>);
    }

    let errorComponent: JSX.Element | null = null
    if (error) {
      errorComponent = (
        <div className={css.error}>
          {error}
        </div>
      )
    }

    const { isHovered } = this.state
    let overlayComponent: JSX.Element | null = null
    if (imagePreviewUrl) {
      overlayComponent = (
        <div className={classNames(css.overlay, { [css.active]: isHovered })}>
          <IconButton onClick={this.onClickEdit}>
            <EditIcon fontSize="small" style={{ color: 'white' }} />
          </IconButton>
          <IconButton onClick={this.onDelete}>
            <DeleteIcon fontSize="small" style={{ color: 'white' }} />
          </IconButton>
        </div>
      )
    }

    return (
      <div className={css.externalWrapper} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
        {overlayComponent}
        <label className={css.wrapper}>
          <input
            ref={fileInput => this.fileInput = fileInput}
            className={css.fileInput}
            type="file"
            onChange={this.handleImageChange}
          />

          <div className={css.imageWrapper}>
            {imagePreview}
          </div>
        </label>
        {errorComponent}
      </div>
    )
  }
}

export default ImageUploader
