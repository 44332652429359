import * as React from 'react'

import { observer } from 'mobx-react'
import sidebarState from "../../../../stores/SidebarStore";

import { ITeamPlayer } from '../../../../Types/Types'
import PlayerCard from '../../../../Components/PlayerCard/PlayerCard'
import GridSection, { IGridItem } from '../../../../Components/GridSection/GridSection';

import { t } from '../../../../localization/i18n'
import Sidebar from '../../../../Components/Sidebar/Sidebar';
import Player from '../../Player/Player';

interface IProps {
  players: ITeamPlayer[]
  teamId: string
}

interface IState {
  activePlayer: ITeamPlayer | null
}

class Squad extends React.Component<IProps, IState> {
  constructor (props: IProps) {
    super(props)
    this.state = {
      activePlayer: null,
    }
  }

  componentWillUnmount () {
    sidebarState.hideSidebar()
  }

  closeSidebar = () => {
    sidebarState.hideSidebar()
  }

  openPlayerModal = (player: ITeamPlayer) => () => {
    this.setState({ activePlayer: player }, () => {
      sidebarState.showSidebar()
    })
  }

  renderSidebar = () => {
    const { teamId } = this.props
    const player = this.state.activePlayer

    if (player) {
      const playerPage = (
        <Player
          id={teamId}
          playerId={player.id || ''}
          onClose={this.closeSidebar}
        />
      )
      return (
        <Sidebar
          onClose={this.closeSidebar}
          title={`${player.first_name} ${player.last_name}`}
        >
          {playerPage}
        </Sidebar>
      )
    }

    return null
  }

  render () {
    const { players } = this.props
    const playerComponents = players
      ? players.map(p => {
        return (
          {
            id: p.id,
            component: (
              <PlayerCard
                key={p.id}
                first_name={p.first_name}
                last_name={p.last_name}
                num={p.num}
                img={p.photo}
                onClick={this.openPlayerModal(p)}
              />
            ),
          } as IGridItem
        )
      })
      : []

    return (
      <>
        {this.renderSidebar()}
        <GridSection
          title={t('team.squad')}
          items={playerComponents}
        />
      </>
    )

  }

}

export default observer(Squad)
