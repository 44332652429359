import React from "react";
import { IRecording } from "../../stores/Recording";
import { ITeam } from "../../Types/Types";
import AdminGameListItem from "./AdminGameListItem";
import css from "./AdminGameList.module.scss"

interface IProps {
  games: IRecording[]
  allTeams: ITeam[]
}

export default class AdminGameList extends React.Component<IProps> {

  render () {

    const { games, allTeams } = this.props
    return (
      <div className={css.container}>
        <h2>Games</h2>
        <div className={css.row}>
          <div className={css.date}>Date</div>
          <div className={css.title}>Game</div>
          <div className={css.teams}>Authorized teams</div>
          <div className={css.form} />
        </div>
        {games.map(game => (
          <AdminGameListItem key={game.id} game={game} allTeams={allTeams} />
        ))}
      </div>
    )
  }
}
