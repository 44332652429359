import React from "react";

interface IProps {
  onSubmit: (file: File) => void
}
const ALLOWED_MIME_TYPE = '.pdf'
// const MAX_SIZE = 5000000000

class ReportUploader extends React.Component<IProps> {

  files: File[] = []

  handleFileChange = (e: React.SyntheticEvent) => {
    e.preventDefault();

    const reader = new FileReader();
    const target = e.target as HTMLInputElement

    if (target && target.files && target.files.length) {
      const file = target.files[0];

      reader.onloadend = () => {
        // if (file.size > MAX_SIZE) {
        //   this.setState({
        //     error: t('imageUploader.errors.size'),
        //   }, () => this.dismissError());
        // } else {
        this.files.push(file)
        // }
      }

      reader.readAsDataURL(file)
    }
  }

  errorTimeout?: number = undefined;

  dismissError = () => {
    window.clearTimeout(this.errorTimeout)
    this.errorTimeout = window.setTimeout(() => this.setState({ error: null }), 5000)
  }

  handleSubmit = async () => {
    if (this.files[0])
      this.props.onSubmit(this.files[0])
  }

  render () {

    const style = {
      display: 'flex',
      flexDirection: 'column' as 'column',
    }

    return (
      <div style={style}>
        <div>Upload a report</div>
        <input
          type="file"
          onChange={this.handleFileChange}
          accept={ALLOWED_MIME_TYPE}
        />
        <button onClick={this.handleSubmit}>
          Save report
        </button>

      </div>
    )
  }
}

export default ReportUploader
