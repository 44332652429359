import * as React from 'react'

import css from './EventPlaylist.module.scss'
import EventPlaylistItem from '../EventPlaylistItem/EventPlaylistItem'
import { IRecording } from '../../stores/Recording'
import { t } from '../../localization/i18n';
import { IGameEvent } from '../../Types/Types';

interface IProps {
  events: IGameEvent[]
  currentEventIndex: number
  activeGame?: IRecording
  updateOnHover?: boolean
  handleGameEventClick: (index: number) => void
}

interface IState {
  scrolling: boolean
}

export default class EventPlaylist extends React.Component<IProps, IState> {
  constructor (props: IProps) {
    super(props)
    this.state = {
      scrolling: false,
    }
    this.scrollingTimeout = 0
  }

  getPlaylist () {
    const homeTeam = this.props.activeGame && this.props.activeGame.home_team ? this.props.activeGame.home_team.name : ''
    const awayTeam = this.props.activeGame && this.props.activeGame.away_team ? this.props.activeGame.away_team.name : ''

    if (!this.props.events.length)
      return (
        <div className={css.infoText}>{t('filter.noEvents')}</div>
      )
    if (this.props.events.length > 0) {
      const playlist = this.props.events.map((event, i) => {
        const isEven = i % 2 === 0
        const isActive = (i === this.props.currentEventIndex) ? true : false
        return (
          <div
            key={event.id}
            onClick={this.handleEventClick(i)}
            onMouseOver={this.handleMouseOver(i)}
          >
            <EventPlaylistItem
              even={isEven}
              homeTeam={homeTeam}
              awayTeam={awayTeam}
              event={event}
              active={isActive}
              activeTopOffset={this.checkScroll}
            />
          </div>
        )
      })
      return (
        <div>
          {playlist}
        </div>
      )
    }
    return
  }

  handleMouseOver = (index: number) => (evt: any) => {
    if (this.props.updateOnHover && this.props.currentEventIndex !== index) {
      this.props.handleGameEventClick(index)
    }
  }

  handleEventClick = (index: number) => (evt: any) => {
    this.props.handleGameEventClick(index)
  }

  scrollingTimeout: any
  onScroll = (evt: React.SyntheticEvent) => {
    if (this.state.scrolling) return;

    this.setState({ scrolling: true })

    if (this.scrollingTimeout) {
      window.clearTimeout(this.scrollingTimeout)
    }
    this.scrollingTimeout = setTimeout(this.resetScrolling, 300)
  }

  resetScrolling = () => {
    this.setState({ scrolling: false })
  }

  checkScroll = (y: number, h: number) => {
    if (this.state.scrolling) return;

    const element = document.getElementById('playlist')

    if (element) {
      const itemBottom = y + h
      const remainder = itemBottom % element.clientHeight;
      if (remainder < h || y < element.scrollTop || itemBottom > element.clientHeight + element.scrollTop) {
        element.scroll({
          top: y,
          left: 0,
          behavior: 'smooth',
        });
      } else if (this.props.currentEventIndex === 0) {
        element.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      }
    }
  }

  render () {

    const length = this.props.events && this.props.events.length ? this.props.events && this.props.events.length : 0

    return (
      <div className={css.container}>
        <span className={css.title}>
          <span>{t('filter.selectedEvents')}</span> <span className={css.subtitle}>({length})</span>
        </span>
        <div className={css.scrollList} id='playlist' onScroll={this.onScroll}>
          {this.getPlaylist()}
        </div>
      </div>
    )
  }
}
