import * as React from "react";

import { observer } from "mobx-react";
import recordingStore from "../../../stores/RecordingStore";
import { IRecording } from "../../../stores/Recording";

import isBefore from 'date-fns/is_before'

// import AddIcon from '@material-ui/icons/Add';

import ToggleButton from "../../../Components/ToggleButton/ToggleButton";
import ConfirmDelete from "../../../Components/ConfirmDelete/ConfirmDelete";
import LoadingSpinner from "../../../Components/LoadingSpinner/LoadingSpinner";
import Page from "../../../Components/Page/Page";
import PageHeader from "../../../Components/PageHeader/PageHeader";

import CreateRecording from "../CreateRecording/CreateRecording";
import RecordingMembersModal from "../RecordingMembersModal/RecordingMembersModal";
import RecordingCard from "../../../Components/RecordingCard/RecordingCard";
import GridSection, { IGridItem } from "../../../Components/GridSection/GridSection";
import PageContent from "../../../Components/PageContent/PageContent";

import { t } from "../../../localization/i18n";

import css from './RecordingList.module.scss'
import { ITeamPlayer } from "../../../Types/Types";
import teamState from "../../../stores/TeamStore";
import gamePlayerState from "../../../stores/GamePlayerStore";

interface IProps {
  listType?: string
  title?: string
}

interface IState {
  listPastOrUpcoming: number // 0: past, 1: upcoming
  editRecording: boolean
  editMembers: boolean
  activeRecording?: IRecording
  showConfirmDelete: boolean
  deleteRecordingId: string
}

const LIST_OPTIONS = ['Past', 'Upcoming']
const DEFAULT_LIST_TITLE = "Recordings"
const DEFAULT_LIST_TYPE = "practice"

export default observer(class RecordingList extends React.Component<IProps, IState> {
  constructor (props: IProps) {
    super(props)
    this.state = {
      listPastOrUpcoming: 0,
      editRecording: false,
      editMembers: false,
      activeRecording: {},
      showConfirmDelete: false,
      deleteRecordingId: "",
    }
  }

  componentDidMount () {
    recordingStore.subscribe()
  }

  componentWillUnmount () {
    recordingStore.unsubscribe()
  }

  toggleRecordingEdit = () => {
    this.setState({ editRecording: !this.state.editRecording })
  }

  handleEditRecordingSubmit = () => {
    this.setState({ editRecording: false, activeRecording: {} })
  }

  closeEditMembersModal = () => {
    this.setState({ editMembers: false, activeRecording: {} })
  }

  saveRecordingPlayers = (players: ITeamPlayer[]) => {
    const teamId = teamState.currentTeam ? teamState.currentTeam.id : ''
    if (this.state.activeRecording && this.state.activeRecording.id) {
      gamePlayerState.saveGamePlayers(this.state.activeRecording.id, teamId, players)
    }
  }

  handleEditMembersClicked = (recording: IRecording) => () => {
    this.setState({
      activeRecording: recording,
      editMembers: true,
    })
  }

  handleEditRecordingClicked = (recording: IRecording) => () => {
    this.setState({
      activeRecording: recording,
      editRecording: true,
    })
  }

  handleDeleteRecordingClicked = (id: string) => () => {
    this.setState({
      showConfirmDelete: true,
      deleteRecordingId: id,
    })
  }

  handleDeleteConfirmation = (action: string) => () => {
    if (this.state.deleteRecordingId && action === 'delete') {
      recordingStore.deleteRecording(this.state.deleteRecordingId)
      this.setState({ deleteRecordingId: "" })
    }
    this.setState({ showConfirmDelete: false })
  }

  renderItemCard = (recording: IRecording, type: string, upcoming: boolean) => {
    return (
      <RecordingCard
        key={recording.id}
        recording={recording}
        type={type}
        upcoming={upcoming}
        onClickMembers={this.handleEditMembersClicked(recording || {})}
        onClickDelete={this.handleDeleteRecordingClicked(recording.id || "")}
        onClickEdit={this.handleEditRecordingClicked(recording || {})}
      />
    )
  }

  renderSections () {
    const recordings: IRecording[] = []

    recordingStore.recordings.forEach(r => {
      const matchTime = r.startTime || new Date()
      const isPast = isBefore(matchTime, new Date())

      if ((r.type === this.props.listType) && (!!this.state.listPastOrUpcoming === !isPast)) {
        recordings.push(r)
      }
    })

    if (!recordings.length) {
      const message = "Nothing to show here"
      return (
        <div className={css.message}>{message}</div>
      )
    }


    if (!!this.state.listPastOrUpcoming) { //Upcoming - show all
      const items = recordings.map(r => {
        const recordingType = r.type ? r.type : DEFAULT_LIST_TYPE
        return ({
          id: r.id,
          component: this.renderItemCard(r, recordingType, true),
        } as IGridItem)
      })

      return <GridSection items={items} title={t('recording.sections.all')} />
    } else { // Past - show in sections
      const recent: IGridItem[] = []
      const all: IGridItem[] = []

      recordings.forEach((r, i) => {
        const recordingType = r.type ? r.type : DEFAULT_LIST_TYPE

        if (i < 4) {
          recent.push({
            id: r.id,
            component: this.renderItemCard(r, recordingType, true),
          } as IGridItem)
        } else {
          all.push({
            id: r.id,
            component: this.renderItemCard(r, recordingType, true),
          } as IGridItem)
        }
      })

      return (
        <>
          <GridSection items={recent} title={t('recording.sections.recent')} />
          <GridSection items={all} title={t(`recording.sections.${recent.length ? 'previous' : 'all'}`)} />
        </>
      )
    }
  }

  renderContent = () => {
    const loading = recordingStore.loading
    if (loading) {
      return (
        <LoadingSpinner />
      )
    }

    return this.renderSections()
  }

  handleChangePastOrUpcoming = (index: number) => this.setState({ listPastOrUpcoming: index === 0 ? 0 : 1 })

  render () {
    const listType = (this.props.listType) ? this.props.listType : DEFAULT_LIST_TYPE

    // const addRecordingButton = (listType === DEFAULT_LIST_TYPE)
    //   ? (
    //     <button className={css.blueButton} onClick={this.toggleRecordingEdit}>
    //       <AddIcon />
    //       Add
    //     </button>
    //   ) : null

    return (
      <Page>
        <PageHeader
          title={this.props.title ? this.props.title : DEFAULT_LIST_TITLE}
          actions={(
            <ToggleButton
              selected={this.state.listPastOrUpcoming}
              options={LIST_OPTIONS}
              onChange={this.handleChangePastOrUpcoming}
            />
          )}
        />
        <PageContent>
          {/* <div className={css.verticalBar}>
            {addRecordingButton}
          </div> */}
          <RecordingMembersModal
            isOpen={this.state.editMembers}
            recordingToEdit={this.state.activeRecording}
            closeWindow={this.closeEditMembersModal}
            onSubmit={this.saveRecordingPlayers}
          />
          <CreateRecording
            isOpen={this.state.editRecording}
            recordingType={listType}
            recordingToEdit={this.state.activeRecording}
            closeWindow={this.handleEditRecordingSubmit}
          />
          <ConfirmDelete
            isOpen={this.state.showConfirmDelete}
            onConfirm={this.handleDeleteConfirmation('delete')}
            onCancel={this.handleDeleteConfirmation('cancel')}
          />
          {this.renderContent()}
        </PageContent>
      </Page>
    );
  }
})
