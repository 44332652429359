import * as React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SettingsIcon from '@material-ui/icons/Settings';
import IconButtonCustom from '../IconButton/IconButton';
import { IconName } from '../../Types/Types';
import css from './OptionsMenu.module.scss';

interface IOption {
  key: string
  label: string
}

interface IProps {
  icon?: IconName
  options: IOption[]
  onSelect: (key: string) => void
}

interface IState {
  anchorEl: React.FormEvent<EventTarget> | null
}

const MENU_WIDTH = 170;

class OptionsMenu extends React.Component<IProps, IState> {
  state = {
    anchorEl: null,
  };

  handleClick = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleOptionClick = (key: string) => (
    () => {
      this.props.onSelect(key);
      this.handleClose()
    }
  );

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render () {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const { options, icon } = this.props

    const iconComponent = icon
      ? (
        <IconButtonCustom onClick={this.handleClick} icon={icon} small />
      ) : (
        <IconButton onClick={this.handleClick}>
          <SettingsIcon />
        </IconButton>
      )

    return (
      <div>
        {iconComponent}
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
          PaperProps={{
            style: {
              width: MENU_WIDTH,
            },
          }}
        >
          {options.map(option => (
            <MenuItem
              key={option.key}
              classes={{ root: css.menuOption }}
              onClick={this.handleOptionClick(option.key)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }
}

export default OptionsMenu;
