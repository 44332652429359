import * as React from 'react';
import { secondsToHHMMSS } from '../../../../Utils/CommonFunctions'
import css from './VideoTimeLabels.module.scss';

interface IProps {
  time: number
  duration: number
}

const VideoTimeLabels = (props: IProps) => {

  const time = props.time
  const duration = props.duration

  return (
    <div className={css.container}>
      <div className={css.timeBox}>
        <span className={css.timeElement}>{secondsToHHMMSS(time)}</span>
        <span> &nbsp;/&nbsp; </span>
        <span className={css.timeElement}>{secondsToHHMMSS(duration)}</span>
      </div>
    </div >
  )
}

export default VideoTimeLabels
