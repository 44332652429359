import * as React from 'react'
import RadioLabeled from './RadioLabeled/RadioLabeled'
import css from './RadioGroup.module.scss'
import cx from 'classnames'

interface IOption {
  key: string
  label: string
}

interface IProps {
  selected: string
  options: IOption[]
  onSelect: (selected: string) => void
}

class RadioGroup extends React.Component<IProps>{
  onSelect = (key: string) => () => {
    this.props.onSelect(key)
  }

  render () {
    const {
      selected,
      options,
    } = this.props

    const classes = cx(css.wrapper)
    const optionComponents = options.map(opt => (
      <RadioLabeled
        key={opt.key}
        selected={selected === opt.key}
        onSelect={this.onSelect(opt.key)}
        label={opt.label}
      />
    ))

    return (
      <div className={classes}>
        {optionComponents}
      </div>
    )
  }
}

export default RadioGroup
