import * as React from "react";
// import { RouteComponentProps } from "react-router";

import { observer } from "mobx-react";
import teamState from "../../../stores/TeamStore";
import { ITeamPlayer } from '../../../Types/Types'

import LoadingSpinner from "../../../Components/LoadingSpinner/LoadingSpinner";
import ConfirmDelete from '../../../Components/ConfirmDelete/ConfirmDelete';

import PlayerModal from '../PlayerModal/PlayerModal';

import { t } from "../../../localization/i18n";
import css from './Player.module.scss';
import { PDFNameToString } from "../../../Utils/CommonFunctions";
import IconButton from "../../../Components/IconButton/IconButton";
import Button from "../../../Components/Button/Button";
import ReportUploader from "../../../Components/ReportUploader/ReportUploader";
import appState from "../../../stores/AppStateStore";

const DEFAULT_IMG =
  'https://firebasestorage.googleapis.com/v0/b/liveinsight-de5ef.appspot.com/o/default-user.jpg?alt=media&token=9c271cb0-4f01-4b0f-960c-2fd2dce0538c'

interface IProps {
  id: string
  playerId: string
  onClose: () => void
}

interface IState {
  player?: ITeamPlayer
  isPlayerModalOpen: boolean
  isDeleteModalOpen: boolean
  refresh: boolean
}

export default observer(class Player extends React.Component<IProps, IState> {
  constructor (props: IProps) {
    super(props)
    this.state = {
      player: undefined,
      isPlayerModalOpen: false,
      isDeleteModalOpen: false,
      refresh: false,
    }
  }

  componentDidMount () {
    const { id, playerId } = this.props
    teamState.getTeamPlayer(id, playerId)
  }

  componentDidUpdate (prevProps: IProps) {
    const { id, playerId } = this.props
    if (prevProps.id !== id || prevProps.playerId !== playerId) {
      teamState.getTeamPlayer(id, playerId)
    }

  }

  componentWillUnmount () {
    teamState.detachPlayer()
  }

  openModal = () => {
    if (!this.state.isPlayerModalOpen) {
      this.setState({ isPlayerModalOpen: true })
    }
  }

  closeModal = () => {
    if (this.state.isPlayerModalOpen) {
      this.setState({ isPlayerModalOpen: false })
    }
  }

  updatePlayer = async (
    playerId: string,
    player: ITeamPlayer,
    photo: File | null,
    removePhoto: boolean
  ) => {
    const { id: teamId } = this.props
    const playerToEdit = player
    playerToEdit.id = playerId
    teamState.updatePlayer(teamId, playerToEdit, photo, removePhoto)
    this.closeModal()
  }

  removePlayer = () => {
    if (!this.state.isDeleteModalOpen) {
      this.setState({ isDeleteModalOpen: true })
    }
  }

  onConfirmDelete = () => {
    const { id: teamId, playerId } = this.props
    const { isDeleteModalOpen } = this.state

    if (isDeleteModalOpen) {
      teamState.removePlayer(teamId, playerId)
      this.props.onClose()

      this.setState({
        isDeleteModalOpen: false,
        isPlayerModalOpen: false,
      })
    }
  }

  onCancelDelete = () => {
    if (this.state.isDeleteModalOpen) {
      this.setState({ isDeleteModalOpen: false })
    }
  }

  handleSubmitReport = async (file: File) => {
    const player = teamState.currentPlayer
    const teamId = teamState.currentTeam ? teamState.currentTeam.id : ''
    if (!player || !teamId)
      return
    await teamState.savePlayerReport(file, player, teamId)
    this.setState({ refresh: !this.state.refresh })
  }

  renderReports = () => {
    const player = teamState.currentPlayer
    if (!player) return null

    const reports = player.reports || []
    const reportComponents = reports.map(r => {
      const urlBits = r.url.split('/')
      const reportName = unescape(urlBits[urlBits.length - 1].replace(/\?.*/g, ''))

      return (
        <div key={r.id} className={css.reportListItem}>
          <a
            href={r.url}
            className={css.reportLinkText}
            title={t('player.tooltips.report')}
            target='__blank'
          >
            {PDFNameToString(reportName)}
          </a>
        </div >
      )
    })

    const content = reportComponents.length
      ? reportComponents
      : (<div className={css.reportsEmpty}>{t('player.reportsEmpty')}</div>)

    return (
      <div className={css.reports}>
        <div className={css.title}>{t('player.reports')}</div>
        <div className={css.reportsList}>
          {content}
        </div>
      </div>
    )
  }

  renderImage = () => {
    const player = teamState.currentPlayer

    if (!player) return null

    return (
      <div className={css.imageWrapper}>
        <img className={css.image} src={player.photo || DEFAULT_IMG} />
      </div>
    )
  }
  renderMain = () => {
    const player = teamState.currentPlayer

    if (!player) return null

    const numComponent = player.num
      ? (
        `#${player.num}`
      ) : null

    return (
      <div className={css.main}>
        <div className={css.header}>
          <div className={css.title}>{t('player.details')}</div>
          <div className={css.actions}>
            <IconButton onClick={this.openModal} icon='edit' title={t('player.tooltips.edit')} />
          </div>
        </div>
        <div className={css.info}>
          <div className={css.infoLine}>
            {player.first_name} {player.last_name}
          </div>
          <div className={css.infoLine}>
            {player.position} {numComponent}
          </div>
        </div>
      </div>
    )
  }

  render () {
    const { isPlayerModalOpen, isDeleteModalOpen } = this.state
    const player = teamState.currentPlayer

    const loading = teamState.loading
    if (loading) {
      return (
        <LoadingSpinner />
      )
    }

    const { user } = appState
    const uploadReport = (user && user.type === 'admin')
      ? (
        <ReportUploader
          onSubmit={this.handleSubmitReport}
        />
      ) : null

    return (
      <div className={css.container}>
        <PlayerModal
          isOpen={isPlayerModalOpen}
          onClose={this.closeModal}
          playerToEdit={player}
          updatePlayer={this.updatePlayer}
        />
        <ConfirmDelete
          isOpen={isDeleteModalOpen}
          onConfirm={this.onConfirmDelete}
          onCancel={this.onCancelDelete}
        />

        <div className={css.wrapper}>
          <div className={css.content}>
            {this.renderImage()}
            {this.renderMain()}
            {this.renderReports()}
          </div>

          {uploadReport}

          <div className={css.footer}>
            <Button small remove onClick={this.removePlayer}>
              {t('player.removeFromTeam')}
            </Button>
          </div>
        </div>
      </div>
    )
  }
})
