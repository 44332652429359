import { Matrix } from './Vectorious/matrix'
import { Vector } from './Vectorious/vector'
type Vec2 = [number, number]

export function matrix_add (a: Matrix, b: Matrix | Vector) {
  const mb = b as Matrix
  const sum: any[] = []
  for (let ri = 0; ri < a.shape[0]; ri++) {
    const row_sum: any[] = []
    for (let ci = 0; ci < a.shape[1]; ci++) {
      row_sum.push(a.get(ri, ci) + mb.get(ri, 0))
    }
    sum.push(row_sum)
  }
  return new Matrix(sum)
}

//TODO: Use matrix_add instead
export function matrix_sub (a: Matrix, b: Matrix) {
  const sum: any[] = []
  for (let ri = 0; ri < a.shape[0]; ri++) {
    const row_sum: any[] = []
    for (let ci = 0; ci < a.shape[1]; ci++) {
      row_sum.push(a.get(ri, ci) - b.get(ri, 0))
    }
    sum.push(row_sum)
  }
  return new Matrix(sum)
}

export function homogeneous_to_cartesian (points: Matrix) {
  const cartesian_points: any[] = []
  for (let i = 0; i < points.shape[1]; i++) {
    const w = points.get(2, i)
    cartesian_points.push([points.get(0, i) / w, points.get(1, i) / w])
  }
  return cartesian_points
}

export function map_image_point (point: Vec2, h: Matrix) {
  const ip = Array.from(point)
  let image_point = new Matrix([[ip[0]], [ip[1]], [1]])
  image_point = Matrix.multiply(h, image_point)
  return homogeneous_to_cartesian(image_point)[0]
}

export function clamp (val: number, min: number, max: number) {
  return Math.max(Math.min(val, max), min)
}

/**
 * Get general rotation matrix
 * @param  {number} theta1 - horizontal rotation angle (Rz)
 * @param  {number} theta2 - vertical rotation angle (Rx)
 * @param  {number} theta3 - Ry rotation angle (Usually 0)
 */
export function get_cr (theta1: number, theta2: number, theta3: number) {
  //RZ(theta1)
  const c1 = new Matrix([
    [Math.cos(theta1), -Math.sin(theta1), 0],
    [Math.sin(theta1), Math.cos(theta1), 0],
    [0, 0, 1],
  ])

  //RX(theta2)
  const c2 = new Matrix([
    [1, 0, 0],
    [0, Math.cos(theta2), -Math.sin(theta2)],
    [0, Math.sin(theta2), Math.cos(theta2)],
  ])

  //RY(theta3)
  const c3 = new Matrix([
    [Math.cos(theta3), 0, Math.sin(theta3)],
    [0, 1, 0],
    [-Math.sin(theta3), 0, Math.cos(theta3)],
  ])

  return Matrix.multiply(Matrix.multiply(c1, c3), c2)
}
