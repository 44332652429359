import * as React from 'react'

import Checkbox from '@material-ui/core/Checkbox'

import { ITeamPlayer } from '../../../../Types/Types'

import css from './MemberListItem.module.scss'

interface IProps {
  member: ITeamPlayer
  checked: boolean
  onSelect: (checked: boolean) => void
}

class MemberListItem extends React.PureComponent<IProps> {
  handleClickWrapper = () => {
    const {
      onSelect,
      checked,
    } = this.props

    onSelect(!checked)
  }

  handleChange = (evt: any) => {
    const {
      onSelect,
    } = this.props

    onSelect(evt.target.checked)
  }

  render () {
    const {
      member,
      checked,
    } = this.props
    const { id, num, first_name, last_name } = member

    return (
      <div className={css.wrapper} onClick={this.handleClickWrapper}>
        <Checkbox
          checked={checked}
          onChange={this.handleChange}
          value={id}
        />
        <div className={css.num}>[ {num || ''} ]</div>
        <div className={css.title}>
          {first_name} {last_name}
        </div>
      </div>
    )
  }

}

export default MemberListItem
