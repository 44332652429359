import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { RouteComponentProps } from "react-router";

import Main from "./Main/Main";

import css from './Reports.module.scss';


class Reports extends React.Component<RouteComponentProps> {
  render () {
    const { match } = this.props

    return (
      <div className={css.dashboardContainer}>
        <div className={css.contentContainer}>
          <Switch>
            <Route path={match.path} component={Main} exact />
          </Switch>
        </div>
      </div>
    )
  }
}

export default Reports;
