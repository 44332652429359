import * as React from 'react';

import CloseIcon from '@material-ui/icons/Close';
import Video3d from "../../Video3d/Video3d";
import { secondsToHHMMSS } from "../../../Utils/CommonFunctions";
import { getEventTitle } from '../../../Components/helpers';
import { t } from '../../../localization/i18n'
import css from './EventModal.module.scss'
import { IPassEvent, IVideoInfo, TracksInfo } from '../../../Types/Types';

interface IProps {
  isOpen: boolean
  activePass?: IPassEvent
  video?: IVideoInfo | null // undefined = loading, null = not found
  closeModal: () => void
  tracks?: TracksInfo | null // undefined = loading, null = not found
}

const stopPropagation = (evt: React.SyntheticEvent) => {
  evt.nativeEvent.stopImmediatePropagation()
}

const EventModal = ({
  isOpen,
  activePass,
  video,
  closeModal,
  tracks,
}: IProps) => {
  if (!isOpen || !activePass)
    return null

  const distance = activePass.distance.toFixed(2);
  const duration = activePass.duration.toFixed(2);
  const speed = (activePass.speed * 3.6).toFixed(2);
  const time = (activePass.start_match_time / 1000);

  const fromPosition = activePass.from_position
  const toPosition = activePass.to_position

  const prePadding = 1.0
  const postPadding = 1.0

  const videoStart = Math.max(0, activePass.start_stream_time[0] / 1000 - prePadding)
  const videoEnd = videoStart + activePass.duration + prePadding + postPadding

  const videoComponent = video
    ? (
      <div className={css.infoBoxColumn} style={{ paddingRight: '25px' }}>
        <Video3d
          video={video}
          start={videoStart}
          end={videoEnd}
          tracks={tracks}
          isLooping
          basicControls
          focusPoints={[fromPosition, toPosition]}
          currentEventIndex={0}
          eventCount={1}
        />
      </div>
    )
    : null

  return (
    <div className={css.infoBoxContainer} onClick={stopPropagation}>
      <div className={css.closeInfoBoxButton} onClick={closeModal}>
        <CloseIcon />
      </div>
      {videoComponent}
      <div className={css.infoBoxColumn}>
        <div className={css.infoBoxRow}>
          {getEventTitle(activePass)}
        </div>
        <div className={css.infoBoxRow}>
          <span>{t('recording.modal.time')}</span>
          <span>{secondsToHHMMSS(time)}</span>
        </div>
        <div className={css.infoBoxRow}>
          <span>{t('recording.modal.duration')}</span>
          <span>{duration} {t('common.units.s')}</span>
        </div>
        <div className={css.infoBoxRow}>
          <span>{t('recording.modal.distance')}</span>
          <span>{distance} {t('common.units.m')}</span>
        </div>
        <div className={css.infoBoxRow}>
          <span>{t('recording.modal.speed')}</span>
          <span>{speed} {t('common.units.km_per_hour')}</span>
        </div>
      </div>
    </div>
  )

}

export default EventModal
