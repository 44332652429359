import * as React from 'react'
import { Route, Switch } from 'react-router-dom'
import { RouteComponentProps } from "react-router";

import { t } from '../../localization/i18n'

import RecordingList from './RecordingList/RecordingList'
import GameView from './GameView/GameView'

class Recordings extends React.Component<RouteComponentProps> {
  goTo = (pathname: string) => () => {
    const {
      history,
      match,
    } = this.props

    history.push(`${match.path}${pathname}`)
  }

  renderGamesView = (props: any) => (
    <GameView
      clickedBack={this.goTo('games')}
      {...props}
    />
  )

  renderPracticesView = (props: any) => (
    <GameView
      clickedBack={this.goTo('practices')}
      {...props}
    />
  )

  renderGamesList = (props: any) => (
    <RecordingList
      listType="fixture"
      title={t('sections.games')}
      {...props}
    />
  )
  renderPracticesList = (props: any) => (
    <RecordingList
      listType="practice"
      title={t('sections.practices')}
      {...props}
    />
  )

  render () {
    const {
      match,
    } = this.props

    return (
      <Switch>
        <Route
          path={`${match.path}games/:id`}
          render={this.renderGamesView}
        />
        <Route
          path={`${match.path}practices/:id`}
          render={this.renderPracticesView}
        />
        <Route
          exact
          path={`${match.path}games`}
          render={this.renderGamesList}
        />
        <Route
          exact
          path={`${match.path}practices`}
          render={this.renderPracticesList}
        />
      </Switch>
    )
  }
}

export default Recordings
