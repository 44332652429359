import * as React from "react";
import {
  withRouter,
  RouteComponentProps,
  NavLink,
  Link
} from "react-router-dom";

import { Authentication } from "../../stores/Authentication"
import appState from "../../stores/AppStateStore"

import BetaTag from "../BetaTag/BetaTag"
import { t } from '../../localization/i18n'
import classNames from "classnames"
import css from './LeftMenu.module.scss'

interface IState {
  activeSection: string
  showmenu: boolean,
}

const NAVIGATION_CONFIG = [
  { key: 'games', pathname: 'games' },
  // {key: 'practices', pathname: 'recordings/practices'},
  { key: 'team', pathname: 'team' },
  // { key: 'reports', pathname: 'reports' },
]

class LeftMenu extends React.PureComponent<RouteComponentProps<{}>, IState> {
  state: IState = {
    activeSection: "games",
    showmenu: false,
  };

  constructor (props: RouteComponentProps<{}>) {
    super(props);
    NAVIGATION_CONFIG.forEach((section) => {
      if (props.location.pathname.includes(section.pathname)) {
        this.state.activeSection = section.key;
      }
    })
  }

  componentWillUpdate (nextProps: RouteComponentProps<{}>, nextState: IState) {
    if (nextProps.location.pathname !== this.props.location.pathname)
      NAVIGATION_CONFIG.forEach((section) => {
        if (nextProps.location.pathname.includes(section.pathname)) {
          nextState.activeSection = section.key;
        }
      })
  }

  logOut = () => {
    Authentication.logOut().catch(err => console.error(err));
  };

  toggleDropdown = () => {
    this.setState({ showmenu: !this.state.showmenu });
  };

  hideDropdown = () => {
    this.setState({ showmenu: false });
  };

  goToGames = () => {
    this.props.history.push('/games')
  }

  renderLink = ({ key, pathname }: { key: string, pathname: string }) => {

    return (
      <NavLink
        key={key}
        className={classNames(css.menuLink, { [css.activeLink]: this.state.activeSection === key })}
        to={"/" + pathname}
      >
        {t('sections.' + key)}
      </NavLink>
    )
  }

  renderUserInformation () {

    const name = appState.user ? appState.user.name : ''
    const isAdmin = appState.user ? appState.user.type === 'admin' : false
    const avatarUrl = null//appState.user ? appState.user.avatarUrl : null

    const avatarComponent = avatarUrl
      ? (
        <img
          className={css.userImage}
          src={avatarUrl}
          alt="user"
        />
      ) : (
        <div className={css.userImage}>
          {name.charAt(0).toUpperCase()}
        </div>
      )

    return (
      <div className={css.userContainer}>
        <div
          className={css.userInfo}
          onClick={this.toggleDropdown}
        >
          <div
            className={css.username}
          >
            <span>{name}</span>
          </div>
          {avatarComponent}
        </div>

        {this.state.showmenu ? (
          <div className={css.dropdownMenu} onMouseLeave={this.hideDropdown}>
            <a onClick={this.logOut}>{t('usermenu.log_out')}</a>
            {isAdmin
              ? <Link to={`/admin`}>{t('sections.admin')}</Link>
              : null}

          </div>
        ) : null}
      </div>
    )
  }

  render () {
    const linkComponents = NAVIGATION_CONFIG.map((section) => (
      this.renderLink(section)
    ))

    return (
      <div className={css.menuComponent}>
        <div className={css.logo}>
          <img
            width='100%'
            onClick={this.goToGames}
            src={require("../../assets/images/logo.png")}
            alt={"logo"}
          />
          <BetaTag
            top={'53px'}
            left={'157px'}
          />
        </div>

        {linkComponents}

        {this.renderUserInformation()}

      </div>
    );
  }
}

export default withRouter(LeftMenu);
