import React from 'react'
import css from './StatsListItem.module.scss'
import Label from '../../../Label/Label';

export interface IStatsListItem {
  id: string
  title: string
  value: string
  success?: boolean
  fail?: boolean
}

export const StatsListItem = ({
  title,
  value,
  success,
  fail,
}: IStatsListItem) => {
  const valueComponent = (success || fail)
    ? (
      <Label
        success={success}
        fail={fail}
      >
        {value}
      </Label>

    )
    : (
      <div className={css.value}>
        {value}
      </div>

    )

  return (
    <div className={css.wrapper}>
      <div className={css.title}>
        {title}
      </div>
      {valueComponent}
    </div>
  )
}

export default StatsListItem
