import { TeamSelection, IPassEvent } from '../Types/Types'
import { t } from "../localization/i18n";
import gameDetailsState from '../stores/GameDetails';

export const getEventTitle = (event: IPassEvent) => {

  if (!gameDetailsState.currentGame)
    return ''

  const fromTeam = event.from_team === TeamSelection.Home ? gameDetailsState.currentGame.home_team : gameDetailsState.currentGame.away_team
  const toTeam = event.to_team === TeamSelection.Home ? gameDetailsState.currentGame.home_team : gameDetailsState.currentGame.away_team
  const fromTeamName = fromTeam ? fromTeam.name : ''
  const toTeamName = toTeam ? toTeam.name : ''

  if (event.live_insight) {
    const {
      from_team,
      to_team,
      from_jersey_number,
      to_jersey_number,
    } = event.live_insight

    if (typeof (from_jersey_number) === 'undefined' || typeof (to_jersey_number) === 'undefined') {
      return fromTeamName
    }

    if (from_team && to_team && from_team !== to_team) {
      return `${t('event.interceptionBy')} #${to_jersey_number} (${toTeamName}). ${t('event.passFrom')} #${from_jersey_number} (${fromTeamName})`
    }

    return `${t('event.passFrom')} #${from_jersey_number} ${t('common.to')} #${to_jersey_number} (${fromTeamName})`
  }

  return fromTeamName
}
