import { observable, decorate } from "mobx";

export interface IUser {
  name: string
  teams: string[]
  type: string
}


class AppStateStore {
  isLoggedIn: boolean = false
  user?: IUser
}
decorate(AppStateStore, {
  isLoggedIn: observable,
})

const appState = new AppStateStore()
export default appState

