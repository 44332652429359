import * as React from 'react'
import css from './BetaTag.module.scss'

interface IProps {
  top?: string
  left?: string
  right?: string
  bottom?: string
  rotateDegrees?: string
}

class BetaTag extends React.Component<IProps>{

  render () {

    const cssTop = this.props.top || 'auto'
    const cssLeft = this.props.left || 'auto'
    const cssRight = this.props.right || 'auto'
    const cssBottom = this.props.bottom || 'auto'
    const degrees = this.props.rotateDegrees || '0'
    const cssRotate = 'rotate(' + degrees + 'deg)'

    return (
      <div
        className={css.betaTag}
        style={{
          top: cssTop,
          left: cssLeft,
          right: cssRight,
          bottom: cssBottom,
          transform: cssRotate,
        }}
      />
    )
  }
}

export default BetaTag
