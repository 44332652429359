import Hls from 'hls.js'

class Video {
  canPlay: boolean = false

  constructor (public element: HTMLVideoElement) {
    this.element = element
  }
}

export default class VideoPlayer {
  video?: Video
  isPlaying: boolean = true

  private addNativeVideo (element: HTMLVideoElement, url: string) {
    if (!element.canPlayType('application/vnd.apple.mpegurl'))
      throw new Error('Can not play HLS video either natively or with hls.js')
    element.src = url;
  }

  private addHlsJsVideo (element: HTMLVideoElement, url: string) {
    const hls = new Hls();
    hls.loadSource(url);
    hls.attachMedia(element);
  }

  addVideo (element: HTMLVideoElement, url: string) {
    if (Hls.isSupported())
      this.addHlsJsVideo(element, url)
    else
      this.addNativeVideo(element, url)

    const video = new Video(element)
    this.addEventListeners(video)

    this.video = video
  }

  private addEventListeners (video: Video) {
    video.element.addEventListener('canplay', () => {
      video.canPlay = true
      if (this.video && this.isPlaying && this.video.canPlay) {
        this.video.element.play()
      }
    })
    video.element.addEventListener('waiting', () => {
      video.canPlay = false
      if (this.video && this.isPlaying)
        this.video.element.pause()

    })
    video.element.addEventListener('pause', () => {
      if (this.video && this.isPlaying)
        this.video.element.pause()

    })
  }

  play () {
    this.isPlaying = true
    if (this.video && this.video.canPlay)
      this.video.element.play()
  }

  pause () {
    this.isPlaying = false
    if (this.video)
      this.video.element.pause()
  }

  seek (time: number) {
    if (this.video)
      this.video.element.currentTime = Math.max(time, 0)
  }
}
