import React from 'react'
import cx from 'classnames'
import css from './Page.module.scss'

interface IProps {
  children: any
  fillHeight?: boolean
}

const Page = ({ fillHeight, children }: IProps) => (
  <div className={cx(css.page, { [css.fillHeight]: fillHeight })}>
    {children}
  </div>
)

export default Page
