import React from 'react'
import cx from 'classnames'
import css from './ListItem.module.scss'

export interface IProps {
  even?: boolean
  children: React.ReactChild
}

export const ListItem = ({
  children,
  even,
}: IProps) => {
  const classes = cx(
    css.wrapper,
    { [css.even]: even }
  )
  return (
    <div className={classes}>
      {children}
    </div>
  )
}

export default ListItem
