import { observable, decorate } from 'mobx'
import { context } from './FirebaseConnection'
import appState from './AppStateStore'
import compareDesc from 'date-fns/compare_desc';

import { ITeam, ITeamPlayer, IPlayerReport } from '../Types/Types'

class TeamStore {
  currentTeam: ITeam | null
  currentPlayer: ITeamPlayer | null
  loading: boolean
  private pDetachTeam: (() => void) | null
  private pDetachPlayer: (() => void) | null

  constructor () {
    this.currentTeam = null
    this.currentPlayer = null
    this.pDetachTeam = null
    this.pDetachPlayer = null
    this.loading = false
  }

  async getTeamById (id: string) {
    const userObject = appState.user
    if (!userObject)
      return
    const userTeams = userObject.teams

    if (!(userTeams && userTeams.length)) {
      return
    }

    this.loading = true

    const db = context.firestore
    const teamRef = db.doc(`${context.firestoreBase}/teams/${id}`)

    try {
      const doc = await teamRef.get()

      const data = doc.data() || {}
      data.id = doc.id

      if (this.pDetachTeam) this.pDetachTeam()

      this.pDetachTeam = teamRef
        .collection('players')
        .onSnapshot(pSnapshot => {
          this.loading = false
          const players = pSnapshot.docs
            .map(pDoc => {
              const pData = pDoc.data()
              pData.id = pDoc.id
              return pData as ITeamPlayer
            })
            .sort((a: ITeamPlayer, b: ITeamPlayer) => {
              return (a.last_name > b.last_name) ? 1 : -1
            })

          data.players = players
          this.currentTeam = data as ITeam
        })
    } catch (err) {
      this.loading = false
      console.error('Document not found', err)
    }
  }

  async getTeamPlayer (teamId: string, playerId: string) {
    const db = context.firestore
    const playerRef = db.doc(`${context.firestoreBase}/teams/${teamId}/players/${playerId}`)

    this.pDetachPlayer = playerRef.onSnapshot(async (doc) => {
      const data = doc.data()

      let reports: IPlayerReport[] = []
      try {
        const reportSnapshots = await playerRef.collection('/reports').get()
        reports = reportSnapshots.docs
          .map((rDoc) => {
            const rData = rDoc.data()
            rData.id = rDoc.id
            return rData as IPlayerReport
          })
          .sort((a: IPlayerReport, b: IPlayerReport) => {
            if (a.date && b.date) {
              const datesCompared = compareDesc(a.date, b.date)
              if (datesCompared === 0 && a.phase && b.phase)
                return (a.phase < b.phase) ? 1 : -1
              return datesCompared
            }
            return 0
          })
      } catch (err) {
        console.error('Unable to obtain reports', err)
      }

      if (data) {
        data.id = doc.id
        data.reports = reports
        this.currentPlayer = data as ITeamPlayer
      }
    })
  }

  async addNewPlayer (teamId: string, player: ITeamPlayer, photo: File | null) {
    const userObject = appState.user
    if (!userObject)
      return
    const userTeams = userObject.teams

    if (!(userTeams && userTeams.length) || !teamId) {
      return
    }

    const db = context.firestore
    const storage = context.storage

    const playerEntity = {
      first_name: player.first_name,
      last_name: player.last_name,
    }

    try {
      const docRef = await db
        .collection(`${context.firestoreBase}/players`)
        .add(playerEntity)

      let photoUrl: string | undefined

      if (photo) {
        const photoPath = `images/${docRef.id}/${photo.name}`
        const storageRef = storage.ref().child(photoPath)

        await storageRef.put(photo)
        photoUrl = await storageRef.getDownloadURL()
      }

      const teamPlayer = player
      if (photoUrl) {
        teamPlayer.photo = photoUrl
      } else {
        delete teamPlayer.photo
      }

      db.doc(`${context.firestoreBase}/teams/${teamId}`)
        .collection('players')
        .doc(docRef.id)
        .set(teamPlayer)
    } catch (err) {
      console.error('Error adding player: ', err)
    }
  }

  async updatePlayer (
    teamId: string,
    player: ITeamPlayer,
    photo: File | null,
    removePhoto: boolean
  ) {
    const userObject = appState.user
    if (!userObject)
      return
    const userTeams = userObject.teams

    if (!(userTeams && userTeams.length) || !teamId) {
      return
    }

    const db = context.firestore
    const storage = context.storage

    const playerEntity = {
      first_name: player.first_name,
      last_name: player.last_name,
    }

    try {
      await db
        .collection(`${context.firestoreBase}/players`)
        .doc(player.id)
        .update(playerEntity)

      let photoUrl: string | undefined

      const teamPlayer = player
      if (removePhoto) {
        teamPlayer.photo = ''
      } else {
        if (photo) {
          const photoPath = `images/${player.id}/${photo.name}`
          const storageRef = storage.ref().child(photoPath)

          await storageRef.put(photo)
          photoUrl = await storageRef.getDownloadURL()
        }

        if (photoUrl) {
          teamPlayer.photo = photoUrl
        } else {
          delete teamPlayer.photo
        }
      }

      db.doc(`${context.firestoreBase}/teams/${teamId}`)
        .collection('players')
        .doc(player.id)
        .update(teamPlayer)
    } catch (err) {
      console.error('Error updating player: ', err)
    }
  }

  async removePlayer (teamId: string, playerId: string) {
    const userObject = appState.user
    if (!userObject)
      return
    const userTeams = userObject.teams

    if (!(userTeams && userTeams.length) || !teamId) {
      return
    }

    const db = context.firestore

    try {
      await db
        .doc(`${context.firestoreBase}/teams/${teamId}`)
        .collection('players')
        .doc(playerId)
        .delete()
      console.error('Player removed: ', teamId, playerId)
    } catch (err) {
      console.error('Error updating player: ', err)
    }
  }

  async savePlayerReports (
    reports: File[],
    player: ITeamPlayer,
    teamId: string
  ) {
    reports.forEach(r => {
      this.savePlayerReport(r, player, teamId)
    })
  }

  async savePlayerReport (
    report: File | null,
    player: ITeamPlayer,
    teamId: string
  ) {
    const userObject = appState.user
    if (!userObject)
      return
    const userTeams = userObject.teams

    if (!(userTeams && userTeams.length) || !teamId) {
      return
    }

    const db = context.firestore
    const storage = context.storage

    if (report) {
      const reportPath = `reports/${teamId}/${player.id}/${report.name}`
      const storageRef = storage.ref().child(reportPath)

      await storageRef.put(report)
      const reportUrl = await storageRef.getDownloadURL()

      const data = {
        url: reportUrl,
      } as IPlayerReport

      await db
        .collection(`${context.firestoreBase}/teams/${teamId}/players/${player.id}/reports`)
        .add(data)
        .then(ref => {
          data.id = ref.id
          if (this.currentPlayer && this.currentPlayer.reports)
            this.currentPlayer.reports.push(data)
        })
    }
  }

  detachTeam () {
    if (this.pDetachTeam) {
      this.pDetachTeam()
      this.pDetachTeam = null
      this.currentTeam = null
    }
  }

  detachPlayer () {
    if (this.pDetachPlayer) {
      this.pDetachPlayer()
      this.pDetachPlayer = null
      this.currentPlayer = null
    }
  }

  detach () {
    this.detachTeam()
    this.detachPlayer()
    this.loading = false
  }
}

decorate(TeamStore, {
  currentTeam: observable,
  currentPlayer: observable,
  loading: observable,
})

const teamState = new TeamStore()
export default teamState
