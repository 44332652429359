import * as React from "react";
import css from './ToggleButton.module.scss';
import classNames from 'classnames'

export interface IToggleButtonProps {
  selected: number
  sub?: boolean
  options: string[]
  onChange: (index: number) => void
  style?: any
}

export const ToggleButton = (props: IToggleButtonProps) => {
  return (
    <div className={classNames(css.container, { [css.sub]: props.sub })} style={props.style}>
      {props.options.map((option, index) => {
        const active = props.selected === index
        return (
          <div
            className={classNames(css.button, { [css.active]: active })}
            key={index}
            onClick={active ? undefined : () => props.onChange(index)}
          >
            {option}
          </div>
        )
      })}
    </div>
  )
}

export default ToggleButton
