import * as React from "react";
import "react-input-range/lib/css/index.css";
import FootballField from "../../../../Components/FootballField/FootballField";
import { PhaseNumber, EventTypeSelection, PossessionTypeSelection, IGameEvent } from "../../../../Types/Types";

interface IProps {
  phase: PhaseNumber
  eventType: EventTypeSelection
  possessionType: PossessionTypeSelection
  events: IGameEvent[]
  currentEventIndex: number
}

export default class MapView extends React.PureComponent<IProps> {

  render () {
    return (
      <FootballField
        length={105}
        width={68}
        phase={this.props.phase}
        filterEventType={this.props.eventType}
        filterPossessionType={this.props.possessionType}
        events={this.props.events}
        currentEventIndex={this.props.currentEventIndex}
      />
    );
  }
}
