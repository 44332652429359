import * as React from 'react'

import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'

import MemberListItem from '../MemberListItem/MemberListItem'
import { ITeamPlayer } from '../../../../Types/Types'

import css from './MemberList.module.scss'

interface IProps {
  players: ITeamPlayer[]
  onSelect: (player: ITeamPlayer, checked: boolean) => void
}

interface IState {
  searchQuery: string
}

class MemberList extends React.Component<IProps, IState> {
  constructor (props: IProps) {
    super(props)
    this.state = {
      searchQuery: '',
    }
  }

  onChangeSearch = (event: React.SyntheticEvent) => {
    const target = event.target as HTMLInputElement
    const searchQuery = target.value

    this.setState({ searchQuery })
  }

  filterPlayers = (player: ITeamPlayer) => {
    const searchQuery = this.state.searchQuery.toLowerCase()
    const first_name = player.first_name.toLowerCase()
    const last_name = player.last_name.toLowerCase()

    const num = (player.num || '').toString().toLowerCase()
    const name = first_name + ' ' + last_name

    return (
      !searchQuery.length ||
      name.indexOf(searchQuery) !== -1 ||
      num.indexOf(searchQuery) !== -1
    )
  }

  renderSearch = () => {
    const { searchQuery } = this.state

    return (
      <div className={css.searchWrapper}>
        <TextField
          value={searchQuery}
          onChange={this.onChangeSearch}
          type="search"
          placeholder="Filter players"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>
    )
  }

  handleSelect = (player: ITeamPlayer) => (checked: boolean) => {
    this.props.onSelect(player, checked)
  }

  renderList = () => {
    const { players } = this.props

    const playerComponents = players
      .filter(this.filterPlayers)
      .map((p: ITeamPlayer) => {
        const id = p.id || ''
        const selected = p.selected || false
        return (
          <MemberListItem
            key={id}
            member={p}
            checked={selected}
            onSelect={this.handleSelect(p)}
          />
        )
      })

    return <div className={css.membersList}>{playerComponents}</div>
  }

  render () {
    return (
      <div className={css.wrapper}>
        {this.renderSearch()}
        {this.renderList()}
      </div>
    )
  }
}

export default MemberList
