import * as React from "react";
import { RouteComponentProps } from "react-router";

import { observer } from "mobx-react";
import appState from "../../../stores/AppStateStore";
import teamListState from "../../../stores/TeamListStore";

import ListItemCard from "../../../Components/ListItemCard/ListItemCard";

import PageHeader from "../../../Components/PageHeader/PageHeader";
import Page from "../../../Components/Page/Page";
import PageContent from "../../../Components/PageContent/PageContent";

interface IProps {
  id: string
}

export default observer(class TeamList extends React.Component<RouteComponentProps<IProps>> {
  componentDidMount () {
    const { user } = appState
    if (user && user.teams && user.teams.length === 1) {
      this.props.history.push(`/team/${user.teams[0]}`)
    } else {
      teamListState.getTeamListFromFirebase()
    }
  }

  render () {
    const teamList = teamListState.teams

    const teamListElements = teamList.map((team, index) => {
      return (
        <ListItemCard
          key={index}
          to={`/team/${team.id}`}
          title={team.name}
        />
      );
    })


    return (
      <Page>
        <PageHeader
          title='Teams'
        />

        <PageContent>
          {teamListElements}
        </PageContent>
      </Page>
    );
  }
})
