import React from 'react'

import { IRecording } from '../../stores/Recording';

import { Link } from 'react-router-dom';
import format from 'date-fns/format'

import { getRecordingTitle } from './helpers/helpers';

import { t } from '../../localization/i18n';
import OptionsMenu from '../OptionsMenu/OptionsMenu';

import cx from 'classnames'
import css from './RecordingCard.module.scss'
import Icon from '../Icon/Icon';

interface IProps {
  recording: IRecording
  type: string
  upcoming: boolean
  onClickMembers?: () => void
  onClickDelete?: () => void
  onClickEdit?: () => void
}

const OPTION_MEMBERS = 'MEMBERS'
const OPTION_JSON = 'JSON'
const OPTION_REPORT = 'REPORT'

export class RecordingCard extends React.Component<IProps> {
  onSelectMenuItem = (key: string) => {
    switch (key) {
      case OPTION_MEMBERS:
        if (this.props.onClickMembers) {
          this.props.onClickMembers()
        }
        break;
      case OPTION_REPORT:
        this.openReport(this.props.recording.report_url)
        break;
      case OPTION_JSON:
        this.openReport(this.props.recording.json_url)
        break;
      default:
        break;
    }
  }

  openReport = (url?: string) => {
    if (url) {
      window.open(url, '__blank')
    }
  }

  render () {
    const { recording } = this.props
    const dateComponent = recording.startTime
      ? (
        <div className={css.time}>
          {format(recording.startTime, 'MMM D, YYYY')}
        </div>
      ) : null

    const options = [
      { key: OPTION_MEMBERS, label: t('recording.tooltips.members') },
    ]

    if (recording.json_url) {
      options.push(
        { key: OPTION_JSON, label: t('recording.json') }
      )
    }

    const report = recording.report_url
      ? (
        <a
          className={css.report}
          href={recording.report_url}
          target='__blank'
          title={t('recording.tooltips.report')}
        >
          <Icon icon='download' />
        </a>
      ) : null

    const optionsMenu = (
      <OptionsMenu
        options={options}
        onSelect={this.onSelectMenuItem}
        icon='more'
      />
    )

    const to = `/games/${recording.id}`
    // const isRunning = recording.state === 'running'
    const isDisabled = recording.state && (['finished', 'running'].indexOf(recording.state) === -1)

    const classes = cx(
      css.wrapper,
      {
        // [css.loading]: isRunning,
        [css.disabled]: isDisabled,
      }
    )

    return (
      <div className={classes}>
        <Link className={css.card} to={to}>
          <div className={css.title}>
            {getRecordingTitle(recording)}
          </div>
        </Link>
        <div className={css.footer}>
          {dateComponent}
          <div className={css.actions}>
            {report}
            {optionsMenu}
          </div>
        </div>
      </div>
    )
  }
}

export default RecordingCard
