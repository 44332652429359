import format from 'date-fns/format'
import { t } from '../localization/i18n'

export function secondsToHHMMSS (totalSeconds: number, withMs: boolean = false) {
  if (isNaN(totalSeconds)) {
    return '00:00:00'
  }

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds - (hours * 3600)) / 60);
  let seconds = totalSeconds - (hours * 3600) - (minutes * 60);
  let ms = seconds - Math.floor(seconds)
  seconds = seconds - ms
  ms = Math.floor(ms * 1000)

  if (withMs) {
    return `${padWithZeroes(hours, 2)}:${padWithZeroes(minutes, 2)}:${padWithZeroes(seconds, 2)}.${ms}`
  }

  return `${padWithZeroes(hours, 2)}:${padWithZeroes(minutes, 2)}:${padWithZeroes(seconds, 2)}`
}

export function padWithZeroes (input: number, length: number) {
  let str = '' + input;
  while (str.length < length) {
    str = '0' + str;
  }

  return str;
}

export function PDFNameToString (input: string) {
  /** Input style:
   * <somedir>/<date>.<hometeam-name>-<awayteam-name>.<half>.<player-team>.<player-jersey-number>.<player-name>-<player-name>
   * where date = YYYYMMDD
   */
  const INPUT_LENGTH = 7
  const DATE_EXPRESSION = /^\d{8}$/
  const HALFS_EXPRESSION = /^1$|^2$/

  if (!input)
    return t('pdf_report')

  if (!input.includes('.')) {
    return input
  }

  let inputString = input

  if (input.includes('/')) {
    const a = input.split('/')
    inputString = a[a.length - 1]
  }

  const inputArray = inputString.split('.')

  if (inputArray.length < INPUT_LENGTH)
    return input

  if (!inputArray[0].match(DATE_EXPRESSION))
    return input
  const date = inputArray[0]

  const teamsArray = inputArray[1].split('-')
  if (teamsArray.length !== 2) {
    return input
  }
  const homeTeam = teamsArray[0]
  const awayTeam = teamsArray[1]

  if (!inputArray[2].match(HALFS_EXPRESSION))
    return input
  const half = inputArray[2]

  const dateFormatted = format(date, 'D MMM YYYY')
  const halfFormatted = t('half.' + half)

  return dateFormatted + ', ' + homeTeam + ' - ' + awayTeam + ', ' + halfFormatted
}

export function arraysEqual (a: any[], b: any[]) {
  if (!(a && b))
    return false;

  if (a.length !== b.length)
    return false;

  const l = a.length
  for (let i = 0; i < l; i++) {
    if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
}

export function roundValueToString (value: number, decimals: number) {
  return value.toFixed(decimals).toString()
}
