import themeGlobal from '../../Themes/global'

const color = themeGlobal.colors.colorGrayTransparent;
const colorGray = themeGlobal.colors.colorGray;
const padding = 2.5;
const strokeWidth = 0.1;
const strokeDash = "0.8 0.8";

const goalWidth = 7.32;
const goalAreaLength = 5.5;
const goalAreaWidth = goalWidth + goalAreaLength * 2;

const penaltyAreaLength = 16.5;
const penaltyAreaWidth = goalWidth + penaltyAreaLength * 2;

export default {
  color,
  colorGray,
  strokeWidth,
  strokeDash,
  padding,
  goal: {
    length: goalAreaLength,
    width: goalAreaWidth,
  },
  penalty: {
    length: penaltyAreaLength,
    width: penaltyAreaWidth,
  },
}
