import * as React from 'react'

import ModalSimple from '../ModalSimple/ModalSimple'
import { t } from '../../localization/i18n';

interface IProps {
  isOpen: boolean
  onConfirm: () => void
  onCancel: () => void
}

const ConfirmDelete = ({
  isOpen,
  onConfirm,
  onCancel,
}: IProps) => (
    <ModalSimple
      isOpen={isOpen}
      onClose={onCancel}
      title={t('common.confirmDelete')}
      styles={{ width: 350 }}
      footerButtons={[
        { title: t('common.cancel'), handler: onCancel, secondary: true },
        { title: t('common.delete'), handler: onConfirm },
      ]}
    />
  )

export default ConfirmDelete
