import React from 'react'
import css from './ValueCard.module.scss'
import Widget from '../Widget/Widget';

interface IProps {
  title: string
  subtitle: string
  value: string
}

export const ValueCard = ({
  title,
  subtitle,
  value,
}: IProps) => {

  return (
    <Widget title={title}>
      <div className={css.container}>
        <span className={css.subtitle}>{subtitle}</span>
        <span className={css.value}>{value}</span>
      </div>
    </Widget>
  )
}

export default ValueCard
