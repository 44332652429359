import React from 'react'
import PlayersListItem, { IStatsListItem } from './StatsListItem/StatsListItem';
import List from '../List/List'

interface IProps {
  title: string
  items: IStatsListItem[]
}

export const StatsList = ({
  title,
  items,
}: IProps) => {
  const listItemComponents = items.map((item) => {
    return (
      <PlayersListItem key={item.id} {...item} />
    )
  })

  return (
    <List
      title={title}
      items={listItemComponents}
    />
  )
}

export default StatsList
