import React from 'react'
import ReactAutocomplete from 'react-autocomplete'
import cx from 'classnames'
import css from './Autocomplete.module.scss'
import Input from '../Input/Input';
import { IconName } from '../../Types/Types';

interface IProps {
  value: string
  onChange?: (value: string) => void
  onSelect: (value: string) => void
  onError?: (hasError: boolean) => void
  options: IAutocompleteItem[]

  //Input props
  placeholder?: string
  label?: string
  type?: string
  disabled?: boolean
  error?: boolean
  secondary?: boolean
  small?: boolean
  fullWidth?: boolean
  icon?: IconName
}

interface IState {
  value: string
  lastValue: string
}

interface IAutocompleteItem {
  value: string
  label: string
}

export class Autocomplete extends React.Component<IProps, IState> {
  constructor (props: IProps) {
    super(props)
    const value = this.getSelectedOptionLabel(props)
    this.state = {
      value,
      lastValue: value,
    }
  }

  componentDidUpdate (prevProps: IProps) {
    if (prevProps.value !== this.props.value) {
      const value = this.getSelectedOptionLabel(this.props)
      this.setState({
        value,
        lastValue: value,
      })
    }
  }

  onMenuVisibilityChange = (isOpen: boolean) => {
    if (!isOpen) {
      const { value } = this.state
      this.setState({ lastValue: value })
    }
  }

  onBlur = (onBlur: (evt: any) => void) => (evt: any) => {
    if (onBlur) onBlur(evt);

    const { value } = this.state
    const selectedLabel = this.getSelectedOptionLabel(this.props)

    if (selectedLabel !== value) {
      this.setError(true)
    }
  }

  setError = (error: boolean) => {
    const { onError } = this.props

    if (onError) onError(error);
  }

  getSelectedOptionLabel = (props: IProps) => {
    const {
      value,
      options,
    } = props

    if (value && options && options.length) {
      const selectedValue = options.find(opt => opt.value === value)
      return selectedValue ? selectedValue.label : ''
    }

    return ''
  }

  shouldItemRender = (item: IAutocompleteItem, value: string) => {
    return (this.state.lastValue === value)
      || item.label.toLowerCase().indexOf(value.toLowerCase()) > -1
  }

  getItemValue = (item: IAutocompleteItem) => item.value

  renderItem = (item: IAutocompleteItem, highlighted: boolean) => {
    return (
      <div
        className={
          cx(
            css.item,
            {
              [css.highlighted]: highlighted,
              [css.secondary]: this.props.secondary,
              [css.small]: this.props.small,
            }
          )
        }
        key={item.value}
      >
        {item.label}
      </div>
    )
  }

  renderInput = (props: any) => {
    const {
      onChange,
      onBlur: inputOnBlur,
      ref,
      ...rest
    } = props

    const {
      value,
      onChange: ownOnChange,
      onSelect,
      options,
      error,
      ...inputProps
    } = this.props

    return (
      <Input
        inputRef={ref}
        onChangeNative={onChange}
        iconSuffix='expand'
        {...inputProps}
        {...rest}
        error={error}
        onBlur={this.onBlur(inputOnBlur)}
      />
    )
  }

  onChange = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLInputElement
    const value = target.value

    this.setError(false)
    this.setState({ value })
    const { onChange } = this.props
    if (onChange) {
      onChange(value)
    }
  }

  onSelect = (value: string) => {
    const selectedOption = this.props.options.find(o => o.value === value)

    if (selectedOption) {
      this.setError(false)
      this.setState({
        value: selectedOption.label,
        lastValue: selectedOption.label,
      })

      const { onSelect } = this.props
      if (onSelect) {
        onSelect(value)
      }
    }
  }

  render () {
    const menuStyle = {
      borderRadius: '4px',
      boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
      background: 'rgba(255, 255, 255, 1)',
      padding: '0',
      overflow: 'auto',
      maxHeight: '200px',
      position: 'fixed',
      zIndex: 99,
    }

    const wrapperStyle = {
      display: 'inline-block',
      width: 'auto',
    }

    if (this.props.fullWidth) {
      wrapperStyle.width = '100%'
    }

    return (
      <ReactAutocomplete
        items={this.props.options}
        renderInput={this.renderInput}
        shouldItemRender={this.shouldItemRender}
        getItemValue={this.getItemValue}
        renderItem={this.renderItem}
        value={this.state.value}
        onChange={this.onChange}
        onSelect={this.onSelect}
        menuStyle={menuStyle as React.CSSProperties}
        wrapperStyle={wrapperStyle as React.CSSProperties}
        onMenuVisibilityChange={this.onMenuVisibilityChange}
        selectOnBlur
      />
    )
  }

}

export default Autocomplete
