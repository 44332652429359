import * as React from 'react'
import css from './RadioLabeled.module.scss'
import cx from 'classnames'

interface IProps {
  selected: boolean
  label: string
  onSelect: () => void
}

interface IRadioProps {
  checked: boolean
}

const Radio = ({
  checked,
}: IRadioProps) => {
  const classes = cx(css.radio, {
    [css.checked]: checked,
  })
  return (
    <label className={classes}>
      <span className={css.inner} />
      <span className={css.outer} />
    </label>
  )
}

const RadioLabeled = ({
  selected,
  label,
  onSelect,
}: IProps) => {
  const classes = cx(css.wrapper, {
    [css.checked]: selected,
  })

  return (
    <div className={classes} onClick={onSelect}>
      <Radio checked={selected} />
      <span className={css.label}>{label}</span>
    </div>
  )

}

export default RadioLabeled
