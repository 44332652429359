import * as React from "react";

import gameDetailsState from "../../../../stores/GameDetails";
import { withRouter, RouteComponentProps } from "react-router";
import PlaylistPlayer from "./PlaylistPlayer";
import { PhaseNumber, EventTypeSelection, PossessionTypeSelection, IBallFrame, IGameEvent } from "../../../../Types/Types";
import { observer } from "mobx-react";

interface IProps extends RouteComponentProps<any> {
  phase?: PhaseNumber
  eventType?: EventTypeSelection
  possessionType?: PossessionTypeSelection
  events: IGameEvent[]
  currentEventIndex: number
  currentEventLastSelected?: number
  updateCurrentEventIndex: (i: number) => {}
}

interface IState {
  ballFrames?: Map<number, IBallFrame>
}

@observer
class VideoView extends React.Component<IProps, IState> {

  state: IState = {}

  componentDidMount () {
    const gameId = this.props.match.params.id

    gameDetailsState.getCurrentGame(gameId)
    gameDetailsState.getVideo(gameId)
    gameDetailsState.getTracks(gameId)
  }

  updateCurrentEventIndex = (i: number) => {
    this.props.updateCurrentEventIndex(i)
  }

  render () {
    if (gameDetailsState.video === null)
      return <div>Video not found</div>
    if (gameDetailsState.video === undefined)
      return null
    return (
      <PlaylistPlayer
        events={this.props.events}
        video={gameDetailsState.video}
        tracks={gameDetailsState.tracks}
        ballFrames={this.state.ballFrames}
        activeGame={gameDetailsState.currentGame}
        currentEventIndex={this.props.currentEventIndex}
        updateCurrentEventIndex={this.updateCurrentEventIndex}
        currentEventLastSelected={this.props.currentEventLastSelected}
        phaseStartTimes={gameDetailsState.currentGame ? gameDetailsState.currentGame.start_stream_times : []}
      />
    );
  }
}

export default withRouter(VideoView)
