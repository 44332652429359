import * as React from 'react'
import cx from 'classnames'
import css from './SimpleSlider.module.scss'
import { secondsToHHMMSS } from '../../../../Utils/CommonFunctions';

interface IProps {
  min: number
  max: number
  value: number
  buffered?: number
  seek?: (vale: number) => void
}

interface IState {
  percent: number
  tooltipValue: number
  tooltipPosition: number
}

class SimpleSlider extends React.Component<IProps, IState> {
  ref = React.createRef<HTMLDivElement>()
  state = {
    percent: 0,
    tooltipValue: 0,
    tooltipPosition: 0,
  }
  mouseDrag = false
  lastClickX = NaN
  showTooltip = false
  dragTime = NaN


  handleMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!this.props.seek) return
    if (!this.ref.current) return

    const offset = this.ref.current.getBoundingClientRect().left
    const clickX = e.pageX - offset

    this.mouseDrag = false
    this.dragTime = NaN

    if (clickX >= 0 && this.lastClickX !== clickX) {
      this.lastClickX = clickX
      this.props.seek(clickX / this.ref.current.clientWidth)
    } else {
      this.lastClickX = NaN
    }
  }

  handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!this.props.seek) return
    if (!this.ref.current) return

    if (isNaN(this.dragTime)) {
      this.dragTime = (new Date()).getTime()
      this.mouseDrag = true
      this.lastClickX = NaN
    }
  }

  stopPropagation (e: React.SyntheticEvent) {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
  }

  getBufferedPct = () => {
    const { min, max, buffered } = this.props
    if (max - min && buffered) {
      return (100 * (buffered - min)) /
        (max - min)
    }
    return 0
  }

  handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!this.ref.current) return

    this.showTooltip = true
    const { max } = this.props
    const offset = this.ref.current.getBoundingClientRect().left
    const clickX = e.pageX - offset

    if (clickX >= 0) {
      let pct = (clickX / this.ref.current.clientWidth)
      pct = Math.max(Math.min(pct, 100), 0)
      const seconds = (pct * max)

      this.setState({ tooltipPosition: pct * 100, tooltipValue: seconds })

      if (this.mouseDrag) {
        this.setState({ percent: pct * 100 })

        const currentTime = (new Date()).getTime()
        if (!isNaN(this.dragTime) && currentTime - this.dragTime > 1000) {
          this.dragTime = currentTime
        }
      }
    }
  }

  handleMouseLeave = () => {
    this.showTooltip = false
  }

  render () {
    let pct
    if (!this.mouseDrag) {
      pct =
        this.props.max - this.props.min > 0
          ? (100 * (this.props.value - this.props.min)) /
          (this.props.max - this.props.min)
          : 0
    } else {
      pct = this.state.percent
    }

    const buf = this.getBufferedPct()

    const seekDisabled = !this.props.seek

    return (
      <div
        className={cx(css.container, { [css.disabled]: seekDisabled })}
        ref={this.ref}
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
        onMouseMove={this.handleMouseMove}
        onMouseLeave={this.handleMouseLeave}
      >
        <div className={css.outerCss}>
          <div className={css.buffered} style={{ width: `${buf}%` }} />
          <div className={css.innerCss} style={{ width: `${pct}%` }} />
        </div>
        <div className={css.circle} style={{ left: `${pct}%` }} />
        <div
          className={css.circle}
          style={{ left: `${pct}%`, opacity: 0 }}
        />
        {(this.showTooltip) ?
          <div
            className={css.tooltip}
            style={{ left: `${this.state.tooltipPosition}%` }}
          >
            {secondsToHHMMSS(this.state.tooltipValue)}
          </div> : null}
      </div>
    )
  }
}

export default SimpleSlider
