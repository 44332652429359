import { IRecording } from "../../../stores/Recording";

export const getRecordingTitle = (recording: IRecording) => {
  let title: string = ''

  if (recording.title) {
    title = recording.title
  } else if (recording.home_team && recording.home_team.name) {
    title = recording.home_team.name
    if (recording.away_team && recording.away_team.name) {
      title += ` - ${recording.away_team.name}`
    }
  } else if (recording.type) {
    title = recording.type
  } else {
    title = 'Recording'
  }

  return title
}
