import React from 'react'
import css from './GridSection.module.scss'

export interface IGridItem {
  component: JSX.Element
  id: string
}

interface IProps {
  items: IGridItem[]
  title: string
  col?: number
}

interface IComponentProps {
  component: JSX.Element
}

const GridItem = ({ component }: IComponentProps) => (
  <div className={css.item}>
    {component}
  </div>
)

export const GridSection = ({
  items,
  title,
  col,
}: IProps) => {
  if (!items.length) return null;

  let gridTemplateColumns = ''
  let style: object = {}

  if (col) {
    for (let i = 0; i < col; i++) {
      gridTemplateColumns += '1fr '
    }

    style = { gridTemplateColumns }
  }

  const gridItems = items.map(c => (
    <GridItem key={c.id} component={c.component} />)
  )

  return (
    <div className={css.container}>
      <div className={css.title}>
        {title}
      </div>
      <div style={style} className={css.list}>
        {gridItems}
      </div>
    </div>
  )
}

export default GridSection
