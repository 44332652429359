import React from 'react'
import Icon from '../../Icon/Icon'
import cx from 'classnames'
import css from './Widget.module.scss'

interface IProps {
  title: string
}

interface IState {
  collapsed: boolean
}

export class Widget extends React.Component<IProps, IState> {
  constructor (props: IProps) {
    super(props)
    this.state = {
      collapsed: false,
    }
  }

  toggleCollapsed = () => {
    this.setState({ collapsed: !this.state.collapsed })
  }

  render () {
    const {
      title,
      children,
    } = this.props

    const {
      collapsed,
    } = this.state

    const classes = cx(
      css.wrapper,
      {
        [css.collapsed]: collapsed,
      }
    )

    return (
      <div className={classes}>
        <div className={css.header}>
          <div className={css.headerLeft}>
            <div className={css.title}>{title}</div>
            <div
              className={css.collapse}
              onClick={this.toggleCollapsed}
            >
              <Icon
                icon='expand'
              />
            </div>
          </div>
        </div>
        <div className={css.content}>
          {children}
        </div>
      </div>
    )
  }
}

export default Widget
