import * as React from "react";
import { autorun } from "mobx";
import { observer } from "mobx-react";
import { PhaseNumber, ITableData, ITableRow, IPlayerStats } from "../../../../Types/Types";
import StatsTable from "../../../../Components/Dashboard/StatsTable/StatsTable";
import GameStatsList from "../../../../Components/Dashboard/GameStatsList/GameStatsList";
import ValueCard from "../../../../Components/Dashboard/ValueCard/ValueCard";
import { roundValueToString, secondsToHHMMSS } from "../../../../Utils/CommonFunctions";
import BarChart from "../../../../Components/Dashboard/BarChart/BarChart";
import liveStatsSate from "../../../../stores/LiveStatsStore";
import css from './StatsView.module.scss'
import gameDetailsState from "../../../../stores/GameDetails";
import { t } from "../../../../localization/i18n";
import Widget from "../../../../Components/Dashboard/Widget/Widget";

interface IProps {
  phase: PhaseNumber
}

@observer
export default class StatsView extends React.Component<IProps> {

  componentDidMount () {
    liveStatsSate.subscribeToStats()
  }

  componentWillUnmount () {
    liveStatsSate.unsubscribe()
  }

  disposer = autorun(reaction => {
    if (!liveStatsSate.currentHomeGameStats || !liveStatsSate.currentAwayGameStats) {
      reaction.dispose()
      return
    }
  })

  // getGameStatsComponents = () => {
  //   const title = ''
  //   const data = gameStatsState.gameStatsData

  //   return (
  //     <StatsTable
  //       title={title}
  //       data={data}
  //     />
  //   )
  // }

  getMatchTimeComponent = () => {
    if (!liveStatsSate.currentHomeGameStats || !liveStatsSate.currentAwayGameStats)
      return null

    const time = liveStatsSate.currentHomeGameStats.match_time

    return (
      <div className={css.lastUpdate}>
        <ValueCard
          title={t('stats.last_update')}
          subtitle={t('stats.match_time') + ':'}
          value={secondsToHHMMSS(time / 1000)}
        />
      </div>
    )
  }

  getHomeTeamStats = () => {
    if (!liveStatsSate.currentHomeGameStats)
      return null

    const homeTeamName = (gameDetailsState.currentGame && gameDetailsState.currentGame.home_team) ? gameDetailsState.currentGame.home_team.name : 'Home team'
    const title = homeTeamName + ' ' + t('player.players')
    const players = liveStatsSate.currentHomePlayerStats

    if (!players.length) return null

    return (
      <div className={css.homeTeam}>
        <GameStatsList
          title={homeTeamName}
          stats={liveStatsSate.currentHomeGameStats}
        />
        {this.getPlayerStatsTable(players, title)}
      </div>
    )
  }

  getAwayTeamStats = () => {
    if (!liveStatsSate.currentAwayGameStats)
      return null

    const awayTeamName = (gameDetailsState.currentGame && gameDetailsState.currentGame.away_team) ? gameDetailsState.currentGame.away_team.name : 'Away team'
    const title = awayTeamName + ' ' + t('player.players')
    const players = liveStatsSate.currentAwayPlayerStats

    if (!players.length) return null

    return (
      <div className={css.awayTeam}>
        <GameStatsList
          title={awayTeamName}
          stats={liveStatsSate.currentAwayGameStats}
        />
        {this.getPlayerStatsTable(players, title)}
      </div>
    )
  }

  getGameStatsListComponents = () => {
    if (!liveStatsSate.currentHomeGameStats || !liveStatsSate.currentAwayGameStats)
      return null

    const homeTeamName = (gameDetailsState.currentGame && gameDetailsState.currentGame.home_team) ? gameDetailsState.currentGame.home_team.name : 'Home team'
    const awayTeamName = (gameDetailsState.currentGame && gameDetailsState.currentGame.away_team) ? gameDetailsState.currentGame.away_team.name : 'Away team'

    return (
      <div className={css.lists}>
        <GameStatsList
          title={homeTeamName}
          stats={liveStatsSate.currentHomeGameStats}
        />
        <GameStatsList
          title={awayTeamName}
          stats={liveStatsSate.currentAwayGameStats}
        />
      </div>
    )
  }

  getPlayerStatsTable = (players: IPlayerStats[], title: string) => {
    const header = [
      t('stats.number'),
      t('stats.distance') + ' (' + t('common.units.km') + ')',
      t('stats.passes'),
      t('stats.sprints'),
      t('stats.high_intensity_runs_short'),
      t('stats.accelerations_short'),
      t('stats.decelerations_short'),
      t('stats.balls_won'),
      t('stats.balls_lost'),
    ]
    const rows = players.map((p, i) => {
      const distance = roundValueToString(p.distance / 1000, 2)
      return {
        id: p.id,
        content: [
          p.jersey_number,
          distance,
          p.successful_passes,
          p.sprints,
          p.hi_runs,
          p.accelerations,
          p.decelerations,
          p.ball_won,
          p.ball_lost,
        ],
      } as ITableRow
    })
    const tableData: ITableData = { header, rows }

    return (
      <div className={css.playerTable}>
        <Widget title={title}>
          <StatsTable
            data={tableData}
          />
        </Widget>
      </div>
    )
  }

  getTopDistanceComponent = () => {

    const homeTeamName = (gameDetailsState.currentGame && gameDetailsState.currentGame.home_team) ? gameDetailsState.currentGame.home_team.name : 'Home team'
    const awayTeamName = (gameDetailsState.currentGame && gameDetailsState.currentGame.away_team) ? gameDetailsState.currentGame.away_team.name : 'Away team'

    const homeTop = liveStatsSate.currentTopDistanceHomePlayer
    const awayTop = liveStatsSate.currentTopDistanceAwayPlayer

    const homeTeamComp = (homeTop)
      ? (
        <ValueCard
          title={t('stats.top_distance') + ' ' + homeTeamName}
          subtitle={t('player.title') + ' #' + homeTop.jersey_number.toString()}
          value={roundValueToString(homeTop.distance / 1000, 2) + ' ' + t('common.units.km')}
        />
      ) : null
    const awayTeamComp = (awayTop)
      ? (
        <ValueCard
          title={t('stats.top_distance') + ' ' + awayTeamName}
          subtitle={t('player.title') + ' #' + awayTop.jersey_number.toString()}
          value={roundValueToString(awayTop.distance / 1000, 2) + ' ' + t('common.units.km')}
        />
      ) : null

    return (
      <div className={css.topDistances}>
        {homeTeamComp}
        {awayTeamComp}
      </div>
    )
  }

  getPossessionChartComponent = () => {
    if (!liveStatsSate.currentHomeGameStats || !liveStatsSate.currentAwayGameStats)
      return null

    const homeTeamName = (gameDetailsState.currentGame && gameDetailsState.currentGame.home_team) ? gameDetailsState.currentGame.home_team.name : 'Home team'
    const awayTeamName = (gameDetailsState.currentGame && gameDetailsState.currentGame.away_team) ? gameDetailsState.currentGame.away_team.name : 'Away team'

    const totalPossession = liveStatsSate.currentHomeGameStats.possession + liveStatsSate.currentAwayGameStats.possession
    const homePossession = (totalPossession > 0) ? (liveStatsSate.currentHomeGameStats.possession / totalPossession) * 100 : 0
    const awayPossession = (totalPossession > 0) ? (liveStatsSate.currentAwayGameStats.possession / totalPossession) * 100 : 0
    const maxY = 100

    const possessionData = [
      { category: homeTeamName, y: homePossession },
      { category: awayTeamName, y: awayPossession },
    ]

    return (
      <div className={css.possessionChart}>
        <BarChart
          id='id'
          title={t('stats.possession')}
          data={possessionData}
          maxY={maxY}
          height={200}
          width={300}
        />
      </div>
    )
  }

  render () {

    return (
      <div className={css.container}>
        {this.getHomeTeamStats()}
        {this.getAwayTeamStats()}
        {/* {this.getGameStatsListComponents()} */}
        {/* {this.getGameStatsComponents()} */}
        <div className={css.columnThree}>
          {this.getMatchTimeComponent()}
          {this.getPossessionChartComponent()}
          {this.getTopDistanceComponent()}
        </div>
      </div>
    );
  }
}
