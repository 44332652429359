import * as React from 'react'
import { IconName } from '../../Types/Types'
import css from './Icon.module.scss'

const icons = [
  'back',
  'close',
  'expand',
  'menu',
  'more',
  'settings',
  'edit',
  'download',
].reduce((obj, key) => { obj[key] = require(`./assets/${key}.svg`); return obj }, {})

interface IProps {
  /** 'back' | 'close' | 'expand' | 'menu' | 'more' | 'settings' | 'edit' | 'download' */
  icon: IconName
}

export const Icon = ({
  icon,
}: IProps) => {
  if (icons[icon]) {
    return (
      <div className={css.icon}>
        <img src={icons[icon]} alt='' />
      </div>
    )
  }

  return null
}

export default Icon
