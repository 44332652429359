import * as React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import { observer } from "mobx-react";
import appState from "./stores/AppStateStore";
import sidebarState from "./stores/SidebarStore";

import Recordings from "./Containers/Recordings/Recordings";
import Teams from "./Containers/Teams/Teams";
import Reports from "./Containers/Reports/Reports";
import LIAdmin from "./Containers/LIAdmin/LIAdmin";

import Login from "./Containers/Login/Login";


import NotificationSystem from 'react-notification-system'
import Notifications from "./Utils/Notifications";
import LeftMenu from "./Components/LeftMenu/LeftMenu";

import cx from 'classnames';
import css from './App.module.scss';

class App extends React.Component {

  notifactionRef: React.RefObject<NotificationSystem.System>

  constructor (props: any) {
    super(props)
    this.notifactionRef = React.createRef()
  }


  componentDidMount () {
    if (this.notifactionRef.current)
      Notifications.setSystem(this.notifactionRef.current)
  }

  render () {
    const { isLoggedIn } = appState
    const { sidebar } = sidebarState
    return (
      <BrowserRouter>
        <>
          {isLoggedIn ? (
            <div className={cx(css.container, { [css.withSidebar]: !!sidebar })}>
              <div className={css.header} id='appHeader' />
              <div className={css.menu} id='appMenu' >
                <LeftMenu />
              </div>

              <div className={css.content}>
                <Switch>
                  <Redirect from="/" to="/games" exact />
                  <Route path="/team" component={Teams} />
                  <Route path="/reports" component={Reports} />
                  <Route path="/admin" component={LIAdmin} />
                  <Route path="/" component={Recordings} />
                </Switch>
              </div>
              <div className={cx(css.sidebar, { [css.hidden]: !sidebar })} id='appSidebar' />
            </div>
          ) : (
              <Login />
            )}
          <NotificationSystem ref={this.notifactionRef} />
        </>
      </BrowserRouter>
    );
  }
}

export default observer(App)
