import * as React from 'react'
import gridConfig from './gridConfig'

interface IProps {
  width: number
  length: number
}

const FieldLines = ({
  width,
  length,
}: IProps) => {
  const lineWidth = 0.2;
  const marksColor = gridConfig.color;
  const padding = gridConfig.padding

  const x1 = padding;
  const y1 = padding;
  const w = width;
  const h = length;
  const x2 = x1 + w;
  const y2 = y1 + h;
  const mx = x1 + w / 2;
  const my = y1 + h / 2;

  const goalAreaLength = gridConfig.goal.length;
  const goalAreaWidth = gridConfig.goal.width
  const halfGoalAreaWidth = goalAreaWidth / 2;

  const penaltyAreaLength = gridConfig.penalty.length;
  const penaltyAreaWidth = gridConfig.penalty.width;
  const halfPenaltyAreaWidth = penaltyAreaWidth / 2;

  const dx = Math.sqrt(Math.pow(9.15, 2) - Math.pow(5.5, 2));

  return (
    <>
      <rect x={x1} y={y1} width={w} height={h} fillOpacity="0" />

      {/* <!-- halfway line --> */}
      <line x1={x1} y1={my} x2={x2} y2={my} />
      <circle cx={mx} cy={my} r={9.15} fillOpacity="0" />

      {/* <!-- centre mark and penalty marks --> */}
      <circle cx={mx} cy={my} r={lineWidth * 2} strokeWidth="0" fill={marksColor} />
      <circle cx={mx} cy={y1 + 11} r={lineWidth * 2} strokeWidth="0" fill={marksColor} />
      <circle cx={mx} cy={y2 - 11} r={lineWidth * 2} strokeWidth="0" fill={marksColor} />

      {/* <!-- corner arcs --> */}
      <path
        d={`M ${x1 + 1},${y1} A 1 1, 0, 0, 1, ${x1},${y1 + 1}`}
        fillOpacity="0"
      />
      <path
        d={`M ${x2 - 1},${y1} A 1 1, 0, 0, 0, ${x2},${y1 + 1}`}
        fillOpacity="0"
      />
      <path
        d={`M ${x1 + 1},${y2} A 1 1, 0, 0, 0, ${x1},${y2 - 1}`}
        fillOpacity="0"
      />
      <path
        d={`M ${x2 - 1},${y2} A 1 1, 0, 0, 1, ${x2},${y2 - 1}`}
        fillOpacity="0"
      />

      {/* <!-- penalty areas --> */}
      <path
        d={`M ${mx},${y1} m -${halfPenaltyAreaWidth},0 l 0,${penaltyAreaLength} l ${penaltyAreaWidth},0 l 0,-${penaltyAreaLength}`}
        fillOpacity="0"
      />
      <path
        d={`M ${mx},${y2} m -${halfPenaltyAreaWidth},0 l 0,-${penaltyAreaLength} l ${penaltyAreaWidth},0 l 0,${penaltyAreaLength}`}
        fillOpacity="0"
      />

      {/*  <!-- goal areas --> */}
      <path
        d={`M ${mx},${y1} m -${halfGoalAreaWidth},0 l 0,${goalAreaLength} l ${goalAreaWidth},0 l 0,-${goalAreaLength}`}
        fillOpacity="0"
      />
      <path
        d={`M ${mx},${y2} m -${halfGoalAreaWidth},0 l 0,-${goalAreaLength} l ${goalAreaWidth},0 l 0,${goalAreaLength}`}
        fillOpacity="0"
      />

      {/* <!-- penalty arcs -->*/}
      <path
        d={`M ${mx - dx},${y1 + penaltyAreaLength} A 9.15 9.15, 0, 0, 0, ${mx +
          dx},${y1 + penaltyAreaLength}`}
        fillOpacity="0"
      />
      <path
        d={`M ${mx - dx},${y2 - penaltyAreaLength} A 9.15 9.15, 0, 0, 1, ${mx +
          dx},${y2 - penaltyAreaLength}`}
        fillOpacity="0"
      />
    </>
  )
}

export default FieldLines
