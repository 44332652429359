import React, { Component } from 'react';

import css from './Button.module.scss'
import classNames from 'classnames'

interface IButtonProps {
  onClick?: () => void
  href?: string

  className?: string
  style?: any
  title?: string
  target?: string

  fullWidth?: boolean
  secondary?: boolean
  clear?: boolean //DEPRECATED
  disabled?: boolean
  small?: boolean
  remove?: boolean
}

export class Button extends Component<IButtonProps> {
  handleClick = (evt: React.SyntheticEvent) => {
    evt.nativeEvent.stopImmediatePropagation()

    const { onClick, disabled } = this.props
    if (onClick && !disabled) onClick();
  }

  stopPropagation = (evt: React.SyntheticEvent) => {
    evt.nativeEvent.stopImmediatePropagation()
  }


  render () {
    const {
      href,
      className,
      clear,
      secondary,
      remove,
      fullWidth,
      disabled,
      small,
      children,
      ...rest
    } = this.props

    const classes = classNames(
      css.button,
      {
        [css.clear]: clear,
        [css.secondary]: secondary,
        [css.remove]: remove,
        [css.fullWidth]: fullWidth,
        [css.disabled]: disabled,
        [css.small]: small,
      },
      className
    )

    if (href) {
      return (
        <a
          href={href}
          className={classes}
          {...rest}
        >
          {children}
        </a>
      );

    }

    return (
      <button
        className={classes}
        onClick={this.handleClick}
        {...rest}
      >
        {children}
      </button>
    );
  }
}

export default Button;
