import * as React from 'react'

import { t } from '../../localization/i18n'
import { TeamSelection, IGameEvent, IBallWinEvent } from '../../Types/Types';

import cx from 'classnames'
import css from './EventPlaylistItem.module.scss'

interface IProps {
  event: IGameEvent
  active: boolean
  even: boolean
  homeTeam: string
  awayTeam: string
  activeTopOffset: (y: number, h: number) => void
}

export default class EventPlaylistItem extends React.Component<IProps> {
  itemRef: React.RefObject<HTMLDivElement>

  constructor (props: IProps) {
    super(props)
    this.itemRef = React.createRef()
  }

  componentDidMount () {
    if (this.props.active) {
      this.setActiveEventPosition()
    }
  }

  componentDidUpdate (prevProps: IProps) {
    if (this.props.active && !prevProps.active) {
      this.setActiveEventPosition()
    }
  }

  setActiveEventPosition () {
    const element = this.itemRef.current
    if (element) {
      this.props.activeTopOffset(element.offsetTop, element.offsetHeight)
    }
  }

  formatTime (val: number) {  //TODO helper?
    if (!val)
      return '00:00:00'
    let value = Math.floor(val / 1000)
    const hours = Math.floor(value / (3600))
    value = (value % (3600))
    const minutes = `0${Math.floor(value / 60)}`.substr(-2)
    const seconds = `0${value % 60}`.substr(-2)
    return `${hours}:${minutes}:${seconds}`
  }

  getTeamName = () => {
    const { event } = this.props

    if (event.type === 'ball_win' && event.initialVersion !== 0) {
      return (event as IBallWinEvent).team === 'home_team' ? this.props.homeTeam : this.props.awayTeam
    }

    return event.from_team === TeamSelection.Home ? this.props.homeTeam : this.props.awayTeam
  }

  getTitle = () => {
    const { event } = this.props
    const team = this.getTeamName()

    if (event.type === 'ball_win' && typeof ((event as IBallWinEvent).jersey_number) !== 'undefined') {
      return `${t('event.possessionWonBy')} #${(event as IBallWinEvent).jersey_number} (${team})`
    }

    if (event.live_insight) {
      const {
        from_team,
        to_team,
        from_jersey_number,
        to_jersey_number,
      } = event.live_insight

      if (typeof (from_jersey_number) === 'undefined' || typeof (to_jersey_number) === 'undefined') {
        return team
      }

      if (from_team && to_team && from_team !== to_team) {
        const fromTeamName = from_team === 'home_team' ? this.props.homeTeam : this.props.awayTeam
        const toTeamName = to_team === 'home_team' ? this.props.homeTeam : this.props.awayTeam

        return `${t('event.interceptionBy')} #${to_jersey_number} (${toTeamName}). ${t('event.passFrom')} #${from_jersey_number} (${fromTeamName})`
      }

      return `${t('event.passFrom')} #${from_jersey_number} ${t('common.to')} #${to_jersey_number} (${team})`
    }

    return team
  }

  renderEventType = () => {
    const { event } = this.props

    if (event.initialVersion !== 0) {
      return null
    }

    return (
      <div className={css.title}>
        {t('event.' + event.type)}
      </div>
    )
  }

  render () {
    const title = this.getTitle()

    return (
      <div
        ref={this.itemRef}
        className={
          cx(
            css.container,
            {
              [css.active]: this.props.active,
              [css.even]: this.props.even,
            }
          )
        }
      >
        <div className={css.title}>
          {title}
        </div>
        {this.renderEventType()}
        <div className={css.time}>
          {this.formatTime(this.props.event.start_match_time)}
        </div>
      </div>
    )
  }
}
