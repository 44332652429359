import * as React from 'react'

import { observer } from 'mobx-react'
import { IRecording } from '../../../stores/Recording'
import appState from '../../../stores/AppStateStore'
import teamState from '../../../stores/TeamStore'

import ModalSimple from '../../../Components/ModalSimple/ModalSimple'

import MemberList from './MemberList/MemberList'

import css from './RecordingMembersModal.module.css'
import ConfirmClose from '../../../Components/ConfirmClose/ConfirmClose';
import { ITeamPlayer } from '../../../Types/Types';
import gamePlayerState from '../../../stores/GamePlayerStore';

interface IProps {
  isOpen: boolean
  recordingToEdit?: IRecording
  onSubmit: (players: ITeamPlayer[]) => void
  closeWindow: () => void
}

interface IState {
  hasBeenEdited: boolean
  isCloseModalOpen: boolean
  allPlayers: ITeamPlayer[]
}

@observer
class RecordingMembersModal extends React.Component<IProps, IState> {

  originalState = {
    hasBeenEdited: false,
    isCloseModalOpen: false,
    allPlayers: [],
  }

  constructor (props: IProps) {
    super(props)

    this.state = this.originalState
  }

  componentDidMount () {
    const { user } = appState
    if (user && user.teams && user.teams.length === 1) {
      teamState.getTeamById(user.teams[0])
    } else {
      //What if we have more than one team?
      //teamListState.getTeamListFromFirebase()
      throw new Error("Multiple teams not implemeneted")
    }
  }

  componentWillUpdate (newProps: IProps) {
    if (newProps.isOpen && !this.props.isOpen) {
      const { recordingToEdit } = newProps
      if (recordingToEdit && recordingToEdit.id) {

        const teamId = teamState.currentTeam ? teamState.currentTeam.id : ''

        gamePlayerState.getGamePlayers(recordingToEdit.id, teamId, newPlayers => {
          if (teamState.currentTeam) {
            const players = this.concatPlayerListsNoDuplicates(teamState.currentTeam.players, newPlayers)
            this.setState({ allPlayers: players || [] })
          } else {
            this.setState({ allPlayers: newPlayers || [] })
          }
        })
      }

    }
  }

  componentWillUnmount () {
    teamState.detach()
    gamePlayerState.detatchGamePlayers()
  }

  concatPlayerListsNoDuplicates = (players: ITeamPlayer[], selectedPlayers: ITeamPlayer[]) => {

    const playerListItems: ITeamPlayer[] = []

    players.forEach(player => {
      let found = false
      selectedPlayers.forEach(selected => {
        if (player.id === selected.id) {
          selected.selected = true
          playerListItems.push(selected)
          found = true
        }
      })
      if (!found) {
        player.selected = false
        playerListItems.push(player)
      }
    })

    return playerListItems
  }

  onClose = () => {
    if (this.state.hasBeenEdited) {
      this.setState({ isCloseModalOpen: true })
    } else {
      this.closeModal()
    }
  }

  closeModal = () => {
    this.setState(this.originalState)
    this.props.closeWindow()
  }

  onConfirmClose = () => {
    this.closeModal()
  }

  onConfirmCancelClose = () => {
    this.setState({ isCloseModalOpen: false })
  }

  handleSubmit = () => {
    this.props.onSubmit(this.state.allPlayers)
    this.closeModal()
  }

  onSelect = (player: ITeamPlayer, checked: boolean) => {
    player.selected = checked
    const newPlayerList = [...this.state.allPlayers]
    const index = newPlayerList.findIndex(i => i.id === player.id)
    newPlayerList[index] = player
    this.setState({ allPlayers: newPlayerList })
  }

  renderPlayersList = () => {

    if (teamState.currentTeam) {
      return (
        <MemberList
          players={this.state.allPlayers}
          onSelect={this.onSelect}
        />
      )
    } else {
      return <div className={css.playersList}>Loading...</div>
    }
  }

  getFooterButtons = () => {
    return [
      { title: 'Cancel', handler: this.closeModal, secondary: true },
      { title: 'Save', handler: this.handleSubmit },
    ]
  }

  render () {
    const { isOpen } = this.props

    return (
      <div>
        <ModalSimple
          isOpen={isOpen}
          onClose={this.onClose}
          title={`Edit Members`}
          footerButtons={this.getFooterButtons()}
          styles={{
            width: '600px',
            height: '600px',
          }}
        >
          {this.renderPlayersList()}
        </ModalSimple>
        <ConfirmClose
          isOpen={this.state.isCloseModalOpen}
          onConfirm={this.onConfirmClose}
          onCancel={this.onConfirmCancelClose}
        />
      </div>
    )
  }
}
export default observer(RecordingMembersModal)
