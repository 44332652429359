import * as React from "react";
import * as d3 from 'd3';
import { IBarChartDataItem } from "../../../Types/Types";
import themeGlobal from '../../../Themes/global'
import Widget from "../Widget/Widget";
import { roundValueToString } from "../../../Utils/CommonFunctions";

const COLOR_LIGHT_BLUE = themeGlobal.colors.colorBrandMainLight
const COLOR_BRAND = themeGlobal.colors.colorBrandAccent
const COLOR_GRAY = themeGlobal.colors.colorGray

interface IProps {
  id: string
  title: string
  data: IBarChartDataItem[]
  maxY?: number
  width?: number
  height?: number
  flexible?: boolean
}

class BarChart extends React.Component<IProps> {
  componentDidMount = () => {
    this.drawChart()
  }

  componentDidUpdate = (prevProps: IProps) => {
    if (JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data)) {
      this.drawChart()
    }
  }

  getChartId = () => `chart-${this.props.id}`

  drawChart = () => {
    const {
      data,
    } = this.props

    const { width: svgWidth, height: svgHeight } = this.getDimensions()

    if (!(svgWidth && svgHeight)) return

    const chartSelector = `#${this.getChartId()}`
    const margin = { top: 5, right: 5, bottom: 5, left: 5 };

    const xScale = d3.scaleBand()
      .domain(data.map(d => d.category))
      .range([margin.left, svgWidth - margin.right])
      .padding(0.2)

    const yMax = (this.props.maxY)
      ? (this.props.maxY + margin.top)
      : d3.max(data.map(d => d.y)) as number
    const yScale = d3.scaleLinear()
      .domain([0, yMax])
      .range([svgHeight - margin.bottom, margin.top])

    const color = [COLOR_LIGHT_BLUE, COLOR_BRAND]

    d3.select(chartSelector).select('svg').remove()
    const svg = d3.select(chartSelector).append('svg')
      .attr("width", xScale.bandwidth() * 2 + margin.right + margin.left)
      .attr("height", svgHeight + margin.top + margin.bottom)
      .style("display", 'block')
      .style("margin", 'auto');

    const g = svg.append("g")

    g.selectAll("rect")
      .data(data)
      .enter()
      .append('rect')
      .attr("fill", (d, i) => color[i % 2])
      .attr("x", (d, i) => i * xScale.bandwidth() + margin.right)
      .attr("y", d => yScale(d.y))
      .attr("height", d => yScale(0) - (yScale(d.y + 1)))
      .attr("width", xScale.bandwidth())
      .style('padding-left', '10px')
      .style('padding-right', '10px')

    const labels = g.selectAll('text')
      .data(data)
      .enter()

    labels.append("text")
      .text(d => roundValueToString(d.y, 0) + ' %')
      .attr("x", (d, i) => i * xScale.bandwidth() + margin.right)
      .attr("y", d => yScale(d.y))
      .attr("text-anchor", "middle")
      .attr('fill', COLOR_GRAY)
      .attr('font-size', '14px')
      .attr('dx', xScale.bandwidth() / 2)
      .attr('dy', '-3')

    labels.append("text")
      .text(d => d.category)
      .attr("x", (d, i) => i * xScale.bandwidth() + margin.right)
      .attr("y", d => yScale(0))
      .attr("text-anchor", "middle")
      .attr('fill', COLOR_GRAY)
      .attr('font-size', '12px')
      .attr('dx', xScale.bandwidth() / 2)
      .attr('dy', '15')
  }

  getDimensions = () => {
    const {
      flexible,
      width: pWidth,
      height: pHeight,
    } = this.props

    let width: number = 0
    let height: number = 0

    if (flexible) {
      const container = this.getContainerElement()
      if (container) {
        width = container.clientWidth
        height = container.clientHeight

        if (height === 0 && pHeight)
          height = pHeight
      }
    } else if (pWidth && pHeight) {
      width = pWidth
      height = pHeight;
    }

    return {
      width,
      height,
    }
  }

  getContainerElement = () => {
    const { flexible } = this.props
    if (flexible) {
      return document.querySelector(`#${this.getChartId()}`)
    }
    return
  }

  render () {
    return (
      <Widget title={this.props.title}>
        <div id={this.getChartId()} />
      </Widget>
    )
  }
}

export default BarChart
