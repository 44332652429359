import * as React from 'react'
import { Table, TableHead, TableCell, TableRow, TableBody, TableSortLabel } from '@material-ui/core';
import { ITableData, ITableRow } from '../../../Types/Types';

interface IProps {
  title?: string
  data: ITableData
}

interface IState {
  order: 'desc' | 'asc'
  orderBy: number
  rowData: ITableRow[]
}

export default class StatsTable extends React.Component<IProps, IState> {

  constructor (props: IProps) {
    super(props)
    this.state = {
      order: 'desc',
      orderBy: -1,
      rowData: this.props.data.rows,
    }
  }

  componentDidUpdate (prev: IProps) {
    if (prev.data.rows !== this.props.data.rows) {
      this.setState({
        rowData: this.props.data.rows,
      }, () => {
        if (this.state.orderBy !== -1)
          this.sortRows(this.state.order, this.state.orderBy)
      })
    }
  }

  handleHeaderClick = (selected: number) => (evt: React.SyntheticEvent) => {
    this.handleSort(selected)
  }

  handleSort = (selected: number) => {

    if (selected === this.state.orderBy) {
      const order = this.state.order === "asc" ? "desc" : "asc"
      this.setState({ order })
      this.sortRows(order, selected)
    }
    else {
      const order = "desc"
      this.setState({ orderBy: selected, order })
      this.sortRows(order, selected)
    }
  }

  sortRows = (order: string, orderBy: number) => {
    if (!this.state.rowData)
      return
    const newArray = this.state.rowData.slice().sort((a, b) => {
      if (isNaN(parseInt(a.content[orderBy], 0)) || isNaN(parseInt(b.content[orderBy], 0))) {
        if (order === 'asc')
          return a.content[orderBy].localeCompare(b.content[orderBy])
        else
          return b.content[orderBy].localeCompare(a.content[orderBy])
      }
      else {
        if (order === 'asc')
          return +a.content[orderBy] - +b.content[orderBy]
        else
          return +b.content[orderBy] - +a.content[orderBy]
      }
    })

    this.setState({ rowData: newArray })
  }

  getHeader () {

    const header = this.props.data.header

    return (
      <TableRow>
        {header.map((cell, i) => {
          return (
            <TableCell
              key={cell + i}
            >
              <TableSortLabel
                active={i === this.state.orderBy}
                direction={this.state.order}
                onClick={this.handleHeaderClick(i)}
              >
                {cell}
              </TableSortLabel>
            </TableCell>
          )
        })
        }
      </TableRow>
    )
  }

  getRows () {

    const data = this.state.rowData

    return data.map((row: ITableRow) => {
      const rowContent = row.content
      return (
        <TableRow key={row.id}>
          {rowContent.map((cell, index: number) => {
            return <TableCell key={(row.id + index)}>{cell}</TableCell>
          })}
        </TableRow>
      )
    })
  }

  render () {
    return (
      <div>
        {(this.props.title) ?
          <div>{this.props.title}</div>
          : null
        }
        <Table padding='none'>
          <TableHead>
            {this.getHeader()}
          </TableHead>
          <TableBody>
            {this.getRows()}
          </TableBody>
        </Table>
      </div>
    )
  }
}
