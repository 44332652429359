export default {
  colors: {
    colorBrandMain: '#2a00ab',
    colorBrandMainLight: '#aaa9ce',
    colorBrandAccent: '#f96332',
    colorBrandDark: '#0d0034',
    colorTransparent: '#0d003400',
    colorBrandTransparent: '#0d003455',
    colorGray: '#3a3a3a',
    colorGrayLight: '#68686899',
    colorGrayish: '#686868',
    colorGrayTransparent: '#3a3a3a33'
  }
}
