import { observable, decorate } from "mobx";

class DisplayConfigStore {

  private defaultMovieOnly = false
  private defaultShowIndividualStats = false
  private defaultShowGameStatsPage = false
  private defaultShowFilterComponent = true

  movieOnly: boolean = false
  showIndividualStats: boolean = false
  showGameStatsPage: boolean = false
  showFilterComponent: boolean = true
  showVideoPage: boolean = true
  showFieldMapPage: boolean = true

  setMovieOnly (v: boolean = this.defaultMovieOnly) {
    this.movieOnly = v
    this.setShowFilterComponent(!v)
  }

  setShowIndividual (v: boolean = this.defaultShowIndividualStats) {
    this.showIndividualStats = v
  }

  setGameStatsPage (v: boolean = this.defaultShowGameStatsPage) {
    this.showGameStatsPage = v
  }

  setShowFilterComponent (v: boolean = this.defaultShowFilterComponent) {
    this.showFilterComponent = v
  }

  setShowVideoPage (v: boolean = true) {
    this.showVideoPage = v
  }

  setShowFieldMapPage (v: boolean = true) {
    this.showFieldMapPage = v
  }
}

decorate(DisplayConfigStore, {
  movieOnly: observable,
  showIndividualStats: observable,
  showGameStatsPage: observable,
  showFilterComponent: observable,
  showVideoPage: observable,
  showFieldMapPage: observable,
})

const displayConfigState = new DisplayConfigStore()
export default displayConfigState
