import appState from "./AppStateStore";
import firebase from "firebase/app";
import recordingsState from "./RecordingStore";
import teamListState from "./TeamListStore";
import gameDetailsState from "./GameDetails";
import teamState from "./TeamStore";

firebase.auth().onAuthStateChanged(async (x) => {
  if (x === null) {
    appState.isLoggedIn = false

    recordingsState.clear()
    teamListState.clear()
    teamState.detach()
    gameDetailsState.detachAll()
    return
  }
  const userObject = await getUserObject(x.uid)
  if (!userObject)
    throw new Error("User not found")

  appState.user = {
    name: userObject.name,
    teams: userObject.teams,
    type: userObject.type,
  };

  appState.isLoggedIn = true
  localStorage.setItem('liveinsight-user', JSON.stringify(appState.user))
})

const user = localStorage.getItem('liveinsight-user')
if (user) {
  appState.isLoggedIn = true
  appState.user = JSON.parse(user)
}

async function getUserObject (uid: string) {
  const db = firebase.firestore();
  const settings = { timestampsInSnapshots: true }
  db.settings(settings)

  const userDoc = await db
    .collection("user")
    .doc(uid)
    .get()
  return (userDoc.data())
}

export class Authentication {

  static async logIn (username: string, password: string) {
    const response = await firebase
      .auth()
      .signInWithEmailAndPassword(username, password);
    if (!response.user || !response.user.email)
      throw new Error("LOGIN FAILED")

    const userObject = await getUserObject(response.user.uid);
    if (!userObject)
      throw new Error("User not found")


    appState.user = {
      name: userObject.name,
      teams: userObject.teams,
      type: userObject.type,
    };

    appState.isLoggedIn = true
  }

  static async logOut () {
    localStorage.removeItem('liveinsight-user')
    appState.isLoggedIn = false;
    recordingsState.clear()
    await firebase.auth().signOut();
  }
}
