import * as React from "react";

import Modal from './Modal';

import css from './ModalSimple.module.scss'

interface IButton {
  title: string,
  secondary?: boolean,
  handler: () => void
}

interface IProps {
  isOpen: boolean
  title?: string
  onClose: () => void
  styles?: object
  noScroll?: boolean
  padded?: boolean
  footerButtons?: IButton[]
}

class ModalSimple extends React.Component<IProps> {
  onClickOutside = () => {
    this.props.onClose()
  }

  render () {
    const {
      isOpen,
      ...rest
    } = this.props

    if (!isOpen) return null;

    return (
      <div className={css.background} onClick={this.onClickOutside}>
        <Modal
          {...rest}
        />
      </div>
    )
  }
}

export default ModalSimple
