import { observable, decorate } from "mobx";
import appState from "./AppStateStore";
import { context } from "./FirebaseConnection";
import { IRecording } from "./Recording"
import compareDesc from 'date-fns/compare_desc'

class RecordingStore {

  recordings: IRecording[]
  loading: boolean
  private internalUnsubscribe?: () => void


  constructor () {
    this.recordings = []
    this.loading = false
  }

  async subscribe () {

    if (this.internalUnsubscribe)
      return

    const userObject = appState.user
    if (!userObject) return;

    const userTeam = userObject.teams[0]
    const db = context.firestore
    this.loading = true

    let recordingsRef: firebase.firestore.Query;

    if (userObject.type === 'admin') {
      recordingsRef = db.collection(`${context.firestoreBase}/recordings`)
    } else {
      recordingsRef = db.collection(`${context.firestoreBase}/recordings`)
        .where('teams', 'array-contains', userTeam)
    }

    this.internalUnsubscribe = recordingsRef
      .onSnapshot(snapshot => {
        this.loading = false
        recordingStore.recordings = snapshot.docs
          .map(doc => {
            const data = doc.data()
            data.id = doc.id

            //TODO: remove if fixed in firebase
            if (!data.startTime) {
              data.startTime = data.startDate
            }

            if (!data.members) data.members = [];
            return data as IRecording
          })
          .filter(data => {
            if (data.isDeleted)
              return false
            return true
          })
          .sort((a, b) => {
            if (a.startTime && b.startTime) {
              return compareDesc(a.startTime, b.startTime)
            }
            return 0
          })
      })

  }

  async unsubscribe () {
    if (this.internalUnsubscribe) {
      this.loading = false
      this.internalUnsubscribe()
      delete this.internalUnsubscribe
    }
  }

  async createRecording (recording: IRecording) {
    const db = context.firestore
    try {
      await db.collection(`${context.firestoreBase}/recordings`).add(recording)
    } catch (err) {
      console.error("Error adding document: ", err);
    }
  }

  async updateRecording (recording: IRecording) {
    const db = context.firestore
    try {
      await db.collection(`${context.firestoreBase}/recordings`)
        .doc(recording.id)
        .update(recording)
    } catch (err) {
      console.error("Error updating document: ", err);
    }
  }

  async deleteRecording (id: string) {
    const db = context.firestore
    try {
      await db.collection(`${context.firestoreBase}/recordings`)
        .doc(id)
        .update({ isDeleted: true })
    } catch (err) {
      console.error("Error updating document: ", err);
    }
  }

  clear () {
    if (this.internalUnsubscribe) {
      this.internalUnsubscribe()
    }
    recordingStore.recordings = []
  }
}

decorate(RecordingStore, {
  recordings: observable,
  loading: observable,
})

const recordingStore = new RecordingStore()
export default recordingStore
