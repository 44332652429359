import * as React from "react";
import { START_TIME, END_TIME, RANGE_STEPS } from "../../config";
import InputRange, { Range } from "react-input-range";
import { ITimeRange } from "../../Types/Types";
import { t } from '../../localization/i18n'

import css from './RangeSlider.module.scss'

interface IProps {
  defaultRange: ITimeRange
  setRange: (range: ITimeRange) => void
}
interface IState {
  range: ITimeRange
  draggable: boolean
}

export default class RangeSlider extends React.PureComponent<IProps, IState> {
  state: IState = {
    range: { min: 0, max: 1 },
    draggable: true,
  };

  componentDidMount () {
    this.setState({
      range: {
        min: this.props.defaultRange.min,
        max: this.props.defaultRange.max,
      },
    })
  }

  updateRange = (range: ITimeRange) => {
    range.min = range.min <= START_TIME ? (range.min = START_TIME) : range.min;
    range.max = range.max >= END_TIME ? (range.max = END_TIME) : range.max;
    this.props.setRange(range);
    this.setState({ range, draggable: true });
  };

  verifyRange = (range: ITimeRange) => {
    if (range.max === range.min && range.min <= START_TIME) {
      range.max = range.min + RANGE_STEPS;
      range.min = START_TIME;
      this.setState({ draggable: false });
    } else if (range.max === range.min && range.max >= END_TIME) {
      range.min = range.max - RANGE_STEPS;
      range.max = END_TIME;
      this.setState({ draggable: false });
    } else if (range.max !== range.min) {
      this.setState({ draggable: true });
    } else {
      this.setState({ draggable: true });
    }
    this.updateRange(range);
  };

  selectAll = () => {
    this.updateRange({ min: START_TIME, max: END_TIME });
  };

  handleUpdateRange = (range: number | Range) => {
    this.updateRange(range as Range)
  }

  handleVerifyRange = (range: number | Range) => {
    this.verifyRange(range as Range)
  }

  render () {

    const inputClasses = {
      activeTrack: css.inputRangeTracksActive + ' input-range__track input-range__track--active',
      disabledInputRange: 'input-range input-range--disabled',
      inputRange: 'input-range',
      labelContainer: 'input-range__label-container',
      maxLabel: 'input-range__label input-range__label--max',
      minLabel: 'input-range__label input-range__label--min',
      slider: css.inputRangeSlider + ' input-range__slider',
      sliderContainer: 'input-range__slider-container',
      track: 'input-range__track input-range__track--background',
      valueLabel: 'input-range__label input-range__label--value',
    }

    return (
      <div className={css.rangeSliderOuterWrapper}>
        <div className={css.rangeSlider}>
          <InputRange
            classNames={inputClasses}
            allowSameValues={false}
            draggableTrack={this.state.draggable}
            minValue={START_TIME}
            maxValue={END_TIME}
            step={RANGE_STEPS}
            value={this.state.range}
            onChange={this.handleUpdateRange}
            onChangeComplete={this.handleVerifyRange}
          />
        </div>

        <div className={css.selectAll}>
          <a onClick={this.selectAll}>{t('filter.select_all')}</a>
        </div>
      </div>
    );
  }
}
