import * as React from 'react'

import { observer } from 'mobx-react'
import teamState from '../../../stores/TeamStore'

import { ITeamPlayer } from '../../../Types/Types'

import ModalSimple from '../../../Components/ModalSimple/ModalSimple'
import ImageUploader from '../../../Components/ImageUploader/ImageUploader'
import Autocomplete from '../../../Components/Autocomplete/Autocomplete'

import { POSITION_OPTIONS } from './config'

import { t } from '../../../localization/i18n'

import css from './PlayerModal.module.scss'
import Input from '../../../Components/Input/Input';
import ConfirmClose from '../../../Components/ConfirmClose/ConfirmClose';

interface IProps {
  isOpen: boolean
  onClose: () => void
  addPlayer?: (player: ITeamPlayer, photo: File | null) => void
  updatePlayer?: (
    playerId: string,
    player: ITeamPlayer,
    photo: File | null,
    removePhoto: boolean
  ) => void
  playerToEdit: ITeamPlayer | null
}

interface IState {
  fields: ITeamPlayer
  photo: File | null
  removePhoto: boolean
  errors: string[]
  isCloseModalOpen: boolean
  hasBeenEdited: boolean
}

const DEFAULT_FIELDS = {
  first_name: '',
  last_name: '',
  num: '',
  position: '',
}

const REQUIRED = ['first_name', 'last_name', 'num']

class PlayerModal extends React.Component<IProps, IState> {
  constructor (props: IProps) {
    super(props)
    this.state = this.getInitialState()
  }

  getInitialState = () => {
    return {
      fields: DEFAULT_FIELDS,
      photo: null,
      removePhoto: false,
      errors: [],
      isCloseModalOpen: false,
      hasBeenEdited: false,
    }
  }

  componentDidMount = () => {
    this.initializeEdit()
  }

  componentWillUpdate = (newProps: IProps) => {
    if (newProps.isOpen && !this.props.isOpen) {
      this.initializeEdit(newProps)
    }
  }

  toggleError = (key: string) => (hasError: boolean) => {
    const errors = this.state.errors

    if (hasError && errors.indexOf(key) === -1) {
      this.setState({ errors: errors.concat([key]) })
    } else {
      this.setState({ errors: errors.filter(err => err !== key) })
    }
  }

  initializeEdit = (props: IProps = this.props) => {
    const { playerToEdit } = props

    if (playerToEdit) {
      this.setState({
        fields: {
          first_name: playerToEdit.first_name || '',
          last_name: playerToEdit.last_name || '',
          num: playerToEdit.num || '',
          position: playerToEdit.position || '',
        },
      })
    } else {
      this.setState(this.getInitialState())
    }
  }

  onChangeField = (key: string) => (event: React.SyntheticEvent) => {
    const target = event.target as HTMLInputElement
    const value = target.value

    this.onChangeFieldValue(key)(value)
  }

  onBlur = (key: string) => () => {
    const valid = this.validate(key, this.state.fields[key])
    this.toggleError(key)(!valid)
  }

  onChangeFieldValue = (key: string) => (value: string) => {
    const fields = Object.assign({}, this.state.fields, { [key]: value })

    this.toggleError(key)(false)
    this.setState({ fields })
    this.setState({ hasBeenEdited: true })
  }

  onChangePhoto = (photo: File | null) => {
    this.setState({ photo, removePhoto: false })
  }

  onRemovePhoto = () => {
    this.setState({ photo: null, removePhoto: true })
  }

  validate = (key: string, value: string) => {
    switch (key) {
      case 'num':
        return !!value && this.getNumberOptions().indexOf(value) !== -1
      case 'position':
        return !!value && POSITION_OPTIONS.map(opt => opt.value).indexOf(value) !== -1
      default:
        return this.isRequired(key) ? !!value && value.length > 0 : true
    }
  }

  handleSubmit = () => {
    const { playerToEdit, updatePlayer, addPlayer } = this.props
    const { fields, photo } = this.state
    const errors: string[] = []

    Object.keys(fields).forEach((key: string) => {
      if (!this.validate(key, fields[key])) {
        errors.push(key)
      }
    })

    if (errors.length) {
      this.setState({ errors })
      return
    }

    if (playerToEdit && updatePlayer) {
      const { removePhoto } = this.state

      updatePlayer(
        playerToEdit.id || '',
        fields,
        photo,
        removePhoto
      )
    } else if (addPlayer) {
      addPlayer(fields, photo)
    }
    this.closePlayerModal()
  }

  closePlayerModal = () => {
    this.setState(this.getInitialState())
    this.props.onClose()
  }

  onClose = () => {
    if (this.state.hasBeenEdited) {
      this.setState({ isCloseModalOpen: true })
    } else {
      this.closePlayerModal()
    }
  }

  isRequired = (key: string) => REQUIRED.indexOf(key) !== -1

  hasError = (key: string) => {
    return this.state.errors.indexOf(key) !== -1
  }

  getNumberOptions = () => {
    const currentTeam = teamState.currentTeam

    let usedNumbers: any[] = []
    if (currentTeam && currentTeam.players && currentTeam.players.length) {
      usedNumbers = currentTeam.players
        .filter((p: ITeamPlayer) => !!p.num)
        .map((p: ITeamPlayer) => p.num)
    }

    const { playerToEdit } = this.props

    let ownNumber: string | undefined = ''
    if (playerToEdit && playerToEdit.num) {
      ownNumber = playerToEdit.num
    }

    const options: string[] = []
    for (let i = 0; i < 100; i++) {
      const si = String(i)
      if (usedNumbers.indexOf(si) === -1 || si === ownNumber) {
        options.push(si)
      }
    }

    return options
  }

  onConfirmCancelClose = () => {
    this.setState({ isCloseModalOpen: false })
  }

  onConfirmClose = () => {
    this.closePlayerModal()
  }

  renderImage = () => {
    let initialUrl: string | undefined

    const { playerToEdit } = this.props

    if (playerToEdit && playerToEdit.photo) {
      initialUrl = playerToEdit.photo
    }

    return (
      <div className={css.formImage}>
        <div className={css.imageWrapper}>
          <ImageUploader
            initial={initialUrl}
            onChange={this.onChangePhoto}
            onRemove={this.onRemovePhoto}
          />
        </div>
      </div>
    )
  }

  renderContent = () => {
    const { fields } = this.state

    const numberOptions = this.getNumberOptions()

    return (
      <div className={css.form}>
        {this.renderImage()}
        <div className={css.details}>
          <div className={css.formLine}>
            <div className={css.firstName}>
              <Input
                label={t('player.first_name')}
                value={fields.first_name}
                error={this.hasError('first_name')}
                onChangeNative={this.onChangeField('first_name')}
                onBlur={this.onBlur('first_name')}
                fullWidth
              />
            </div>

            <div className={css.lastName}>
              <Input
                label={t('player.last_name')}
                value={fields.last_name}
                error={this.hasError('last_name')}
                onChangeNative={this.onChangeField('last_name')}
                onBlur={this.onBlur('last_name')}
                fullWidth
              />
            </div>
          </div>
          <div className={css.formLine}>
            <div className={css.num}>
              <Autocomplete
                options={numberOptions.map((num: string) => ({
                  value: num,
                  label: num,
                }))}
                onSelect={this.onChangeFieldValue('num')}
                value={fields.num || ''}
                label={t('player.num')}
                error={this.hasError('num')}
                onError={this.toggleError('num')}
                fullWidth
              />
            </div>

            <div className={css.position}>
              <Autocomplete
                options={POSITION_OPTIONS}
                onSelect={this.onChangeFieldValue('position')}
                value={fields.position || ''}
                label={t('player.position')}
                error={this.hasError('position')}
                onError={this.toggleError('position')}
                fullWidth
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  getFooterButtons = () => {
    return [
      { title: t('common.save'), handler: this.handleSubmit, disabled: this.state.errors.length },
    ]
  }

  render () {
    const { isOpen, playerToEdit } = this.props

    const title = t(`player.${playerToEdit ? 'edit' : 'add'}`)

    return (
      <div>
        <ModalSimple
          isOpen={isOpen}
          onClose={this.onClose}
          title={title}
          footerButtons={this.getFooterButtons()}
          styles={{ width: '580px' }}
        >
          {this.renderContent()}
        </ModalSimple>
        <ConfirmClose
          isOpen={this.state.isCloseModalOpen}
          onConfirm={this.onConfirmClose}
          onCancel={this.onConfirmCancelClose}
        />
      </div>
    )
  }
}

export default observer(PlayerModal)
