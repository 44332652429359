import * as React from "react";
import { Link } from "react-router-dom";

import css from './ListItemCard.module.scss'

interface IProps {
  to: string
  img?: string
  title: string
  label?: string
  date?: string
}

const DEFAULT_IMG = "https://firebasestorage.googleapis.com/v0/b/liveinsight-de5ef.appspot.com/o/match_aik.jpg?alt=media&token=010b0e43-1fe2-442f-a1d9-851f9c6f1f6e"

const ListItemCard = ({
  to,
  img,
  title,
  label,
  date,
}: IProps) => {
  const labelComponent = label ? <span className={css.label}>{label}</span> : null;
  const dateComponent = date ? <div style={{ marginTop: '1em' }}>{date}</div> : null;

  const imageSource = img || DEFAULT_IMG;

  return (
    <Link className={css.container} to={to}>
      <img className={css.image} src={imageSource} alt="thumbnail" />
      <div className={css.infoContainer}>
        <span className={css.textTitle}>
          {title}
        </span>
        {dateComponent}
        {labelComponent}
      </div>
    </Link>
  );
}

export default ListItemCard;
