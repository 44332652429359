import { observable, decorate } from "mobx";

class SidebarStore {
  sidebar: boolean = false

  showSidebar () {
    this.sidebar = true
  }

  hideSidebar () {
    this.sidebar = false
  }
}
decorate(SidebarStore, {
  sidebar: observable,
})

const sidebarState = new SidebarStore()
export default sidebarState

