import i18n from "i18next";

import en from './locales/en';
// import sv from './locales/sv';

i18n
  // .use(LanguageDetector)
  .init({
    resources: {
      en: { translations: en },
      // sv: {translations: sv}
    },
    whitelist: ['en'], //, 'sv'],
    fallbackLng: "en",
    debug: true,

    ns: ["translations"],
    defaultNS: "translations",

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ",",
    },

    react: {
      wait: true,
    },
  });

export const t = i18n.t.bind(i18n)
export default i18n;
