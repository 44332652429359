import stateData from '../assets/nchan_output.json'
import { decorate, observable } from "mobx";
import { IStats, IPlayerStats } from '../Types/Types.js';
import gameDetailsState from './GameDetails';

const INPUT: 'live' | 'saved' = 'live'

class LiveStatsStore {

  NchanSubscriber = require('nchan')

  urlPath = 'https://livedata.signality.com/'
  opt = { subscriber: ['websocket', 'longpoll', 'eventsource'] }
  sub = new this.NchanSubscriber()
  subscribing: boolean = false

  currentHomeGameStats?: IStats
  currentAwayGameStats?: IStats
  currentHomePlayerStats: IPlayerStats[]
  currentAwayPlayerStats: IPlayerStats[]
  currentTopDistanceHomePlayer?: IPlayerStats
  currentTopDistanceAwayPlayer?: IPlayerStats

  constructor () {
    this.currentHomePlayerStats = []
    this.currentAwayPlayerStats = []
  }

  subscribeToStats () {

    if (INPUT === 'live') {
      if (!gameDetailsState.currentGame || !gameDetailsState.currentGame.executionId) return

      const executionId = gameDetailsState.currentGame.executionId
      const url = this.urlPath + executionId
      // const url = 'https://livedata.signality.com/0ffc3db0-5acf-11e9-a0b9-45af27b3618d' //Test channel
      this.sub = new this.NchanSubscriber(url, this.opt);
      this.sub.reconnectTimeout = 1500

      this.sub.on("message", (message: string) => {
        const messageObj = JSON.parse(message)
        if (messageObj.state === 'end') {
          console.log('end subscription')
          this.sub.stop()
        } else {
          this.setGameStats(messageObj)
        }
      });
      this.sub.on('connect', (evt: any) => {
        console.log('connect')
        this.subscribing = true
      });
      this.sub.on('disconnect', (evt: any) => {
        console.log('disconnecting')
        this.subscribing = false
      });
      this.sub.on('error', (evt: any) => { console.log('error', evt.message) });
      this.sub.reconnect = true

      this.sub.start()

    } else {
      this.getLocalStats()
    }
  }

  unsubscribe () {
    if (this.subscribing)
      this.sub.stop()
    this.clearAll()
  }

  getLocalStats () {
    let i = 0
    window.setInterval(() => {
      const data = stateData
      this.setGameStats(data)
      i++
      if (i > 1000)
        clearInterval()
    }, 1500)
  }

  setGameStats = (messageObj: any) => {

    const phase = messageObj.phase
    const match_time = messageObj.match_time

    this.setTeamStats(messageObj.stats.home_team, 'home_team', phase, match_time)
    this.setTeamStats(messageObj.stats.away_team, 'away_team', phase, match_time)
  }

  setTeamStats = (teamStatsObj: any, team: 'home_team' | 'away_team', phase: number, match_time: number) => {
    if (!teamStatsObj) return
    const playerStats: IPlayerStats[] = []

    let totalDistance = 0
    let totalSprints = 0
    let totalHighspeedRuns = 0
    let totalDecelerations = 0
    let totalAccelerations = 0
    let totalSuccessfulPasses = 0
    let totalBallWon = 0
    let totalBallLost = 0


    let topDist = 0
    let topDistPlayer = null
    for (const player of teamStatsObj.player_stats) {
      player.id = team + player.track_id
      if (player.distance > topDist) {
        topDistPlayer = player
        topDist = player.distance
      }

      totalDistance += player.distance
      totalSprints += player.sprints
      totalHighspeedRuns += player.hi_runs
      totalDecelerations += player.decelerations
      totalAccelerations += player.accelerations
      totalSuccessfulPasses += player.successful_passes
      totalBallWon += player.ball_won
      totalBallLost += player.ball_lost

      playerStats.push(player as IPlayerStats)
    }

    const teamStats: IStats = {
      phase,
      match_time,
      team: 'home_team',
      possession: teamStatsObj.possession,
      distance: totalDistance,
      sprints: totalSprints,
      highspeedRuns: totalHighspeedRuns,
      decelerations: totalDecelerations,
      accelerations: totalAccelerations,
      successfulPasses: totalSuccessfulPasses,
      ballWon: totalBallWon,
      ballLost: totalBallLost,
    }

    this.updateGameStats(team, teamStats, playerStats, topDistPlayer)
  }

  updateGameStats = (
    team: 'home_team' | 'away_team',
    teamStats: IStats,
    playerStats: IPlayerStats[],
    topDistPlayer: IPlayerStats) => {

    if (team === 'home_team') {
      this.currentHomeGameStats = teamStats
      this.currentHomePlayerStats = playerStats
      this.currentTopDistanceHomePlayer = topDistPlayer
    } else {
      this.currentAwayGameStats = teamStats
      this.currentAwayPlayerStats = playerStats
      this.currentTopDistanceAwayPlayer = topDistPlayer
    }
  }

  clearAll () {
    this.currentHomeGameStats = undefined
    this.currentAwayGameStats = undefined
    this.currentHomePlayerStats = []
    this.currentAwayPlayerStats = []
    this.currentTopDistanceHomePlayer = undefined
    this.currentTopDistanceAwayPlayer = undefined
    this.subscribing = false
  }
}

decorate(LiveStatsStore, {
  currentHomeGameStats: observable,
  currentAwayGameStats: observable,
  currentHomePlayerStats: observable,
  currentAwayPlayerStats: observable,
  subscribing: observable,
  currentTopDistanceHomePlayer: observable,
  currentTopDistanceAwayPlayer: observable,
})

const liveStatsSate = new LiveStatsStore()
export default liveStatsSate
