import { observable, decorate, action } from "mobx";
import { context } from "./FirebaseConnection";
import { IRecording } from "./Recording";
import { PhaseNumber, IEventMovements, TeamSelection, IGameEvent, IPassEvent, IGameEventV0, IBallWinEvent, IPhaseInfo, IJerseyNumbers, IVideoInfo, TracksInfo, IBallFrame } from "../Types/Types";
import firebase from 'firebase/app'
import { compareAsc } from "date-fns";
import displayConfigState from "./DisplayConfigStore";

class Phase {
  homeSuccessfulPasses: IPassEvent[] = []
  awaySuccessfulPasses: IPassEvent[] = []
  homeFailedPasses: IPassEvent[] = []
  awayFailedPasses: IPassEvent[] = []
  homeBallWins: IBallWinEvent[] = []
  awayBallWins: IBallWinEvent[] = []
}

decorate(Phase, {
  homeSuccessfulPasses: observable,
  awaySuccessfulPasses: observable,
  homeFailedPasses: observable,
  awayFailedPasses: observable,
  homeBallWins: observable,
  awayBallWins: observable,
})

export function isPassEvent (event: IGameEvent): event is IPassEvent {
  return !!event && (event.type === 'pass' || event.type === 'interception')
}

export function isBallWinEvent (event: IGameEvent): event is IBallWinEvent {
  return !!event && event.type === 'ball_win'
}

class GameDetailsStore {

  currentGame?: IRecording
  currentPhase?: IPhaseInfo
  activePassObject?: IPassEvent
  eventMovements?: IEventMovements
  jerseyNumbers?: IJerseyNumbers | null
  video?: IVideoInfo | null
  tracks?: TracksInfo | null

  private detachGameHeader: (() => void) | null
  private detachEvents: (() => void) | null

  constructor () {
    this.detachGameHeader = null
    this.detachEvents = null
  }

  @action async getCurrentGame (id: string) {
    const db = context.firestore

    return new Promise((res, rej) => {
      if (this.currentGame && this.currentGame.id === id) {
        return res(this.currentGame)
      }

      this.detachGameHeader = db.doc(`${context.firestoreBase}/recordings/${id}`).onSnapshot(snapshot => {
        const gameData = snapshot.data()
        this.currentGame = { id: snapshot.id, ...gameData }
        displayConfigState.setMovieOnly(this.currentGame.video_only)
        displayConfigState.setShowIndividual(this.currentGame.show_individual_stats)
        if (this.currentGame.state === 'running') {
          displayConfigState.setGameStatsPage(true)//this.currentGame.state === 'running')
          displayConfigState.setShowFilterComponent(false)
          displayConfigState.setShowVideoPage(false)
          displayConfigState.setShowFieldMapPage(false)
        } else {
          displayConfigState.setGameStatsPage(false)//this.currentGame.state === 'running')
          displayConfigState.setShowVideoPage(true)
          displayConfigState.setShowFieldMapPage(true)
        }
        if (this.currentGame.eventVersion === 1 || this.currentGame.eventVersion === 2) {
          this.getStartEventsV1(id)
        } else {
          this.getStartEventsV0(id)
        }
        // gameStatsState.getGameStats()
        res(this.currentGame)
      })
    })
  }

  @action async getPhaseInfo (id: string, phase: PhaseNumber) {
    const db = context.firestore
    const doc = await db.doc(`${context.firestoreBase}/recordings/${id}/data/stats`).get()

    const data = doc.data()
    if (data) {
      const phaseInfo = data[`phase${phase}`]
      phaseInfo.duration = phaseInfo.match_time
      this.currentPhase = phaseInfo as IPhaseInfo
    }
  }

  @action async getJerseyNumbers (id: string) {
    const db = context.firestore
    const doc = await db.doc(`${context.firestoreBase}/recordings/${id}/data/jersey-numbers`).get()
    const jerseyNumbers = doc.data()
    if (!jerseyNumbers) {
      this.jerseyNumbers = null
      return
    }
    this.jerseyNumbers = jerseyNumbers as IJerseyNumbers
  }

  @action async getVideo (id: string) {
    const db = context.firestore
    const doc = await db.doc(`${context.firestoreBase}/recordings/${id}/data/video`).get()
    const video = doc.data()
    if (!video) {
      this.video = null
      return
    }
    video.calibration = JSON.parse(video.calibration)
    this.video = video as IVideoInfo
  }

  @action async getTracks (id: string) {
    const jsonRef = context.storage.ref(`recordings/${id}/auto-follow-camera.json`)

    if (!jsonRef) {
      this.tracks = null
      return
    }

    try {
      const url = await jsonRef.getDownloadURL()
      const tracks = await fetch(url)
      const json = await tracks.json()
      this.tracks = json as TracksInfo
    } catch (err) {
      console.error(err)
    }
  }

  private convertEventToRecentVersion (event: IGameEventV0) {
    const {
      type,
      phase,
      time,
      stream_times,
      team,
      ...rest
    } = event

    const newEvent: IGameEvent = {
      type: type === 'failed_pass' ? 'interception' : type,
      phase: phase || 1, //???
      start_match_time: time,
      start_stream_time: stream_times || [],
      from_team: team === 'home_team' ? 0 : 1,
      initialVersion: 0,
      ...rest,
    }

    return newEvent
  }

  async getEvents (
    id: string,
    phase: PhaseNumber,
    type: string,
    team: TeamSelection,
    minTime: number,
    maxTime: number,
    jerseyNumber: number,
    onUpdate: (events: IGameEvent[]) => void
  ) {

    const currentGame = await this.getCurrentGame(id) as IRecording

    let newMaxTime = maxTime
    if (maxTime >= 2700000)
      newMaxTime = maxTime * 1000000

    if (currentGame && currentGame.id === id) {
      if (currentGame.eventVersion === 1 || currentGame.eventVersion === 2) {
        return this.getEventsV1(id, phase, type, team, minTime, newMaxTime, jerseyNumber, onUpdate)
      }
      const teamStr = team === 0 ? 'home_team' : 'away_team'
      return this.getEventsV0(id, phase, type, teamStr, minTime, newMaxTime, onUpdate)
    }
  }

  private async getEventsV1 (
    id: string,
    phase: PhaseNumber,
    type: string,
    team: TeamSelection,
    minTime: number,
    maxTime: number,
    jerseyNumber: number,
    onUpdate: (events: IGameEvent[]) => void
  ) {
    const teamStr = team === 0 ? 'home_team' : 'away_team'
    const db = context.firestore
    const path = `${context.firestoreBase}/recordings/${id}/events`
    let eventsRef: firebase.firestore.Query

    if (jerseyNumber !== -1) {
      const teamPrefix = team === 0 ? 'home-' : 'away-'

      eventsRef = db.collection(path)
        .where('phase', '==', +phase)
        .where('live_insight.jersey_numbers', 'array-contains', (teamPrefix + jerseyNumber))
        .where('type', '==', type)
        .where('start_match_time', '>=', minTime)
        .where('start_match_time', '<', maxTime)

      const ballWins = await this.getBallWinEvents(eventsRef, teamStr)
      const others = await this.getOtherEvents(eventsRef, teamStr)
      const events = ballWins.concat(others).sort((a, b) => compareAsc(a.start_match_time, b.start_match_time))

      onUpdate(events)
    } else {
      eventsRef = db.collection(path)
        .where('phase', '==', +phase)
        .where('type', '==', type)
        .where('start_match_time', '>=', minTime)
        .where('start_match_time', '<', maxTime)

      if (type === 'ball_win') {
        const events = await this.getBallWinEvents(eventsRef, teamStr)
        onUpdate(events)
      } else {
        const events = await this.getOtherEvents(eventsRef, teamStr)
        onUpdate(events)
      }
    }
  }

  private async getStartEventsV1 (
    id: string
  ) {
    const db = context.firestore
    const path = `${context.firestoreBase}/recordings/${id}/events`

    const startTimes: [number, number] = [0, 0]
    db.collection(path)
      .where('type', '==', 'start')
      .get()
      .then(snapshot => {
        snapshot.forEach((doc) => {
          let data = doc.data()
          data = { id: doc.id, ...data }
          startTimes[(data.phase - 1)] = data.stream_time[0] / 1000
        })
        this.currentGame = {
          id,
          start_stream_times: startTimes,
          ...this.currentGame,
        }
      })
  }

  private async getStartEventsV0 (id: string) {
    const startTimes = [0, 0]
    this.currentGame = {
      id,
      start_stream_times: startTimes,
      ...this.currentGame,
    }
  }

  private async getBallWinEvents (ref: firebase.firestore.Query, teamStr: string) {
    const docs: IGameEvent[] = []

    await ref
      .where('team', '==', teamStr)
      .get()
      .then(snapshot => {
        snapshot.forEach((doc) => {
          let data = doc.data()
          data = { id: doc.id, ...data }

          docs.push(data as IGameEvent)
        })
      })

    return docs
  }

  private async getOtherEvents (ref: firebase.firestore.Query, teamStr: string) {
    const docs: IGameEvent[] = []

    await ref
      .where('live_insight.from_team', '==', teamStr)
      .get()
      .then(snapshot => {
        snapshot.forEach((doc) => {
          let data = doc.data()
          data = {
            id: doc.id,
            ...data,
            from_team: data.live_insight.from_team === 'home_team' ? 0 : 1,
          }

          if (!data.false_positive)
            docs.push(data as IGameEvent)
        })
      })

    return docs
  }

  private async getEventsV0 (
    id: string,
    phase: PhaseNumber,
    type: string,
    team: 'home_team' | 'away_team',
    minTime: number,
    maxTime: number,
    onUpdate: (events: IGameEvent[]) => void
  ) {
    // async getEvents (id: string, phase: PhaseNumber, type: string, team: ('home_team' | 'away_team'), minTime: number, maxTime: number, onUpdate: (events: IGameEvent[]) => void) {
    const db = context.firestore
    const path = `${context.firestoreBase}/recordings/${id}/events`
    const eventsRef = db.collection(path).where('phase', '==', +phase)

    this.detachEvents = eventsRef
      .where('type', '==', type === 'interception' ? 'failed_pass' : type)
      .where('team', '==', team)
      .where('time', '>=', minTime)
      .where('time', '<', maxTime)
      .onSnapshot(snapshot => {
        const events = snapshot.docs.map(doc => {
          let data = doc.data()
          data = { id: doc.id, ...data }

          return this.convertEventToRecentVersion(data as IGameEventV0)
        })

        onUpdate(events)
      })
  }

  async saveGameReport (
    report: File | null,
    gameId: string
  ) {
    const db = context.firestore
    const storage = context.storage

    if (report) {
      console.log('Uploading report...')
      const reportPath = `reports/games/${gameId}/${report.name}`
      const storageRef = storage.ref().child(reportPath)

      await storageRef.put(report)
      const reportUrl = await storageRef.getDownloadURL()

      await db
        .collection(`${context.firestoreBase}/recordings/`)
        .doc(gameId)
        .set({ report_url: reportUrl }, { merge: true })
        .then(file => {
          console.log('Report upload DONE')
        }
        )
    }
  }

  @action clearActiveEvent () {
    if (this.activePassObject) {
      this.activePassObject = undefined
    }

    if (this.eventMovements) {
      this.eventMovements = undefined
    }
  }

  @action async fetchEvent (id: string, eventId: string) {
    const path = `${context.firestoreBase}/recordings/${id}/events/${eventId}`
    const db = context.firestore
    const doc = await db.doc(path).get()
    const data = { id: doc.id, ...doc.data() }

    if (this.currentGame && this.currentGame.id === id) {
      if (this.currentGame.eventVersion === 1 || this.currentGame.eventVersion === 2) {
        this.activePassObject = data as IPassEvent
      }

      this.activePassObject = this.convertEventToRecentVersion(data as IGameEventV0) as IPassEvent
    }
  }

  @action async fetchEventMovements (id: string, eventId: string) {
    const path = `${context.firestoreBase}/recordings/${id}/events/${eventId}/players/players`

    const db = context.firestore
    const doc = await db.doc(path).get()
    this.eventMovements = doc.data() as IEventMovements
  }

  async fetchTracks (id: string, phase: (1 | 2), onUpdate: (frames: Map<number, IBallFrame>) => void) {
    const ref = firebase.storage().ref(`${id}/ball.${id}.${phase}.tracks`)
    const httpsReference = await ref.getDownloadURL()
    const data = await fetch(httpsReference)
    const ab = await data.arrayBuffer()
    const view = new DataView(ab)
    view.getUint32(0) // version


    let index = 4
    const frames = new Map<number, IBallFrame>()
    while (index < view.byteLength) {
      const ballFound = !!view.getUint8(index); index += 1
      const matchTime = view.getUint32(index); index += 4
      const ballX = view.getFloat32(index); index += 4
      const ballY = view.getFloat32(index); index += 4
      frames.set(matchTime, { matchTime, found: ballFound, x: ballX, y: ballY })
    }
    onUpdate(frames)
  }

  @action clear () {
    this.clearActiveEvent()
    this.currentGame = undefined
    this.currentPhase = undefined
    this.video = undefined
    this.tracks = undefined
    this.jerseyNumbers = undefined
  }

  detachAll () {
    if (this.detachGameHeader) {
      this.detachGameHeader()
    }
    if (this.detachEvents) {
      this.detachEvents()
    }

    this.clear()
  }
}

decorate(GameDetailsStore, {
  currentGame: observable,
  currentPhase: observable,
  activePassObject: observable,
  eventMovements: observable,
  video: observable,
  tracks: observable,
  jerseyNumbers: observable,
})

const gameDetailsState = new GameDetailsStore()
export default gameDetailsState
