import { NO_OF_ZONE_COLUMNS, NO_OF_ZONE_ROWS } from "../../config";
import { Position, GridType } from '../../Types/Types'
import gridConfig from './gridConfig'
type Vec2 = [number, number]

/*
USED TO CALCULATE COMMON FUNCTIONS REGARDING ZONES
*/

const playground = {
  name: "PlayGround",
  limits: { from: 0, to: 1 },
  coordinates: {
    top: {
      left: { x: 0, y: 0 },
      right: { x: 1, y: 0 },
    },
    bottom: {
      left: { x: 0, y: 1 },
      right: { x: 1, y: 1 },
    },
  },
};

export interface IZone {
  zoneName: number,
  coordinates: {
    top: {
      left: { x: number, y: number },
      right: { x: number, y: number }
    },
    bottom: {
      left: { x: number, y: number },
      right: { x: number, y: number }
    }
  }
}

function BuildZone (
  zoneNumber: number,
  noOfColumns: number,
  noOfRows: number,
  widthPerZone: number,
  heightPerZone: number
) {
  const zone: IZone = {
    zoneName: -99,
    coordinates: {
      top: {
        left: { x: -99, y: -99 },
        right: { x: -99, y: -99 },
      },
      bottom: {
        left: { x: -99, y: -99 },
        right: { x: -99, y: -99 },
      },
    },
  };
  let rowNumber = 1;
  let columnNumber = 1;
  if (zoneNumber > noOfColumns) {
    // figure out what row this zone blongs to
    rowNumber = Math.ceil(zoneNumber / noOfColumns);
    // figure out what column this zone belongs to
    columnNumber = zoneNumber - (rowNumber - 1) * noOfColumns;
  } else {
    columnNumber = zoneNumber;
  }
  zone.zoneName = zoneNumber;
  zone.coordinates.bottom.left.x = widthPerZone * (columnNumber - 1);
  zone.coordinates.bottom.left.y = heightPerZone * (rowNumber - 1);
  zone.coordinates.bottom.right.x = widthPerZone * columnNumber;
  zone.coordinates.bottom.right.y = heightPerZone * (rowNumber - 1);
  zone.coordinates.top.left.x = widthPerZone * (columnNumber - 1);
  zone.coordinates.top.left.y = heightPerZone * rowNumber;
  zone.coordinates.top.right.x = widthPerZone * columnNumber;
  zone.coordinates.top.right.y = heightPerZone * rowNumber;

  return zone;
};

function BuildZoneCoords (
  zoneNumber: number,
  topLeft: Vec2,
  topRight: Vec2,
  bottomLeft: Vec2,
  bottomRight: Vec2
) {
  const zone: IZone = {
    zoneName: -99,
    coordinates: {
      top: {
        left: { x: -99, y: -99 },
        right: { x: -99, y: -99 },
      },
      bottom: {
        left: { x: -99, y: -99 },
        right: { x: -99, y: -99 },
      },
    },
  };

  zone.zoneName = zoneNumber;
  zone.coordinates.bottom.left.x = bottomLeft[0]
  zone.coordinates.bottom.left.y = bottomLeft[1]
  zone.coordinates.bottom.right.x = bottomRight[0]
  zone.coordinates.bottom.right.y = bottomRight[1]
  zone.coordinates.top.left.x = topLeft[0]
  zone.coordinates.top.left.y = topLeft[1]
  zone.coordinates.top.right.x = topRight[0]
  zone.coordinates.top.right.y = topRight[1]

  return zone;
};

const getZoneDimensionsCorridors = (width: number, length: number) => {
  const penaltyAreaLength = gridConfig.penalty.length
  const halfGoalAreaWidth = gridConfig.goal.width / 2
  const halfPenaltyAreaWidth = gridConfig.penalty.width / 2

  const halfWidth = width / 2
  const halfLength = length / 2

  const vertPoints = [
    0,
    penaltyAreaLength,
    length / 3,
    halfLength,
    length * 2 / 3,
    length - penaltyAreaLength,
    length,
  ].map((p) => (p / length))

  const horPoints = [
    0,
    halfWidth - halfPenaltyAreaWidth,
    halfWidth - halfGoalAreaWidth,
    halfWidth + halfGoalAreaWidth,
    halfWidth + halfPenaltyAreaWidth,
    width,
  ].map((p) => (p / width))

  //let zoneCordinates = [];
  const zones: IZone[] = [];

  const columns = horPoints.length - 1
  const rows = vertPoints.length - 1

  for (let i = 0; i < columns; i++) {
    for (let j = 0; j < rows; j++) {
      const num = (i + 1) + j * columns
      const bottomLeft = [horPoints[i], vertPoints[j]] as Vec2
      const topLeft = [horPoints[i], vertPoints[j + 1]] as Vec2
      const bottomRight = [horPoints[i + 1], vertPoints[j]] as Vec2
      const topRight = [horPoints[i + 1], vertPoints[j + 1]] as Vec2

      zones.push(
        BuildZoneCoords(num, topLeft, topRight, bottomLeft, bottomRight)
      );
    }
  }
  const sorted = zones.sort((a, b) => a.zoneName - b.zoneName);
  return sorted
};

const getZoneDimensionsThreeBySix = () => {
  const noOfColumns = NO_OF_ZONE_COLUMNS;
  const noOfRows = NO_OF_ZONE_ROWS;
  if (noOfColumns < 2 || noOfRows < 2) {
    console.error(
      "ZoneUtil Error: ERROR:NO OF COLUMNS SHOULD BE > 2 && NO OF ROWS SHOULD BE > 2"
    );
    return;
  }
  //let zoneCordinates = [];
  const widthPerZone = playground.coordinates.top.right.x / noOfColumns;
  const heightPerZone = playground.coordinates.bottom.left.y / noOfRows;
  const totalNoOfZones = noOfColumns * noOfRows;
  const zones: IZone[] = [];
  for (let i = 0; i < totalNoOfZones; i++) {
    zones.push(
      BuildZone(i + 1, noOfColumns, noOfRows, widthPerZone, heightPerZone)
    );
  }
  return zones;
};

export const getZoneDimensions = (
  gridType: GridType = GridType.Corridors,
  width: number = 68,
  length: number = 105
) => {
  switch (gridType) {
    case GridType.Corridors:
      return getZoneDimensionsCorridors(width, length);
    case GridType.ThreeBySix:
      return getZoneDimensionsThreeBySix();
    default:
      return null;
  }
}

export const findMyZone = (zones: IZone[], position: Position) => {
  const x = position[0];
  const y = position[1];
  let myZone = -999;
  zones!.forEach(zone => {
    if (
      x >= zone.coordinates.top.left.x &&
      x <= zone.coordinates.top.right.x &&
      y >= zone.coordinates.bottom.left.y &&
      y <= zone.coordinates.top.left.y
    ) {
      myZone = zone.zoneName;
    }
  });
  return myZone;
};
