export const POSITION_OPTIONS = [
  //Defenders
  'CB',
  'RB',
  'LB',
  'LWB',
  'RWB',

  //Midfielders
  'DM',
  'CM',
  'CAM',
  'CDM',
  'RW',
  'LW',
  'RM',
  'LM',

  //Forwards
  'CF',
  'LF',
  'RF',
  'ST',

  //Goalkeeper
  'GK',
].map((p: string) => ({value: p, label: p}))

