import React, { useState } from "react";
import { Link } from "react-router-dom";
import format from 'date-fns/format';
import { IRecording } from "../../stores/Recording";
import { ITeam } from "../../Types/Types";
import Autocomplete from "../Autocomplete/Autocomplete";
import teamListState from "../../stores/TeamListStore";
import recordingStore from "../../stores/RecordingStore";
import css from "./AdminGameListItem.module.scss"

interface IProps {
  game: IRecording
  allTeams: ITeam[]
}

const AdminGameListItem = ({ game, allTeams }: IProps) => {
  const [selectedTeam, setSelectedTeam] = useState('')

  const teamOptions = teamListState.allTeams
    ? allTeams.map((team: ITeam) => ({
      value: team.id,
      label: team.name,
    }))
    : []
  const date = game.startTime ? format(game.startTime, 'MMM D, YYYY') : 'Date unknown'
  const home = game.home_team ? game.home_team.name : 'Home team unknown'
  const away = game.away_team ? game.away_team.name : 'Away team unknown'
  const title = home + ' - ' + away

  const teams = game.teams ? game.teams.map(teamId => {
    const team = teamListState.getTeam(teamId)
    return team ? <p>{team.name}</p> : null
  }) : null

  const onAddTeam = () => {
    if (game.teams) {
      if (game.teams.find(team => {
        return team === selectedTeam
      })) {
        return
      } else {
        game.teams.push(selectedTeam)
      }
    }
    else {
      game.teams = [selectedTeam]
    }
    recordingStore.updateRecording(game)
  }

  const onRemoveTeam = () => {
    if (game.teams) {
      game.teams = game.teams.filter(data => {
        return data !== selectedTeam
      })
      recordingStore.updateRecording(game)
    }
  }

  const handleTeamSelected = (value: string) => {
    setSelectedTeam(value)
  }

  const addTeamForm = (

    <div className={css.teamForm}>
      <Autocomplete
        options={teamOptions}
        onSelect={handleTeamSelected}
        value={teamOptions[0] ? teamOptions[0].label : ''}
        label={'Team'}
        fullWidth
        small
        secondary
      />
      <button onClick={onAddTeam}>Add Team</button>
      <button onClick={onRemoveTeam}>Remove Team</button>
    </div>
  )

  return (
    <div className={css.row}>
      <div className={css.date}>{date}</div>
      <div className={css.title}>
        <Link to={`/games/${game.id}`}>{title}</Link>
        <div><span>Execution id:</span><span>{game.executionId}</span></div>
        <div><span>State:</span>{game.state}</div>
      </div>
      <div className={css.teams}>{teams}</div>
      {addTeamForm}
    </div>
  )
}

export default AdminGameListItem
