import React, { Component } from 'react'
import Icon from '../Icon/Icon'
import { IconName } from '../../Types/Types'
import cx from 'classnames'
import css from './Input.module.scss'

interface IProps {
  value: string
  placeholder?: string
  label?: string
  type?: string
  disabled?: boolean
  error?: boolean
  small?: boolean
  secondary?: boolean
  fullWidth?: boolean
  icon?: IconName
  iconSuffix?: IconName
  inputRef?: React.RefObject<HTMLInputElement>
  onChange?: (value: string) => void
  onChangeNative?: (evt: React.SyntheticEvent) => void
  onBlur?: (evt: React.SyntheticEvent) => void
}

export class Input extends Component<IProps> {
  onChange = (evt: React.SyntheticEvent) => {
    const { onChange, onChangeNative, disabled } = this.props
    const target = evt.target as HTMLInputElement
    const value = target.value

    if (onChange && !disabled) {
      onChange(value)
    }
    if (onChangeNative && !disabled) {
      onChangeNative(evt)
    }
  }

  render () {
    const {
      value,
      placeholder,
      label,
      disabled,
      small,
      secondary,
      fullWidth,
      icon,
      iconSuffix,
      type,
      error,
      inputRef,
      onChange,
      onChangeNative,
      onBlur,
      ...rest
    } = this.props

    const classes = cx(css.wrapper, {
      [css.labeled]: !!label,
      [css.iconed]: !!icon,
      [css.withSuffix]: !!iconSuffix,
      [css.disabled]: !!disabled,
      [css.fullWidth]: !!fullWidth,
      [css.small]: !!small,
      [css.secondary]: !!secondary,
      [css.error]: !!error,
    })

    const labelComponent = label
      ? (<label className={css.label}>{label}</label>)
      : null

    const iconComponent = icon
      ? (
        <div className={css.icon}>
          <Icon icon={icon} />
        </div>
      )
      : null

    const suffixComponent = iconSuffix
      ? (
        <div className={css.iconSuffix}>
          <Icon icon={iconSuffix} />
        </div>
      )
      : null

    return (
      <div className={classes}>
        {iconComponent}
        {suffixComponent}
        {labelComponent}
        <input
          ref={inputRef}
          type={type || 'text'}
          onChange={this.onChange}
          onBlur={onBlur}
          className={css.input}
          value={value}
          placeholder={placeholder}
          {...rest}
        />
      </div>
    )
  }
}

export default Input
