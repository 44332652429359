import { context } from './FirebaseConnection'
import { ITeamPlayer } from '../Types/Types';

class GamePlayerStore {
  private detachTeamPlayers: (() => void) | null

  constructor () {
    this.detachTeamPlayers = null
  }

  async getGamePlayers (gameId: string, teamId: string, onUpdate: (players: ITeamPlayer[]) => void) {
    const db = context.firestore
    const path = `${context.firestoreBase}/recordings/${gameId}/teams/${teamId}/players`
    const playersRef = db.collection(path)

    this.detachTeamPlayers = playersRef
      .onSnapshot(snapshot => {
        const players = snapshot.docs.map(doc => {
          const data = doc.data()
          data.id = doc.id
          return data as ITeamPlayer
        })
        onUpdate(players)
      })
  }

  async saveGamePlayers (gameId: string, teamId: string, players: ITeamPlayer[]) {
    const db = context.firestore
    const path = `${context.firestoreBase}/recordings/${gameId}/teams/${teamId}/players`
    const playersRef = db.collection(path)

    try {
      players.forEach(player => {
        if (player.selected) {
          if (playersRef.doc(player.id)) {
            playersRef.doc(player.id).set(player)
          } else {
            playersRef.add(player)
          }

        } else {
          if (playersRef.doc(player.id)) {
            playersRef.doc(player.id).delete()
          }
        }
      })
    } catch (err) {
      console.error('Error adding players: ', err)
    }
  }

  detatchGamePlayers () {
    if (this.detachTeamPlayers) {
      this.detachTeamPlayers()
    }
  }

}

const gamePlayerState = new GamePlayerStore()
export default gamePlayerState
