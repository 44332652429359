import * as React from 'react'

import css from './PlayerCard.module.scss'

interface IProps {
  img?: string
  first_name: string
  last_name: string
  num?: string
  onClick?: () => void
}

const DEFAULT_IMG =
  'https://firebasestorage.googleapis.com/v0/b/liveinsight-de5ef.appspot.com/o/default-user.jpg?alt=media&token=9c271cb0-4f01-4b0f-960c-2fd2dce0538c'

const PlayerCard = ({
  img,
  first_name,
  last_name,
  num,
  onClick,
}: IProps) => {
  const defaultImage = DEFAULT_IMG
  const imageSource = img || defaultImage

  const numComponent = num ? <span className={css.textNum}>#{num}</span> : null

  return (
    <div className={css.container} onClick={onClick}>
      <img className={css.image} src={imageSource} alt="thumbnail" />
      <div className={css.infoContainer}>
        <span className={css.textTitle}>
          <span>{first_name}</span>
          <span>{last_name}</span>
        </span>
        {numComponent}
      </div>
    </div>
  )
}

export default PlayerCard
