import * as React from 'react'

import { IRecording } from "../../../stores/Recording";
import recordingStore from '../../../stores/RecordingStore';

import { TextField } from '@material-ui/core';
import dateParse from 'date-fns/parse'
import dateFormat from 'date-fns/format'
import isAfter from 'date-fns/is_after'

import ModalSimple from '../../../Components/ModalSimple/ModalSimple';

import css from './CreateRecording.module.css'

interface IProps {
  isOpen: boolean
  recordingType: string
  recordingToEdit?: IRecording
  closeWindow: () => void
}

interface IState {
  title: string
  type: string
  startDate: string
  startTime: string
  endDate: string
  endTime: string
  mode: string
  arena: string
  state: string
  validators: {
    endTimeError: boolean
    titleError: boolean
  }
  errorMessage: string
}

const FORMAT_DATE = 'YYYY-MM-DD'
const FORMAT_TIME = 'HH:mm'

class CreateRecording extends React.Component<IProps, IState> {
  constructor (props: IProps) {
    super(props)
    this.state = this.getInitialState()
  }

  getInitialState = () => {
    const currentDate = dateFormat(new Date(), FORMAT_DATE)
    const currentTime = dateFormat(new Date(), FORMAT_TIME)

    return {
      title: "",
      type: "practice",
      startDate: currentDate,
      startTime: currentTime,
      endDate: currentDate,
      endTime: currentTime,
      mode: "record",
      arena: "",
      state: "planned",
      validators: {
        endTimeError: false,
        titleError: false,
      },
      errorMessage: "",
    }
  }

  componentDidMount () {
    this.setRecordingToEdit()
  }

  componentWillUpdate (newProps: IProps) {
    if (newProps.isOpen && !this.props.isOpen) {
      this.setRecordingToEdit(newProps)
    }
  }

  handleChange = (event: any) => {
    const target = event.target;
    const value = target.value
    const name = target.name;

    const partialState = {};
    partialState[name] = value;
    this.setState(partialState)
    /*     this.setState({
          [name]: value
        }); */
    this.validateInput(name, value)

    if (name === "startDate") {
      this.setState({ endDate: value })
    }
  }

  handleSubmit = () => {
    for (const key in this.state.validators) {
      if (this.state.validators[key]) {
        this.setState({ errorMessage: "Please fill in all required fields" })
        return
      }
    }

    const start = dateParse(this.state.startDate + " " + this.state.startTime)
    const end = dateParse(this.state.endDate + " " + this.state.endTime)

    const recording: IRecording = {
      title: this.state.title,
      type: this.state.type,
      startTime: start.toISOString(),
      endTime: end.toISOString(),
      mode: this.state.mode,
      arena: this.state.arena,
      state: this.state.state,
    }

    // update or create depending on if there is an active recording (user clicked edit)
    if (this.props.recordingToEdit && this.props.recordingToEdit.title) {
      recording.id = this.props.recordingToEdit.id
      recordingStore.updateRecording(recording)
    } else {
      recordingStore.createRecording(recording)
    }
    this.closeWindow()
  }

  validateInput (name: string, value: string) {
    switch (name) {
      case "startTime":
        {
          const start = dateParse(this.state.startDate + " " + value)
          const end = dateParse(this.state.endDate + " " + this.state.endTime)

          if (isAfter(start, end))
            this.state.validators.endTimeError = true
          else
            this.state.validators.endTimeError = false
          break;
        }
      case "startDate":
        {
          const start = dateParse(value + " " + this.state.startTime)
          const end = dateParse(this.state.endDate + " " + this.state.endTime)

          if (isAfter(start, end))
            this.state.validators.endTimeError = true
          else
            this.state.validators.endTimeError = false
          break;
        }
      case "endTime":
        {
          const start = dateParse(this.state.startDate + " " + this.state.startTime)
          const end = dateParse(this.state.endDate + " " + value)

          if (isAfter(start, end))
            this.state.validators.endTimeError = true
          else
            this.state.validators.endTimeError = false
          break;
        }
      case "endDate":
        {
          const start = dateParse(this.state.startDate + " " + this.state.startTime)
          const end = dateParse(value + " " + this.state.endTime)

          if (isAfter(start, end))
            this.state.validators.endTimeError = true
          else
            this.state.validators.endTimeError = false
          break;
        }
      case "title":
        {
          if (value === "")
            this.state.validators.titleError = true
          else
            this.state.validators.titleError = false
          break;
        }
      default:
        break;
    }
  }

  closeWindow = () => {
    this.setState(this.getInitialState())
    this.props.closeWindow()
  }

  // If edit recording i selected - populate form with recording data
  setRecordingToEdit = (props: IProps = this.props) => {
    const initialState = this.getInitialState()
    const toEdit = props.recordingToEdit

    if (toEdit) {
      const newState = Object.assign({}, initialState, toEdit, {
        startDate: toEdit.startTime ? dateFormat(toEdit.startTime, FORMAT_DATE) : initialState.startDate,
        startTime: toEdit.startTime ? dateFormat(toEdit.startTime, FORMAT_TIME) : initialState.startTime,
        endDate: toEdit.endTime ? dateFormat(toEdit.endTime, FORMAT_DATE) : initialState.endDate,
        endTime: toEdit.endTime ? dateFormat(toEdit.endTime, FORMAT_TIME) : initialState.endTime,
      })
      this.setState(newState)
    }
  }

  getFooterButtons = () => {
    return ([
      { title: 'Save', handler: this.handleSubmit },
      { title: 'Cancel', handler: this.closeWindow, secondary: true },
    ])
  }

  renderContent = () => {
    return (
      <div className={css.form}>
        <TextField
          id="recording-name"
          name="title"
          className={css.titleInput}
          label="Name"
          value={this.state.title}
          margin="normal"
          required={true}
          error={this.state.validators.titleError}
          onChange={this.handleChange}
        />
        <h3>When?</h3>
        <div className={css.timeInput}>
          <TextField
            id="recording-date-start"
            name="startDate"
            type="date"
            helperText="Start time"
            value={this.state.startDate}
            onChange={this.handleChange}
          />
          <TextField
            id="recording-time-start"
            name="startTime"
            type="time"
            value={this.state.startTime}
            onChange={this.handleChange}
          />

          to

          <TextField
            id="recording-time-end"
            name="endTime"
            type="time"
            helperText="End time"
            value={this.state.endTime}
            error={this.state.validators.endTimeError}
            label={this.state.validators.endTimeError ? "Must be after start date" : ""}
            onChange={this.handleChange}
          />
          <TextField
            id="recording-date-end"
            name="endDate"
            type="date"
            error={this.state.validators.endTimeError}
            value={this.state.endDate}
            onChange={this.handleChange}
          />
        </div>
        <p>{this.state.errorMessage}</p>
      </div>
    )
  }

  render () {
    const {
      isOpen,
    } = this.props

    return (
      <ModalSimple
        isOpen={isOpen}
        onClose={this.closeWindow}
        title={`Add ${this.props.recordingType}`}
        footerButtons={this.getFooterButtons()}
      >
        {this.renderContent()}
      </ModalSimple>
    );
  }
}
export default CreateRecording
