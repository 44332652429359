import * as React from 'react'

import { IRecording } from "../../../../stores/Recording";

import {
  IFilterParameters, ITimeRange, PlayMode, TeamSelection, IJerseyNumbers
} from "../../../../Types/Types";

import FilterIcon from '@material-ui/icons/FilterList';

import css from './EventFilter.module.scss'
import ToggleButtonSmall from '../../../../Components/ToggleButtonSmall/ToggleButtonSmall';
import Filter from './Filter/Filter';
import filterOptionsState from '../../../../stores/FilterOptionsStore';


interface IProps extends IFilterParameters {
  activeGame: IRecording
  jerseyNumbers?: IJerseyNumbers | null
  onChange: (parameters: any) => void
  togglePlayMode: () => void
  onRangeChange: (range: ITimeRange) => void
}

interface IState {
  playMode: PlayMode
  hidden: boolean
}

class EventFilter extends React.Component<IProps, IState> {

  state = {
    playMode: PlayMode.Events,
    hidden: false,
  }

  hideFilters = () => {
    this.setState({ hidden: true })
  }

  showFilters = () => {
    this.setState({ hidden: false })
  }

  handleChange = (key: string) => (value: number) => {
    this.props.onChange({ [key]: value })
  }

  handleChangePhase = (value: number) => {
    this.props.onChange({ phase: value === 0 ? 1 : 2 })
  }

  togglePlayMode = () => {
    this.setState({
      playMode: this.state.playMode === PlayMode.Events ? PlayMode.All : PlayMode.Events,
    })
    this.props.togglePlayMode()
  }

  renderFilters = () => {
    if (this.state.playMode === PlayMode.All) {
      return null
    }

    const options = filterOptionsState.getFilterOptions()

    return this._renderFilters(options)
  }

  getJerseyNumbers = () => {
    const { jerseyNumbers, team } = this.props

    const teamStr = team === TeamSelection.Home ? 'home' : 'away'

    if (!jerseyNumbers) return []
    return jerseyNumbers[teamStr] || []
  }

  _renderFilters = (options: any) => {
    return options.map((filter: any) => {
      return this._renderFilter(filter)
    })
  }

  _renderFilter = (filter: any) => {
    return (
      <Filter
        key={filter.key}
        filter={filter}
        isChecked={this.isChecked}
        handleFilterChange={this.handleFilterChange}
        playMode={this.state.playMode}

        jerseyNumber={this.props.jerseyNumber}
        jerseyNumbers={this.getJerseyNumbers()}

        onRangeChange={this.props.onRangeChange}
        timeRangeMin={this.props.timeRangeMin}
        timeRangeMax={this.props.timeRangeMax}
      />
    )
  }

  isChecked = (filter: string, option: any) => {
    const parameters = this.props
    return parameters[filter] === option
  }

  handleFilterChange = (filter: string) => (option: any) => {
    if (filter === 'phase') {
      this.handleChangePhase(option)
    }
    this.handleChange(filter)(option)
  }

  render () {
    const { hidden } = this.state

    if (hidden) {
      return (
        <div className={css.filterCard} onClick={this.showFilters}>
          <FilterIcon />
        </div>
      )
    }

    return (
      <div className={css.container}>
        <div className={css.smallToggle}>
          <ToggleButtonSmall
            selected={this.state.playMode === PlayMode.All}
            onChange={this.togglePlayMode}
          /> Watch whole video
        </div>
        {this.renderFilters()}
      </div>
    )
  }
}

export default EventFilter
