import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const fbBase = process.env.REACT_APP_FIRESTORE_BASE || '';
// if (!fbBase) throw new Error("REACT_APP_FIRESTORE_BASE not set");

const CACHE_VERSION = process.env.REACT_APP_BUILD_DATE || ''
const cacheVersion = localStorage.getItem("liveinsight-version");
if (cacheVersion !== CACHE_VERSION) {
  localStorage.clear();
}
localStorage.setItem("liveinsight-version", CACHE_VERSION);


export const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
};

class Context {
  constructor (
    public app: firebase.app.App,
    public firestore: firebase.firestore.Firestore,
    public storage: firebase.storage.Storage,
    public firestoreBase: string,
    public fieldvalue: any
  ) { }
}


const fbApp = firebase.initializeApp(FIREBASE_CONFIG);
const fbStore = firebase.firestore();
const fbStorage = firebase.storage();
const fbFieldValue = firebase.firestore.FieldValue;
const settings = { timestampsInSnapshots: true };
fbStore.settings(settings)

fbStore.enablePersistence({ experimentalTabSynchronization: true })
  .catch((err) => {
    console.error(err)
    // if (err.code == 'failed-precondition') {
    //   // Multiple tabs open, persistence can only be enabled
    //   // in one tab at a a time.
    //   // ...
    // } else if (err.code == 'unimplemented') {
    //   // The current browser does not support all of the
    //   // features required to enable persistence
    //   // ...
    // }
  });

const context = new Context(fbApp, fbStore, fbStorage, fbBase, fbFieldValue)
export { context }
