import { observable, decorate } from "mobx";
import { context } from "./FirebaseConnection";
import appState from "./AppStateStore";
import { ITeam } from "../Types/Types";

interface ITeamListItem {
  id: string
  name: string
}

class TeamListStore {
  teams: ITeamListItem[]
  allTeams: ITeam[]

  private internalUnsubscribe?: () => void | null

  constructor () {
    this.teams = []
    this.allTeams = []
  }

  async getTeamListFromFirebase () {
    const userObject = appState.user

    if (!userObject || !userObject.teams || userObject.teams.length === 0) {
      this.teams = []
      return
    }
    const userTeams = userObject.teams

    const db = context.firestore

    Promise.all(userTeams.map((team => db.doc(`${context.firestoreBase}/teams/${team}`).get())))
      .then(docs => {
        this.teams = docs
          .map(doc => {
            const data = doc.data() || {}
            data.id = doc.id
            return data as ITeamListItem
          })
      }).catch(err => {
        console.error(err)
      })
  }

  async getAllTeams () {

    if (this.internalUnsubscribe)
      return

    const db = context.firestore
    const path = `${context.firestoreBase}/teams/`
    const recordingsRef = db.collection(path)

    this.internalUnsubscribe = recordingsRef
      .onSnapshot(snapshot => {
        teamListState.allTeams = snapshot.docs
          .map(doc => {
            const data = doc.data()
            data.id = doc.id
            if (!data.players) data.players = [];

            return data as ITeam
          })
      })
  }

  getTeam (id: string) {
    return this.allTeams.find(t => {
      return t.id === id
    })
  }

  async unsubscribe () {
    if (this.internalUnsubscribe) {
      this.internalUnsubscribe()
      delete this.internalUnsubscribe
    }
    this.clear()
  }

  clear () {
    this.teams = []
    this.allTeams = []
  }
}


decorate(TeamListStore, {
  teams: observable,
  allTeams: observable,
})

const teamListState = new TeamListStore()
export default teamListState
