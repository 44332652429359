import * as React from 'react'
import css from './ErrorMessage.module.scss';

interface IProps {
  message: string | null
}

export default class Error extends React.Component<IProps> {
  render () {
    const { message } = this.props
    return (
      <div className={css.errorMessage}>
        {message}
      </div>
    )
  }
}
