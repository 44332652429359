import * as React from "react";

import * as d3 from 'd3';

import css from './LineChart.module.scss'
import cx from 'classnames'
import { ILineChartDataItem } from "../../Types/Types";

interface IProps {
  id: string
  data: ILineChartDataItem[]
  width?: number
  height?: number
  flexible?: boolean
}

class LineChart extends React.Component<IProps, any> {
  componentDidMount = () => {
    this.drawChart()

    const { flexible } = this.props

    if (flexible) {
      window.addEventListener('resize', this.onResize)
    }
  }

  componentWillUnmount = () => {
    const { flexible } = this.props

    if (flexible) {
      window.addEventListener('resize', this.onResize)
    }
  }

  componentWillUpdate = (newProps: IProps) => {
    if (newProps.width !== this.props.width
      || newProps.height !== this.props.height) {
      this.onResize()
    }
  }

  onResize = () => {
    requestAnimationFrame(this.drawChart)
  }

  getContainerElement = () => {
    const { flexible } = this.props

    if (flexible) {
      return document.querySelector(`#${this.getChartId()}`)
    }

    return
  }

  getChartId = () => `chart-${this.props.id}`

  getDimensions = () => {
    const {
      flexible,
      width: pWidth,
      height: pHeight,
    } = this.props

    let width: number = 0
    let height: number = 0

    if (flexible) {
      const container = this.getContainerElement()
      if (container) {
        width = container.clientWidth
        height = container.clientHeight
      }
    } else if (pWidth && pHeight) {
      width = pWidth
      height = pHeight;
    }

    return {
      width,
      height,
    }
  }

  drawChart = () => {
    const {
      data,
    } = this.props

    const { width: svgWidth, height: svgHeight } = this.getDimensions()

    if (!(svgWidth && svgHeight)) return

    const chartSelector = `#${this.getChartId()}`
    const margin = { top: 20, right: 50, bottom: 40, left: 50 };
    const width = svgWidth - margin.left - margin.right;
    const height = svgHeight - margin.top - margin.bottom;

    d3.select(chartSelector).select('svg').remove()
    const svg = d3.select(chartSelector).append('svg')
      .attr("width", svgWidth)
      .attr("height", svgHeight);

    const g = svg.append("g")
      .attr("transform",
        "translate(" + margin.left + "," + margin.top + ")"
      );

    const x = d3.scaleLinear().rangeRound([0, width]);
    const y = d3.scaleLinear().rangeRound([height, 0]);

    const line = d3.line()
      .x(d => x(d[0]))
      .y(d => y(d[1]))

    const xExtent = d3.extent(data, (d) => d.x)
    const yMin = d3.min([
      d3.min(data.map(d => d.y1)) as number,
      d3.min(data.map(d => d.y2)) as number,
    ])
    const yMax = d3.max([
      d3.max(data.map(d => d.y1)) as number,
      d3.max(data.map(d => d.y2)) as number,
    ])
    const yExtent = [yMin, yMax]

    x.domain(xExtent as [number, number]);
    y.domain(yExtent as [number, number]);

    const xAxis = d3.axisBottom(x).tickSize(-height).ticks(data.length)
    g.append("g")
      .attr("transform", "translate(0," + height + ")")
      .attr('class', cx(css.axis, css.xaxis))
      .call(xAxis as any)
      .select(".domain")
      .remove();

    const yAxis = d3.axisLeft(y).tickSize(-width)
    g.append("g")
      .call(yAxis as any)
      .attr('class', cx(css.axis, css.yaxis))
      .append("text")
      .attr("fill", "#000")
      .attr("transform", "rotate(-90)")

    g.append("path")
      .datum(data.map(item => [item.x, item.y1]))
      .attr("fill", "none")
      .attr("stroke", "steelblue")
      .attr("stroke-linejoin", "round")
      .attr("stroke-linecap", "round")
      .attr("stroke-width", 2)
      .attr("d", line as any);

    g.append("path")
      .datum(data.map(item => [item.x, item.y2]))
      .attr("fill", "none")
      .attr("stroke", "red")
      .attr("stroke-linejoin", "round")
      .attr("stroke-linecap", "round")
      .attr("stroke-width", 2)
      .attr("d", line as any);
  }

  render () {
    return (
      <div id={this.getChartId()} className={cx(css.wrapper, css.lineChart, { [css.flexible]: this.props.flexible })} />
    )
  }

}

export default LineChart
