import * as React from 'react'

import * as THREE from 'three'
import { Position, IGameEvent, IPassEvent, IVideoInfo, TracksInfo, IBallFrame } from '../../Types/Types'

import SimpleSlider from './Components/SimpleSlider/SimpleSlider';
import VideoTimeLabels from './Components/SimpleSlider/VideoTimeLabels';
import VideoPlayer from './VideoPlayer'
import { Video3dRenderer } from './Video3dRenderer';

import PlayIcon from '@material-ui/icons/PlayArrowRounded'
import PauseIcon from '@material-ui/icons/PauseRounded'
import PrevIcon from '@material-ui/icons/SkipPreviousRounded'
import NextIcon from '@material-ui/icons/SkipNextRounded'
import UndoIcon from '@material-ui/icons/Undo'
import RedoIcon from '@material-ui/icons/Redo'
import LoopIcon from '@material-ui/icons/LoopRounded'
import FullscreenIcon from '@material-ui/icons/FullscreenRounded'
import FullscreenExitIcon from '@material-ui/icons/FullscreenExitRounded'
import FocusIcon from '@material-ui/icons/CenterFocusStrongRounded'
import NoFocusIcon from '@material-ui/icons/CenterFocusWeakRounded'
import KeyboardTab from '@material-ui/icons/KeyboardTab';


import css from './Video3d.module.scss'
import cx from 'classnames'
import { t } from '../../localization/i18n'

const KEY_ESC = 27;

interface IProps {
  video?: IVideoInfo // TODO: Not ?
  tracks?: TracksInfo | null
  start: number
  end: number
  isLooping?: boolean
  followBall?: boolean
  focusPoints?: Position[]
  ballFrames?: Map<number, IBallFrame>
  events?: IGameEvent[]
  currentEventIndex: number
  currentEventLastSelected?: number
  eventCount: number
  basicControls?: boolean
  onFragmentDone?: (currentTime: number) => void
  onNavigate?: (value: ('previous' | 'next')) => void
}

type ToolType = 'none' | 'dot' | 'rectangle' | 'circle' | 'line'

interface IState {
  isLooping: boolean
  isFullscreen: boolean
  isPlaying: boolean
  followBall: boolean
  wholeVideo: boolean
  rate: number
  tool: ToolType
  currentTime: number
  currentBufferedEnd: number
  paddingBefore: number
  paddingAfter: number
}

const keys: Set<string> = new Set()
const keyDown = (e: KeyboardEvent) => keys.add(e.key)
const keyUp = (e: KeyboardEvent) => keys.delete(e.key)

// Hardcoded for demo game
const videoOffset = 12 * 1 / 25

export default class Video3d extends React.Component<IProps, IState> {

  containerRef: React.RefObject<HTMLDivElement>
  videoRef: React.RefObject<HTMLVideoElement>

  videoPlayer: VideoPlayer
  video3dRenderer!: Video3dRenderer // This is set in componentDidMount

  rafId?: number
  currentEvent?: IGameEvent;

  zoomingIn: boolean = false
  zoomingOut: boolean = false
  rotatingLeft: boolean = false
  rotatingRight: boolean = false
  rotatingUp: boolean = false
  rotatingDown: boolean = false

  currentFrame: number = 0
  ballFrameKeys?: number[]

  parentWidth: number = 0

  handleTimeUpdate = () => {
    const currentTime = this.videoRef.current!.currentTime
    if (Math.floor(currentTime) !== Math.floor(this.state.currentTime)) {
      this.setState({ currentTime })
    }
  }

  handleVideoProgress = () => {
    const video = this.videoRef.current
    if (!video) return;

    const buffered = video.buffered
    const currentTime = video.currentTime

    let currentBufferedEnd: number = 0

    for (let i = 0; i < buffered.length; i++) {
      const lastBufferedStart = buffered.start(i)
      const lastBufferedEnd = buffered.end(i)
      if (currentTime > lastBufferedStart && currentTime <= lastBufferedEnd) {
        currentBufferedEnd = lastBufferedEnd
        break
      }
    }

    if (currentBufferedEnd !== this.state.currentBufferedEnd) {
      this.setState({ currentBufferedEnd })
    }
  }

  constructor (props: IProps) {
    super(props)
    this.state = {
      isLooping: props.isLooping || false,
      isPlaying: true,
      isFullscreen: false,
      followBall: false,
      wholeVideo: !props.eventCount,
      rate: 1.0,
      tool: 'none',
      currentTime: 0,
      currentBufferedEnd: 0,
      paddingBefore: 2,
      paddingAfter: 2,
    }
    this.containerRef = React.createRef()
    this.videoRef = React.createRef()
    this.videoPlayer = new VideoPlayer()
  }

  componentDidMount () {
    document.addEventListener('keydown', keyDown)
    document.addEventListener('keyup', keyUp)
    // this.initScene()
    this.start()
    if (this.props.focusPoints) {
      this.lookAtPoints()
    }
    if (!this.containerRef.current)
      return
    if (!this.videoRef.current)
      return
    if (!this.props.video)
      return

    this.parentWidth = this.containerRef.current.parentElement!.clientWidth

    this.video3dRenderer = new Video3dRenderer(
      this.containerRef.current,
      this.videoRef.current,
      this.props.video.calibration,
      this.props.video.alpha_map_url
    )

    if (this.props.tracks) {
      this.video3dRenderer.setTracks(this.props.tracks)
    }

    if (!this.props.eventCount) {
      this.toggleWholeVideo(true)
    }

    const followBall = !!this.props.followBall
    this.video3dRenderer.autoFollow = followBall
    this.setState({ followBall })

    this.videoPlayer.addVideo(this.videoRef.current, this.props.video.url)

    this.videoRef.current.addEventListener('timeupdate', this.handleTimeUpdate)
    this.videoRef.current.addEventListener('progress', this.handleVideoProgress)

    const paddedStart = this.props.start - this.state.paddingBefore
    this.videoPlayer.seek(paddedStart)
  }

  componentDidUpdate (prevProps: IProps) {

    const paddedStart = Math.max(this.props.start - this.state.paddingBefore, 0)
    const paddedEnd = this.props.end + this.state.paddingAfter

    if (prevProps.start === this.props.start || prevProps.end === this.props.end) {
      if (prevProps.currentEventLastSelected !== this.props.currentEventLastSelected)
        this.videoPlayer.seek(paddedStart)
    }
    else if (prevProps.start !== this.props.start || prevProps.end !== this.props.end) {
      const videoEnd = this.props.end > 0 ? paddedEnd : this.videoRef.current!.duration
      if (this.videoRef.current) {
        if (this.props.start < 0) { // Continue from current time
          this.videoPlayer.seek(this.videoRef.current.currentTime)
        } else {
          if (this.videoRef.current.currentTime < paddedStart - 1 || this.videoRef.current.currentTime > videoEnd) {
            this.videoPlayer.seek(paddedStart)
          } // Else if difference between current time and new start time is > 2 (arbitrary numnber...)
          else if (Math.abs(Math.max(this.props.start, this.videoRef.current.currentTime) - Math.min(this.props.start, this.videoRef.current.currentTime)) > 2) { // If play whole game
            this.videoPlayer.seek(paddedStart)
          }
        }
      }
    }

    if (this.props.ballFrames && (!this.ballFrameKeys || this.props.ballFrames !== prevProps.ballFrames)) {
      this.ballFrameKeys = Array.from(this.props.ballFrames.keys())
    }

    if (this.props.tracks && !prevProps.tracks) {
      this.video3dRenderer.setTracks(this.props.tracks)
    }

    if (!this.props.eventCount && prevProps.eventCount) {
      this.toggleWholeVideo(true)
    } else if (this.props.eventCount && !prevProps.eventCount) {
      this.toggleWholeVideo(false)
    }
  }

  transformPoint (point: Position) {
    const x = (point[0] - 0.5) * 0.96 * 105
    const y = (point[1] - 0.5) * 0.92 * 68
    return [x, y]
  }

  toCanvasPoint (point: Position) {
    const x = (point[0] - 0.5) * 0.96 * 105
    const y = (point[1] - 0.5) * 0.92 * 68
    return [x, y]
  }

  lookAtPoints = () => {
    console.log('TODO: Implement lookAtPoints')
    // 0,0 -> -105/2, -68/2
    // 1,1 -> 105/2, 68/2
    // points = points.map(x => [+x[0], +x[1]])
    // const sum = points.map(this.transformPoint).reduce((pv, cv) => [cv[0] + pv[0], cv[1] + pv[1]], [0, 0])
    // const target = new THREE.Vector3()
    // target.x = sum[0] / points.length
    // target.z = sum[1] / points.length
    // this.camera.lookAt(target)
    // this.camera.updateMatrixWorld(false)
    // this.camera.lookAt(this.camera.localToWorld(new THREE.Vector3(0, 0, -1)))
  }

  resetCamera = () => {
    if (this.props.focusPoints) {
      this.lookAtPoints()
    }
    else {
      // this.camera.lookAt(new THREE.Vector3())
      // this.camera.updateMatrixWorld(false)
      // this.camera.lookAt(this.camera.localToWorld(new THREE.Vector3(0, 0, -1)))
    }
  }

  removeEventListeners = () => {
    document.removeEventListener('keydown', keyDown)
    document.removeEventListener('keyup', keyUp)

    if (this.videoRef.current) {
      this.videoRef.current.removeEventListener('timeupdate', this.handleTimeUpdate)
      this.videoRef.current.removeEventListener('progress', this.handleVideoProgress)
    }
  }

  componentWillUnmount () {
    this.removeEventListeners()
    this.video3dRenderer.destroy()
    this.stop()
  }

  start = () => {
    if (!this.rafId)
      this.rafId = requestAnimationFrame(() => this.animate())
  }

  stop = () => {
    if (this.rafId !== undefined)
      cancelAnimationFrame(this.rafId)
  }

  animate = () => {
    // TODO: This block caused an infinite loop in react:
    const paddedEnd = this.props.end + this.state.paddingAfter
    if (this.props.end > 0 && this.videoRef.current && this.videoRef.current.currentTime > paddedEnd) {
      // console.log('done fragment')
      if (this.state.isLooping)
        this.repeatFragment()
      else if (this.props.onFragmentDone)
        this.props.onFragmentDone(this.videoRef.current.currentTime)
      else {
        console.log('End of fragment. Pausing')
        this.videoPlayer.pause()
      }
    }
    this.rafId = window.requestAnimationFrame(() => this.animate())
  }

  findEvent = () => {
    if (!this.props.events) {
      this.currentEvent = undefined
      return
    }
    if (!this.videoRef.current)
      return
    const currentTime = (this.videoRef.current.currentTime + videoOffset) * 1000

    if (this.currentEvent) {
      const pass = this.currentEvent as IPassEvent
      if (currentTime < pass.start_match_time + pass.duration)
        return
    }

    this.currentEvent = this.props.events.find((each) => {
      const pass = each as IPassEvent
      return (currentTime < pass.start_match_time + pass.duration)
    })

    // this.currentEvent = this.props.events[0]
  }


  findNearestFrame = () => {
    if (!this.videoRef.current)
      return
    if (!this.ballFrameKeys)
      return
    // return binarySearchNearest(this.ballFrameKeys, this.videoRef.current.currentTime * 1000)
    const currentTime = (this.videoRef.current.currentTime + videoOffset) * 1000

    while (currentTime > this.ballFrameKeys[this.currentFrame])
      this.currentFrame++
    while (currentTime < this.ballFrameKeys[this.currentFrame])
      this.currentFrame--
  }

  // seek (time: number) {
  //   if (!this.videoRef.current)
  //     return
  //   this.videoRef.current.currentTime = time
  //   // this.videoRef.current.playbackRate = 2
  //   this.videoRef.current.src = `video/pano.mp4#t=${time},${time + 1}`
  // }

  repeatFragment = () => {
    console.log('replay!')
    // this.setState({ src: `${this.props.src}` })
    if (!this.videoRef.current)
      return
    const paddedStart = this.props.start - this.state.paddingBefore
    this.videoPlayer.seek(paddedStart)
    // this.videoSyncer.play()
  }

  toggleFullscreen = (fullscreen: boolean = !this.state.isFullscreen) => {
    if (fullscreen === this.state.isFullscreen) return;
    this.setState({ isFullscreen: fullscreen })

    this.video3dRenderer.fullscreen = fullscreen
    // if (this.containerRef.current)
    //   console.log(this.containerRef.current.clientWidth)

    const size = new THREE.Vector2()

    size.x = fullscreen ? window.innerWidth : this.parentWidth
    size.y = size.x * 0.5625
    if (fullscreen)
      this.adjustFullscreenSize(size)

    this.video3dRenderer.handleWindowResizeFromDefinedSize(size)
    // this.renderer.setSize(size.x, size.y)

    // camera.aspect = window.innerWidth / window.innerHeight;
    // camera.updateProjectionMatrix();

    // renderer.setSize(window.innerWidth, window.innerHeight);
  }

  adjustFullscreenSize = (size: THREE.Vector2) => {
    if (size.y >= window.innerHeight - 200) {
      size.y = window.innerHeight - 200
      size.x = size.y / 0.5625
    }
  }

  play = () => {
    this.setState({ isPlaying: true }, () => {
      this.videoPlayer.play()
      this.video3dRenderer.isPlaying = true
    })
  }

  pause = () => {
    this.setState({ isPlaying: false }, () => {
      this.videoPlayer.pause()
      this.video3dRenderer.isPlaying = false
    })
  }

  seekFrame = (delta: number) => {
    if (!this.videoRef.current)
      return
    this.videoPlayer.seek(this.videoRef.current.currentTime += delta / 25)
  }

  previousEvent = () => {
    if (this.props.onNavigate)
      this.props.onNavigate('previous')
  }

  nextEvent = () => {
    if (this.props.onNavigate)
      this.props.onNavigate('next')
  }

  setRate = (rate: number) => {
    this.setState({ rate })
    if (!this.videoRef.current)
      return
    this.videoRef.current.playbackRate = rate
  }

  handleRate = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e && e.target)
      this.setRate(+e.target.value)
  }

  handleTool = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e && e.target)
      this.setState({ tool: e.target.value as ToolType }) // TODO: Type guard
  }

  handleSeek = (value: number) => {
    if (!this.videoRef.current) return
    const duration = this.videoRef.current
      ? this.videoRef.current.duration
      : 0

    this.videoPlayer.seek(value * duration)
    this.currentEvent = undefined
  }

  onKeyUp = (evt: React.KeyboardEvent) => {
    if (evt.which === KEY_ESC) {
      this.toggleFullscreen(false)
    }
  }

  toggleWholeVideo = (wv: boolean) => {
    if (wv) {
      this.setState({
        isLooping: false,
        wholeVideo: true,
      })
    } else {
      this.setState({
        wholeVideo: false,
      })
    }
  }

  handleToggleFullscreen = () => this.toggleFullscreen()
  handleToggleFullscreenFalse = () => this.toggleFullscreen(false)
  handleToggleLooping = () => this.setState({ isLooping: !this.state.isLooping })
  handlePreviousEvent = () => this.previousEvent()
  handlePlay = () => this.play()
  handleTogglePlay = () => (this.videoPlayer.isPlaying ? this.pause() : this.play())
  handlePause = () => this.pause()
  handleNextEvent = () => this.nextEvent()
  handleBackward10s = () => {
    if (!this.videoRef.current) return
    this.videoPlayer.seek(this.videoRef.current.currentTime - 10)
  }
  handleForward10s = () => {
    if (!this.videoRef.current) return
    this.videoPlayer.seek(this.videoRef.current.currentTime + 10)
  }
  handleToggleFollowBall = () => {
    const followBall = !this.state.followBall
    this.video3dRenderer.autoFollow = followBall
    this.setState({ followBall })
  }

  handlePaddingBeforeChange = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLInputElement
    const value = target.value
    this.setState({ paddingBefore: +value })
  }

  handlePaddingAfterChange = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLInputElement
    const value = target.value
    this.setState({ paddingAfter: +value })
  }

  renderControls = () => {
    const duration = this.videoRef.current ? this.videoRef.current.duration : 0
    const isPlaying = this.state.isPlaying

    let loopButton = null
    let prevButton = null
    let nextButton = null
    let fwdButton = null
    let bwdButton = null
    let followButton = null

    if (!(this.state.wholeVideo || this.props.basicControls)) {
      loopButton = (
        <button
          className={cx({ [css.on]: this.state.isLooping })}
          onClick={this.handleToggleLooping}
          title={t('recording.tooltips.loop')}
        >
          <LoopIcon />
        </button>
      )
      prevButton = (
        <button onClick={this.handlePreviousEvent} title={t('recording.tooltips.previous')}>
          <PrevIcon />
        </button>

      )
      nextButton = (
        <button onClick={this.handleNextEvent} title={t('recording.tooltips.next')}>
          <NextIcon />
        </button>
      )
    }

    if (this.state.wholeVideo) {
      bwdButton = (
        <button onClick={this.handleBackward10s} title={t('recording.tooltips.backward10s')}>
          <UndoIcon />
        </button>

      )
      fwdButton = (
        <button onClick={this.handleForward10s} title={t('recording.tooltips.forward10s')}>
          <RedoIcon />
        </button>
      )
    }

    followButton = (
      <button
        className={cx({ [css.on]: this.state.followBall })}
        onClick={this.handleToggleFollowBall}
        title={t('recording.tooltips.tacticalView')}
      >
        {this.state.followBall ? <FocusIcon /> : <NoFocusIcon />}
      </button>
    )

    return (
      <div className={css.controls}>

        <SimpleSlider
          min={0}
          max={duration}
          value={this.state.currentTime}
          buffered={this.state.currentBufferedEnd}
          seek={this.state.wholeVideo
            ? (value) => this.handleSeek(value)
            : undefined}
        />
        <div className={css.buttonBar}>
          <div className={css.buttonBarLeft}>
            {loopButton}
            {prevButton}
            {bwdButton}

            <button
              title={t(`recording.tooltips.${isPlaying ? 'pause' : 'play'}`)}
              onClick={this.handleTogglePlay}
            >
              {isPlaying ? <PauseIcon /> : <PlayIcon />}
            </button>

            {fwdButton}
            {nextButton}

            <VideoTimeLabels
              time={this.state.currentTime}
              duration={duration}
            />
          </div>

          <div className={css.buttonBarRight}>

            <div className={css.paddingInput}>
              <span>{t('recording.timePadding')}</span>
              <span className={css.inputSpan}>
                <input
                  type='number'
                  value={this.state.paddingBefore}
                  min='0'
                  max='30'
                  onChange={this.handlePaddingBeforeChange}
                />
              </span>
              <KeyboardTab className={cx(css.barIcon, css.rotatedIcon)} />
              <span>&nbsp;&nbsp;</span>
              <KeyboardTab className={cx(css.barIcon)} />
              <span className={css.inputSpan}>
                <input
                  type='number'
                  value={this.state.paddingAfter}
                  min='0'
                  max='30'
                  onChange={this.handlePaddingAfterChange}
                />
              </span>
            </div>

            <select
              value={this.state.rate}
              onChange={this.handleRate}
              title={t('recording.tooltips.speed')}
            >
              <option disabled>{t('recording.tooltips.speed')}</option>
              <option value={2}>{t('recording.playback_rate.speed_2')}</option>
              <option value={1}>{t('recording.playback_rate.speed_1')}</option>
              <option value={0.5}>{t('recording.playback_rate.speed_0_5')}</option>
            </select>

            {followButton}

            <button
              className={cx({ [css.on]: this.state.isFullscreen })}
              onClick={this.handleToggleFullscreen}
              title={t('recording.tooltips.fullscreen')}
            >
              {this.state.isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </button>
          </div>
        </div>
      </div>
    )
  }
  render () {
    if (!this.props.video)
      return null
    const topButtons = this.state.isFullscreen
      ? (
        <div className={css.controlsTop}>
          <div className={css.buttonBar}>
            <button onClick={this.handleToggleFullscreenFalse}>
              <FullscreenExitIcon />
            </button>
          </div>
        </div>
      ) : null

    return (
      <div className={cx(css.wrapper, { [css.fullscreen]: this.state.isFullscreen })} onKeyUp={this.onKeyUp}>
        <div className={css.container} ref={this.containerRef}>
          <video id="video" src={this.props.video.url} ref={this.videoRef} controls muted loop crossOrigin="anonymous" />
          {topButtons}
        </div >
        {this.renderControls()}
      </div>
    )
  }
}

/* <select style={{ width: '80px' }} value={this.state.tool} onChange={(e) => this.handleTool(e)}>
  <option value="none">Select Tool</option>
  <option value="dot">Marker</option>
  <option value="line">Line</option>
  <option value="rectangle">Rectangle</option>
  <option value="circle">Circle</option>
</select> */
/* <button style={{ visibility: this.drawings.length > 0 ? 'visible' : 'hidden' }} onClick={() => this.clearDrawings()}><img src={clearSvg} /></button> */
/* <div>
  <button onClick={() => this.resetCamera()}><img className="small" src={cameraSvg} /></button>
  <button onMouseDown={() => this.rotatingLeft = true} onMouseUp={() => this.rotatingLeft = false}><img className="small" src={leftSvg} /></button>
  <button onMouseDown={() => this.rotatingRight = true} onMouseUp={() => this.rotatingRight = false}><img className="small" src={rightSvg} /></button>
  <button onMouseDown={() => this.rotatingUp = true} onMouseUp={() => this.rotatingUp = false}><img className="small" src={upSvg} /></button>
  <button onMouseDown={() => this.rotatingDown = true} onMouseUp={() => this.rotatingDown = false}><img className="small" src={downSvg} /></button>
  <button onMouseDown={() => this.zoomingIn = true} onMouseUp={() => this.zoomingIn = false}><img className="small" src={zoomInSvg} /></button>
  <button onMouseDown={() => this.zoomingOut = true} onMouseUp={() => this.zoomingOut = false}><img className="small" src={zoomOutSvg} /></button>
</div> */

