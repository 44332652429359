import * as React from 'react'
import css from './LoadingSpinner.module.scss'

interface IProps {
}

interface IState {
  loading: boolean
}

const LOADING_DELAY = 500

export default class LoadingSpinner extends React.Component<IProps, IState> {
  constructor (props: IProps) {
    super(props)
    this.state = {
      loading: false,
    }
  }

  loadingTimeout?: number = undefined;

  componentDidMount () {
    this.loadingTimeout = window.setTimeout(this.setLoading, LOADING_DELAY)
  }

  componentWillUnmount () {
    window.clearTimeout(this.loadingTimeout)
  }

  setLoading = () => {
    this.setState({ loading: true })
  }

  render () {
    const {
      loading,
    } = this.state

    if (!loading) {
      return null
    }
    return (
      <div className={css.container}>
        <img className={css.image} src={require('../../assets/images/loading.gif')} alt={'spinner'} />
      </div>
    )
  }
}
