import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { RouteComponentProps } from "react-router";

import teamState from "../../stores/TeamStore";

import TeamList from './TeamList/TeamList'
import Team from './Team/Team'
import Player from './Player/Player'

// import css from './Teams.module.scss';

class Teams extends React.Component<RouteComponentProps> {
  componentWillUnmount () {
    teamState.detach()
  }

  render () {
    const { match } = this.props

    return (
      <Switch>
        <Route path={`${match.path}/:id/:playerId`} component={Player} exact />
        <Route path={`${match.path}/:id`} component={Team} exact />
        <Route path={match.path} component={TeamList} exact />
      </Switch>
    )
  }
}

export default Teams;
