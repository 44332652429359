import React from 'react'

import IconButton from '../IconButton/IconButton'

import css from './Sidebar.module.scss'
import ReactDOM from 'react-dom';

interface IProps {
  title?: string
  onClose?: () => void
  children?: any
}

export const Sidebar = ({
  title,
  onClose,
  children,
}: IProps) => {
  const titleComponent = title
    ? (
      <div className={css.title}>
        {title}
      </div>

    ) : null

  const closeComponent = onClose
    ? (
      <IconButton
        className={css.close}
        onClick={onClose}
        icon='close'
      />
    ) : null

  const content = (
    <div className={css.wrapper}>
      <div className={css.header}>
        {closeComponent}
        {titleComponent}
      </div>
      <div className={css.content}>
        {children}
      </div>
    </div>
  )
  const sidebarEl = document.querySelector('#appSidebar')

  if (sidebarEl) {
    return ReactDOM.createPortal(
      content,
      sidebarEl
    )
  }

  return content
}

export default Sidebar
